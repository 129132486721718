import { ChangeEvent, useState } from "react";
import { showAPIToast } from "../../../Helpers/ui-helper";
import { UserDetailsDTO } from "../../../Models/UserManagementDto";
import { UserApiService } from "../../../services/userApiService";
import "./login.scss";

function LoginPage(props: { saveUserAuth: any }) {
  const [inputs, setInputs] = useState({ userName: "", password: "" });
  const [errors, setErrors] = useState<any>({});

  const handleSubmit = () => {
    if (validateInputs()) {
      UserApiService.userLogin(inputs)
        .then((res) => {
          if (res.data) {
            let data: UserDetailsDTO = res.data as UserDetailsDTO;
            try {
              props.saveUserAuth(data);
            } catch (e) {
              console.error(e);
            }
          }
        })
        .catch((err) => {
          console.error(err);
          showAPIToast(err, "");
        });
    }
  };

  const validateInputs = (): boolean => {
    if (!inputs.userName) {
      setErrors({ userName: "Email is required!" });
      return false;
    }
    if (!inputs.password) {
      setErrors({ password: "Password is required" });
      return false;
    }

    let regEx =
      //eslint-disable-next-line
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEx.test(inputs.userName)) {
      setErrors({ userName: "Email is not valid!" });
      return false;
    }
    setErrors({});
    return true;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let inputVals = { ...inputs } as any;
    inputVals[e.target.name] = e.target.value;
    setInputs(inputVals);
  };

  const handleKeyUp = (e: any) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className="section">
      <div className="login-wrap">
        <div className="login-vector">
          <span className="absolute-title">
            <h1 className="title-1">Ashva</h1>
            <h1 className="title-2">Platform</h1>
          </span>
        </div>
        <div className="login-html">
          <label className="tab">Sign In</label>
          <div className="login-form">
            <div className="group">
              <label className="label">Email</label>
              <input
                type="email"
                className="input"
                name="userName"
                onKeyUp={handleKeyUp}
                onChange={handleChange}
                value={inputs.userName}
              />
              <div className="errorMessage">
                <b>{errors["userName"]}</b>
              </div>
            </div>
            <div className="group">
              <label className="label">Password</label>
              <input
                type="password"
                className="input"
                name="password"
                onKeyUp={handleKeyUp}
                onChange={handleChange}
                value={inputs.password}
              />
              <div className="errorMessage">
                <b>{errors["password"]}</b>
              </div>
            </div>
            <div className="group" style={{ padding: "2rem 0px" }}>
              <input
                type="submit"
                className="button"
                value="Sign In"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoginPage;

import {
  DateTimeFormatter,
  Instant,
  LocalDate,
  LocalDateTime,
  ZoneId,
} from "@js-joda/core";
import { RaceDetailsDTO } from "../Models/RaceDetailsDTO";
import "@js-joda/timezone";
import { FORMAT } from "./constants";
import { format } from "date-fns";
const formatter: DateTimeFormatter = DateTimeFormatter.ofPattern(
  FORMAT.yyyyMMddHHmmss
);
const formatter1: DateTimeFormatter = DateTimeFormatter.ofPattern(
  FORMAT.ddMMyyyyHHmmss_DASH
);

const YEAR_MONTH_DAY_FORMATTER: DateTimeFormatter = DateTimeFormatter.ofPattern(
  FORMAT.yyyyMMdd
);
const Dash_Format = "dd-MM-yyyy HH:mm:ss";
const Day_Month_Year = "dd-MM-yyyy";
const YYYY_MM_DD_HH_MM_SS = "yyyy-MM-dd HH:mm:ss";

export const getZonedPostTime = (
  race: RaceDetailsDTO,
  formatPattern: string,
  postTime: string
) => {
  let pt = LocalDateTime.parse(postTime, formatter)
    .atZone(ZoneId.systemDefault())
    .withZoneSameInstant(ZoneId.of(race.timezoneId))
    .format(DateTimeFormatter.ofPattern(formatPattern));

  return pt;
};

export const getPostTimeFormat = (
  timeZone: string,
  postTime: string,
  formatPattern: string
) => {
  let zoneId = ZoneId.of(timeZone);
  return LocalDateTime.parse(postTime, formatter)
    .atZone(zoneId)
    .withZoneSameInstant(ZoneId.systemDefault())
    .toLocalDateTime()
    .format(DateTimeFormatter.ofPattern(formatPattern));
};

export const getFormetTime = (postTime: string, formatPattern: string) => {
  if (postTime) {
    return LocalDateTime.parse(postTime, formatter).format(
      DateTimeFormatter.ofPattern(formatPattern)
    );
  }
};

export const getStringToPosttime = (postime: string, timeZone: string) => {
  let zoneId = ZoneId.of(timeZone);
  let post = LocalDateTime.parse(postime, formatter)
    .atZone(zoneId)
    .withZoneSameInstant(ZoneId.systemDefault())
    .toLocalDateTime()
    .toString();
  return new Date(post);
};

export const getStringToDate = (date: string) => {
  let dateFormate = LocalDate.parse(date, YEAR_MONTH_DAY_FORMATTER).toString();
  return new Date(dateFormate);
};

export const stringToStringDate = (
  date: string,
  sourceFormatter: string = "yyyyMMdd",
  outputFormat: string = "dd-MM-yyyy"
) => {
  let dateFormate = LocalDate.parse(
    date,
    DateTimeFormatter.ofPattern(sourceFormatter)
  ).toString();
  return format(new Date(dateFormate), outputFormat);
};

export const getStringToDateFormat = (date: any) => {
  let dateFormate = LocalDate.parse(date, YEAR_MONTH_DAY_FORMATTER);
  return dateFormate.format(DateTimeFormatter.ofPattern(Day_Month_Year));
};

export const dateTimeConversion = (timezone: any, time: any) => {
  let rigionalZoneId = ZoneId.of(timezone);
  let localDateTime = LocalDateTime.parse(time, formatter)
    .atZone(rigionalZoneId)
    .withZoneSameInstant(ZoneId.systemDefault())
    .toLocalDateTime();
  return localDateTime.format(DateTimeFormatter.ofPattern(Dash_Format));
};

export const timeZoneConversion = (timezone: any, time: any) => {
  let rigionalZoneId = ZoneId.of(timezone);
  let localDateTime = LocalDateTime.parse(time, formatter)
    .atZone(ZoneId.systemDefault())
    .withZoneSameInstant(rigionalZoneId)
    .toLocalDateTime();
  return new Date(localDateTime.toString());
};

export const dateTimeConvert = (timezone: any, time: any) => {
  let rigionalZoneId = ZoneId.of(timezone);
  let localDateTime = LocalDateTime.ofInstant(Instant.ofEpochMilli(time))
    .atZone(rigionalZoneId)
    .withZoneSameInstant(ZoneId.systemDefault())
    .toLocalDateTime();
  return localDateTime.format(DateTimeFormatter.ofPattern(Dash_Format));
};

export const dateConversionForInvOdds = (timezone: string, time: any) => {
  let rigionalZoneId = ZoneId.of(timezone);
  let localDateTime = LocalDateTime.parse(time, formatter)
    .atZone(rigionalZoneId)
    .withZoneSameInstant(ZoneId.systemDefault())
    .toLocalDateTime();
  return localDateTime.format(DateTimeFormatter.ofPattern(Dash_Format));
};

export const getDateAndTimeFormat = (datetime: any, formatType: any) => {
  let localDateTime = LocalDateTime.parse(
    datetime,
    formatType === "year" ? formatter1 : formatter
  );
  return localDateTime.format(
    DateTimeFormatter.ofPattern(
      formatType === "year" ? YYYY_MM_DD_HH_MM_SS : Dash_Format
    )
  );
};

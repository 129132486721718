import { useContext } from "react";
import { ToteContext } from "../../../Context/toteContext";
import { ToteCols } from "../../../Helpers/columns";
import { sortRaceTrips } from "../../../Helpers/list-helpers";
import { __SelToNumbers } from "../../../Models/dynamicTypes";
import { RaceTripDTO } from "../../../Models/raceTripsDTO";

export default function ToteDataTable() {
  const { poolTableData, selectedPool, selectedRaceTrips } =
    useContext(ToteContext);

  const cellTemplate = (runner: RaceTripDTO, field: string) => {
    let { selId } = runner.raceTripKey;
    switch (field) {
      case "selId":
        return runner.raceTripKey.selId;
      case "runnerName":
        return runner.horseName;
      case "layoff":
        return LiabilityTemplate(poolTableData[selectedPool]?.layoff, runner);
      case "investment":
        return InvestementTemplate(
          poolTableData[selectedPool]?.investment,
          selId
        );
      default:
        return "-";
    }
  };
  const InvestementTemplate = (invData: __SelToNumbers, selId: string) => {
    if (invData && invData[selId]) return invData[selId]?.toFixed(2) ?? 0;
    return 0;
  };

  const LiabilityTemplate = (lbData: __SelToNumbers, runner: RaceTripDTO) => {
    let data = "0";
    if (lbData) data = lbData[runner.raceTripKey.selId]?.toFixed(2) ?? data;
    return data;
  };
  
  return (
    <div className="table-card" style={{borderRadius:"0.6rem"}}>
      <div className="table-card-header">
        <em style={{ fontWeight: "bolder" }}>Pool Type : {selectedPool}</em>
      </div>
      {/* <h1>kdujhfksjdh</h1> */}
      <div>
        <table className="pz-table">
          <thead>
            <tr>
              {ToteCols.map((col) => {
                return (
                  <th
                    key={col.field}
                    style={{ width: col.width ?? "", cursor: "pointer" }}
                    data-toggle="tooltip"
                    data-placement="right"
                    title={col.toolTip}
                  >
                    {col.header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {sortRaceTrips(Object.values(selectedRaceTrips ?? [])).map(
              (runner: RaceTripDTO, index: number) => {
                return (
                  <tr className="row-t" key={index}>
                    {ToteCols.map((col) => {
                      return (
                        <td
                          key={col.field}
                          className={
                            runner.scratched === true ? "scratched" : ""
                          }
                        >
                          {cellTemplate(runner, col.field)}
                        </td>
                      );
                    })}
                  </tr>
                );
              }
            )}
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td></td>
              <td style={{ padding: "5px" }}>
                <span style={{ fontWeight: "bolder" }}>
                  Pool Total :{" "}
                  {poolTableData[selectedPool].totalInvestment
                    ? poolTableData[selectedPool].totalInvestment.toFixed(2)
                    : 0}
                </span>
              </td>
              <td style={{ padding: "5px" }}>
                <span style={{ fontWeight: "bolder" }}>
                  Layoff Total :{" "}
                  {poolTableData[selectedPool].totalLayoffInv
                    ? poolTableData[selectedPool].totalLayoffInv.toFixed(2)
                    : 0}
                </span>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td style={{ padding: "5px" }}>
                {" "}
                <span style={{ fontWeight: "bolder" }}>
                  Payoff Total :{" "}
                  {poolTableData[selectedPool].totalPayoff
                    ? poolTableData[selectedPool].totalPayoff.toFixed(2)
                    : 0}
                </span>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

import { Calendar } from "primereact/calendar";

export interface IDateTimePickerProps {
  label?: string;
  placeholder?: string;
  min?: string;
  max?: string;
  name?: string;
  onChange: any;
  onKeyUp?: any;
  value: Date;
  disabled?: boolean;
  className?: string;
  minDate?: Date;
  timeOnly?: boolean;
  inputClass?: string;
  dateFormat?: string;
  id?: string;
  showTime?: any;
  showSeconds?: any;
  hourFormat?: string;
}

export default function DateTimePicker(props: IDateTimePickerProps) {
  return (
    <div className={`input-container ${props.className}`}>
      <div className="input">
        <Calendar
          placeholder={props.placeholder}
          onChange={props.onChange}
          name={props.name}
          value={props.value}
          disabled={props.disabled}
          className="prime-datepicker"
          dateFormat={props.dateFormat ?? "dd - M - yy"}
          minDate={props.minDate}
          timeOnly={props.timeOnly}
          hourFormat="12"
          inputClassName={props.inputClass}
          id={props.id}
          showTime={props.showTime}
          showSeconds={props.showSeconds}
        ></Calendar>
        {props.label && (
          <label className="input-label" htmlFor={props.label}>
            {props.label}
          </label>
        )}
      </div>
    </div>
  );
}

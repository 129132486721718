export const Province: any = {
  BD: "bangladesh",
  BE: "belgium",
  BF: "burkina_faso",
  BG: "bulgaria",
  BA: "bosnia_and_herzegovina",
  BB: "barbados",
  WF: "wallis_and_futuna",
  BL: "saint_barthelemy",
  BM: "bermuda",
  BN: "brunei",
  BO: "bolivia",
  BH: "bahrain",
  BI: "burundi",
  BJ: "benin",
  BT: "bhutan",
  JM: "jamaica",
  BV: "bouvet_island",
  BW: "botswana",
  WS: "samoa",
  BQ: "bonaire,_saint_eustatius_and_saba_",
  BR: "brazil",
  BS: "bahamas",
  JE: "jersey",
  BY: "belarus",
  BZ: "belize",
  RU: "russia",
  RW: "rwanda",
  RS: "serbia",
  TL: "east_timor",
  RE: "reunion",
  TM: "turkmenistan",
  TJ: "tajikistan",
  RO: "romania",
  TK: "tokelau",
  GW: "guinea-bissau",
  GU: "guam",
  GT: "guatemala",
  GS: "south_georgia_and_the_south_sandwich_islands",
  GR: "greece",
  GQ: "equatorial_guinea",
  GP: "guadeloupe",
  JP: "japan",
  GY: "guyana",
  GG: "guernsey",
  GF: "french_guiana",
  GE: "georgia",
  GD: "grenada",
  GB: "united_kingdom",
  GA: "gabon",
  SV: "el_salvador",
  GN: "guinea",
  GM: "gambia",
  GL: "greenland",
  GI: "gibraltar",
  GH: "ghana",
  OM: "oman",
  TN: "tunisia",
  JO: "jordan",
  HR: "croatia",
  HT: "haiti",
  HU: "hungary",
  HK: "hong_kong",
  HN: "honduras",
  HM: "heard_island_and_mcdonald_islands",
  VE: "venezuela",
  PR: "puerto_rico",
  PS: "palestinian_territory",
  PW: "palau",
  PT: "portugal",
  SJ: "svalbard_and_jan_mayen",
  PY: "paraguay",
  IQ: "iraq",
  PA: "panama",
  PF: "french_polynesia",
  PG: "papua_new_guinea",
  PE: "peru",
  PK: "pakistan",
  PH: "philippines",
  PN: "pitcairn",
  PL: "poland",
  PM: "saint_pierre_and_miquelon",
  ZM: "zambia",
  EH: "western_sahara",
  EE: "estonia",
  EG: "egypt",
  ZA: "south_africa",
  EC: "ecuador",
  IT: "italy",
  VN: "vietnam",
  SB: "solomon_islands",
  ET: "ethiopia",
  SO: "somalia",
  ZW: "zimbabwe",
  SA: "saudi_arabia",
  ES: "spain",
  ER: "eritrea",
  ME: "montenegro",
  MD: "moldova",
  MG: "madagascar",
  MF: "saint_martin",
  MA: "morocco",
  MC: "monaco",
  UZ: "uzbekistan",
  MM: "myanmar",
  ML: "mali",
  MO: "macao",
  MN: "mongolia",
  MH: "marshall_islands",
  MK: "macedonia",
  MU: "mauritius",
  MT: "malta",
  MW: "malawi",
  MV: "maldives",
  MQ: "martinique",
  MP: "northern_mariana_islands",
  MS: "montserrat",
  MR: "mauritania",
  IM: "isle_of_man",
  UG: "uganda",
  TZ: "tanzania",
  MY: "malaysia",
  MX: "mexico",
  IL: "israel",
  FR: "france",
  IO: "british_indian_ocean_territory",
  SH: "saint_helena",
  FI: "finland",
  FJ: "fiji",
  FK: "falkland_islands",
  FM: "micronesia",
  FO: "faroe_islands",
  NI: "nicaragua",
  NL: "netherlands",
  NO: "norway",
  NA: "namibia",
  VU: "vanuatu",
  NC: "new_caledonia",
  NE: "niger",
  NF: "norfolk_island",
  NG: "nigeria",
  NZ: "new_zealand",
  NP: "nepal",
  NR: "nauru",
  NU: "niue",
  CK: "cook_islands",
  XK: "kosovo",
  CI: "ivory_coast",
  CH: "switzerland",
  CO: "colombia",
  CN: "china",
  CM: "cameroon",
  CL: "chile",
  CC: "cocos_islands",
  CA: "canada",
  CG: "republic_of_the_congo",
  CF: "central_african_republic",
  CD: "democratic_republic_of_the_congo",
  CZ: "czech_republic",
  CY: "cyprus",
  CX: "christmas_island",
  CR: "costa_rica",
  CW: "curacao",
  CV: "cape_verde",
  CU: "cuba",
  SZ: "swaziland",
  SY: "syria",
  SX: "sint_maarten",
  KG: "kyrgyzstan",
  KE: "kenya",
  SS: "south_sudan",
  SR: "suriname",
  KI: "kiribati",
  KH: "cambodia",
  KN: "saint_kitts_and_nevis",
  KM: "comoros",
  ST: "sao_tome_and_principe",
  SK: "slovakia",
  KR: "south_korea",
  SI: "slovenia",
  KP: "north_korea",
  KW: "kuwait",
  SN: "senegal",
  SM: "san_marino",
  SL: "sierra_leone",
  SC: "seychelles",
  KZ: "kazakhstan",
  KY: "cayman_islands",
  SG: "singapore",
  SE: "sweden",
  SD: "sudan",
  DO: "dominican_republic",
  DM: "dominica",
  DJ: "djibouti",
  DK: "denmark",
  VG: "british_virgin_islands",
  DE: "germany",
  YE: "yemen",
  DZ: "algeria",
  US: "united_states",
  UY: "uruguay",
  YT: "mayotte",
  UM: "united_states_minor_outlying_islands",
  LB: "lebanon",
  LC: "saint_lucia",
  LA: "laos",
  TV: "tuvalu",
  TW: "taiwan",
  TT: "trinidad_and_tobago",
  TR: "turkey",
  LK: "sri_lanka",
  LI: "liechtenstein",
  LV: "latvia",
  TO: "tonga",
  LT: "lithuania",
  LU: "luxembourg",
  LR: "liberia",
  LS: "lesotho",
  TH: "thailand",
  TF: "french_southern_territories",
  TG: "togo",
  TD: "chad",
  TC: "turks_and_caicos_islands",
  LY: "libya",
  VA: "vatican",
  VC: "saint_vincent_and_the_grenadines",
  AE: "united_arab_emirates",
  AD: "andorra",
  AG: "antigua_and_barbuda",
  AF: "afghanistan",
  AI: "anguilla",
  VI: "u.s._virgin_islands",
  IS: "iceland",
  IR: "iran",
  AM: "armenia",
  AL: "albania",
  AO: "angola",
  AQ: "antarctica",
  AS: "american_samoa",
  AR: "argentina",
  AU: "australia",
  AT: "austria",
  AW: "aruba",
  IN: "india",
  AX: "aland_islands",
  AZ: "azerbaijan",
  IE: "ireland",
  ID: "indonesia",
  UA: "ukraine",
  QA: "qatar",
  MZ: "mozambique",
};

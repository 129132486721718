import { useEffect, useState } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { CustomerChartDTO } from "../../../Models/CustomerSummaryData";
import { UserApiService } from "../../../services/userApiService";
import { ChartItem } from "chart.js";
import Chart from "chart.js/auto";
// Css
import "./CustomerSummeryChartData.scss";
import { showToast, TOAST } from "../../../Helpers/ui-helper";

const CustomerSummeryChartData = (props: any) => {
  // variables
  const [betAmt, setBetAmt] = useState<any>([]);
  const [winAmt, setWinAmt] = useState<any>([]);
  const [label, setLabel] = useState<string[]>([]);
  const [strikeRate, setStrikeRate] = useState<any>([]);
  const [winAmtLabel, setWinAmtLabel] = useState<any>([]);
  const [betAmtLabel, setBetAmtLabel] = useState<any>([]);
  const [strikeRateLabel, setStrikeRateLabel] = useState<any>([]);

  const options = {
    color: "#fff",
    plugins: {
      legend: {
        position: "right",
      },
      datalabels: {
        color: "#fff",
        font: {
          size: 14,
        },
      },
    },
  };

  const Colors = [
    "#883cae",
    "#FF7300",
    "#7CDDDD",
    "#FFAB05",
    "#FF0000",
    "#54a7ff",
    "#1bde79",
    "#868686",
  ];

  // callbacks
  useEffect(() => {
    const provisionData = () => {
      if (props.provinceData && props.isSubmited) {
        UserApiService.getCustomerSummaryData(props.provinceData)
          .then((res) => {
            let chartData = res.data as CustomerChartDTO[];
            let provinceToData = {} as any;
            let tempList = [];
            for (const data of chartData) {
              let province = data.csByProvinceKey.province;
              if (provinceToData[province]) {
                tempList = provinceToData[province];
                tempList.push(data);
                provinceToData[province] = tempList;
              } else {
                tempList = [];
                tempList.push(data);
                provinceToData[province] = tempList;
              }
            }
            let betsData = [];
            let winBetsData = [];
            let strikeData = [];
            let dataLabel = [] as any;
            let betDataLabel = [] as any;
            let strRateLabel = [] as any;
            for (const key in provinceToData) {
              let data = provinceToData[key] as CustomerChartDTO[];
              let bets = 0;
              let winAmt = 0;
              let strikeRate = 0;
              for (let i = 0; i < data.length; i++) {
                bets += data[i].betAmount;
                winAmt += data[i].winAmount;
                if (i === 0) {
                  strikeRate =
                    (data[i].totalWinningBets / data[i].totalBets) * 100;
                } else {
                  strikeRate =
                    (strikeRate +
                      (data[i].totalWinningBets / data[i].totalBets) * 100) /
                    2;
                }
              }
              if (bets !== 0) {
                betsData.push(bets.toFixed(2));
                betDataLabel.push(key);
              }
              if (winAmt !== 0) {
                winBetsData.push(winAmt.toFixed(2));
                dataLabel.push(key);
              }
              if (strikeRate !== 0) {
                strikeData.push(strikeRate.toFixed(2));
                strRateLabel.push(key);
              }
            }

            var arr = dataLabel.filter((element: any, index: any) => {
              return dataLabel.indexOf(element) === index;
            });

            var betArr = betDataLabel.filter((element: any, index: any) => {
              return betDataLabel.indexOf(element) === index;
            });

            var srtRatArr = strRateLabel.filter((element: any, index: any) => {
              return strRateLabel.indexOf(element) === index;
            });
            setBetAmtLabel(betArr);
            setWinAmtLabel(arr);
            setStrikeRateLabel(srtRatArr);
            setBetAmt(betsData);
            setWinAmt(winBetsData);
            setStrikeRate(strikeData);
            setLabel(Object.keys(provinceToData));
          })
          .catch((err) => {
            console.log(err);
            showToast(err.response?.data.message, TOAST.ERROR);
          });
      }
    };
    provisionData();
  }, [props.isSubmited, props.provinceData]);

  useEffect(() => {
    let chartCanvas: any;
    const test = () => {
      const ctx = document.getElementById("myChart") as ChartItem;
      if (!ctx) return;
      if (chartCanvas) chartCanvas?.destroy();

      chartCanvas = new Chart(ctx, {
        type: "pie",
        data: betdata,
        plugins: [ChartDataLabels],
        options: options as any,
      });
    };
    if (label.length > 0) test();
    return () => {
      if (chartCanvas) chartCanvas?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label]);

  useEffect(() => {
    let chartCanvas: any;
    const test = () => {
      const ctx = document.getElementById("winAmtChart") as ChartItem;
      if (!ctx) return;
      if (chartCanvas) chartCanvas?.destroy();
      chartCanvas = new Chart(ctx, {
        type: "pie",
        data: winBetdata,
        plugins: [ChartDataLabels],
        options: options as any,
      });
    };
    if (label.length > 0) test();
    return () => {
      if (chartCanvas) chartCanvas?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label]);

  useEffect(() => {
    let chartCanvas: any;
    const test = () => {
      const ctx = document.getElementById("strikeRateChart") as ChartItem;
      if (!ctx) return;
      if (chartCanvas) chartCanvas?.destroy();
      chartCanvas = new Chart(ctx, {
        type: "pie",
        data: strike,
        plugins: [ChartDataLabels],
        options: options as any,
      });
    };
    if (label.length > 0) test();
    return () => {
      chartCanvas?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label]);

  const betdata = {
    labels: betAmtLabel,
    datasets: [
      {
        data: betAmt,
        backgroundColor: Colors,
        borderColor: Colors,
        borderWidth: 1,
      },
    ],
  };
  const winBetdata = {
    labels: winAmtLabel,
    datasets: [
      {
        data: winAmt,
        backgroundColor: Colors,
        borderColor: Colors,
        borderWidth: 1,
      },
    ],
  };

  const strike = {
    labels: strikeRateLabel,
    datasets: [
      {
        data: strikeRate,
        backgroundColor: Colors,
        borderColor: Colors,
        borderWidth: 1,
      },
    ],
  };

  const chartDataTemplate = (label: any, id: string, title: string) => {
    return (
      <>
        {label.length > 0 && (
          <div className="pie_chart_block">
            <canvas id={id} width="100" height="100"></canvas>
            <span className="province_data_title">{title}</span>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="chat_wrapper pz-card">
      <>
        {chartDataTemplate(betAmtLabel, "myChart", "Bet Amount")}
        {chartDataTemplate(winAmtLabel, "winAmtChart", "Winning Bets Amount")}
        {chartDataTemplate(strikeRateLabel, "strikeRateChart", "Strike Rate")}
      </>

      <script
        type="text/javascript"
        src="https://cdn.jsdelivr.net/npm/chart.js"
      ></script>
      <script
        src="https://cdnjs.cloudflare.com/ajax/libs/chartjs-plugin-datalabels/2.0.0/chartjs-plugin-datalabels.min.js"
        integrity="sha512-R/QOHLpV1Ggq22vfDAWYOaMd5RopHrJNMxi8/lJu8Oihwi4Ho4BRFeiMiCefn9rasajKjnx9/fTQ/xkWnkDACg=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      ></script>
    </div>
  );
};
export default CustomerSummeryChartData;

import Loader from "react-loader-spinner";
import "./loaders.scss"

export default function LargeLoader(props: { isLoading: boolean }) {
    if (props.isLoading)
        return (
            <>
                <div className="loader">
                    <Loader
                        type="Watch"
                        color="#dcd8dd"
                        height={50}
                        width={50}
                    />
                    <div className="d-flex py-4 text-light">
                        <h3 className="px-2">Please wait...</h3>
                    </div>
                </div>
            </>
        );
    return null;
}
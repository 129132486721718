import { showAPIToast, showToast, TOAST } from "../../../Helpers/ui-helper";
import { getTrackName } from "../../../Helpers/valueHelper";
import { ManageRequestDTO, PoolRequestDTO } from "../../../Models/PoolsDTO";
import { ScheduleApiService } from "../../../services/scheduleApiService";
import MessageTimer from "../../../UI-Components/Footer/components/messageTimer/messageTimer";
import "./poolReqNotification.scss";

export interface IPoolReqNotiProps {
  requestedNotifications: PoolRequestDTO[];
  deleteReqObj: (reqId: string) => void;
}

export default function PoolReqNotification(props: IPoolReqNotiProps) {
  
  const acceptPoolRequest = (status: boolean, reqObj: PoolRequestDTO) => {
    let reqData = {} as ManageRequestDTO;
    if (reqObj) {
      reqData.poolKey = reqObj.poolKey;
      reqData.reqId = reqObj.reqId;
      reqData.requestStatus = status ? "ACCEPTED" : "REJECTED";
      reqData.userId = reqObj.requestedUserId;

      ScheduleApiService.acceptPoolRequest(reqData)
        .then((res) => {
          console.log(res);
          showToast(`Pool request is ${reqData.requestStatus}.`, TOAST.SUCCESS);
          props.deleteReqObj(reqData.reqId);
        })
        .catch((err) => {
          console.log(err);
          showAPIToast(err, "while managing pool request", "p_reqqq");
        });
    }
  };
  return (
    <div className="notification">
      {props.requestedNotifications &&
        props.requestedNotifications.map(
          (poolReq: PoolRequestDTO, index: number) => {
            if (poolReq.requestStatus === "PENDING")
              return (
                <div className="warning m-2" key={index}>
                  <div className={`n-head warning`}>
                    <span>Pool Request</span>
                  </div>
                  <div className="n-body">
                    Hey! From {getTrackName(poolReq.poolKey.pzoneCode)} Race No{" "}
                    {poolReq.poolKey.raceNo}{" "}
                    <b>
                      <em>{poolReq.requestedUserName}</em>
                    </b>{" "}
                    wants access of pool type{" "}
                    <b>
                      <em>{poolReq.poolKey.poolType}</em>
                    </b>{" "}
                    Do you want to grant access to this pool ?
                    <div>
                      {poolReq && (
                        <MessageTimer
                          duration={poolReq.duration}
                          onTimerFinished={props.deleteReqObj}
                          poolReq={poolReq}
                        />
                      )}
                    </div>
                  </div>
                  <div className="n-fotter">
                    <button
                      className="n-btn yes"
                      onClick={() => acceptPoolRequest(true, poolReq)}
                    >
                      {" "}
                      <i className="fa fa-check"></i> Yeah, Sure!
                    </button>
                    <button
                      className="n-btn no"
                      onClick={() => acceptPoolRequest(false, poolReq)}
                    >
                      {" "}
                      <i className="fa fa-times"></i> No
                    </button>
                  </div>
                </div>
              );
            else return null;
          }
        )}
    </div>
  );
}

import { OverlayPanel } from "primereact/overlaypanel";
import { useContext, useState } from "react";
import { ScheduleContext } from "../../../Context/scheduleContext";
import { TableColumnsDTO } from "../../../Helpers/columns";
import { LOCAL_STORAGE } from "../../../Helpers/constants";
import { sortRaceTrips } from "../../../Helpers/list-helpers";
import { RunnerFieldDTO } from "../../../Helpers/StaticData/race-data";
import { showAPIToast, showToast, TOAST } from "../../../Helpers/ui-helper";
import { setLocalStorage } from "../../../Helpers/valueHelper";
import { RaceTripDTO } from "../../../Models/raceTripsDTO";
import { ScheduleApiService } from "../../../services/scheduleApiService";
import PzCheckBox from "../../../UI-Components/Inputs/checkBoxInput";
import RunnerFieldCheckboxes from "../Components/runnerCheckboxes";
import "./raceTripComponent.scss";

var op: any;
export default function RaceTripComponent() {
  const {
    selectedRaceTrips,
    runnerFields,
    runnerFieldCols,
    setRunnerFiledsCols,
    setSelectedRaceTrips,
    startLoader,
    stopLoader,
  } = useContext(ScheduleContext);

  const [isShowFields, setIsShowFields] = useState(false);
  const [inputNumber, setInputNumber] = useState({ prgNo: "" });
  const [isNewAddTrip] = useState(false);
  const [values, setValues] = useState({
    prevRaceTrip: {} as RaceTripDTO,
    prevIndex: -1,
  });

  const onCheckBoxChange = (e: any) => {
    runnerFields[e.name].isTrue = e.value;
    setLocalStorage(
      LOCAL_STORAGE.SM_RUNNER_FIELDS,
      JSON.stringify(runnerFields)
    );
    updateColumn(runnerFields);
  };

  const updateColumn = (runnerFields: any) => {
    let runnerFieldsTemp: RunnerFieldDTO[] = Object.values({ ...runnerFields });
    let cols: TableColumnsDTO[] = [];
    for (let field of runnerFieldsTemp) {
      let tableCol: TableColumnsDTO = {
        field: field.key,
        header: field.label,
        width: " ",
      };
      if (field.isTrue) cols.push(tableCol);
    }
    setRunnerFiledsCols([...cols]);
  };

  const changeInput = (e: any) => {
    let _prgNo = { ...inputNumber };
    _prgNo.prgNo = e.target.value;
    setInputNumber(_prgNo);
  };

  const RunnerFieldTemplate = (trip: RaceTripDTO, index: number) => {
    if (runnerFieldCols)
      return runnerFieldCols.map((col) => {
        return (
          <td key={col.field}>{getCellTemplate(trip, col.field, index)}</td>
        );
      });
  };

  const getCellTemplate = (rowData: any, field: string, index: number) => {
    let data = rowData as RaceTripDTO;
    if (!data.isEditing) {
      if (typeof rowData[field] === "boolean") {
        if (rowData[field]) return <i className="fa fa-check text-warning"></i>;
        return <span>-</span>;
      }
      if (data.scratched)
        return <del className="text-danger">{rowData[field]}</del>;
      return rowData[field];
    }

    if (typeof rowData[field] === "boolean")
      return (
        <PzCheckBox
          value={rowData[field]}
          name={field}
          onChange={(e: any) => changeInRunnerCheckBox(e, index)}
        />
      );
    if (field === "programNo" && !isNewAddTrip) return rowData[field];

    return getInput(data, field, index);
  };

  const changeInRunnerCheckBox = (e: any, index: number) => {
    let trips = [...selectedRaceTrips] as any;
    trips[index][e.name] = e.value;
    setSelectedRaceTrips(trips);
  };

  const getInput = (rowData: any, field: string, index: number) => {
    return (
      <input
        type="text"
        name={field}
        value={rowData[field] ?? ""}
        onChange={(e) => changeInInput(e, index)}
        className="cell-input"
        autoFocus={field === "horseName"}
        onKeyUp={(e) => handleKeyUp(e, index)}
        id={index.toString()}
      />
    );
  };

  const changeInInput = (e: any, index: number) => {
    let trips: any = [...selectedRaceTrips];
    trips[index][e.target.name] = e.target.value;

    if (e.target.name === "programNo") {
      trips[index].raceTripKey.selId = e.target.value;
    }

    setSelectedRaceTrips(trips);
  };

  const handleKeyUp = (e: any, index: number) => {
    switch (e.keyCode) {
      case 13:
        onAccept(index);
        break;
      case 27:
        onCloseEdit(index);
        break;
      default:
        break;
    }
  };

  const onCloseEdit = (index: number) => {
    let trips = [...selectedRaceTrips] as any;
    let tVal = { ...values };
    trips[index] = tVal.prevRaceTrip;
    trips[index].isEditing = false;
    setSelectedRaceTrips(trips);
    setValues({
      prevRaceTrip: {} as RaceTripDTO,
      prevIndex: -1,
    });
  };

  const onAccept = (index: number) => {
    let trips = [...selectedRaceTrips];
    let singleTrip = { ...trips[index] } as any;
    startLoader();
    delete singleTrip.isEditing;
    delete singleTrip.raceTripKeyStr;
    setSelectedRaceTrips(trips);

    ScheduleApiService.saveSingleRaceTrip(singleTrip)
      .then((res) => {
        if (res.data) {
          trips[index].isEditing = false;
          setSelectedRaceTrips(trips);
          stopLoader();

          showToast("Runner Updated Successfully!", TOAST.SUCCESS);
          setValues({
            prevRaceTrip: {} as RaceTripDTO,
            prevIndex: -1,
          });
        } else {
          stopLoader();
        }
      })
      .catch((err) => {
        stopLoader();
        console.error(err);
        showAPIToast(err, "while saving runner details", "runner");
      });
  };

  const changeShowFieldState = () => {
    setLocalStorage(
      LOCAL_STORAGE.IS_FIELDS_SHOW,
      JSON.stringify(!isShowFields)
    );
    setIsShowFields(!isShowFields);
  };

  const onAddRow = () => {
    if (!inputNumber.prgNo) {
      showToast("Add Runner No.", TOAST.ERROR);
      return;
    }
    let trips: any = [...selectedRaceTrips];
    let index = trips.findIndex(
      (x: any) => x.raceTripKey.selId === inputNumber.prgNo
    );

    if (index === -1) {
      let temp: RaceTripDTO = {
        age: 0,
        apprentice: false,
        apprenticeType: "",
        barrierPos: 0,
        breedType: "",
        carriedWeight: 0,
        claimingPrice: 0,
        coupled: false,
        earnings: 0,
        equipments: false,
        equipmentsType: "",
        horseName: "",
        horseWeight: 0,
        jockeyName: "",
        lastUpdated: 0,
        medication: false,
        medicationType: "",
        providerId: "",
        scratched: false,
        sex: "",
        speedIndex: 0,
        trainerName: "",
        raceTripKey: {
          selId: inputNumber.prgNo.toString(),
          localDate: trips[0].raceTripKey.localDate,
          pzoneCode: trips[0].raceTripKey.pzoneCode,
          raceNo: trips[0].raceTripKey.raceNo,
        },
        programNo: inputNumber.prgNo.toString(),
        isEditing: false,
        raceTripKeyStr: "",
      } as any;
      trips.push(temp);
      console.log(temp);
      setSelectedRaceTrips(trips);
      op.hide();
      onRowEdit(trips.length - 1, trips);
    } else {
      showToast("Runner No. Already Added", TOAST.ERROR);
    }
  };

  const onRowEdit = (index: number, _trips?: any) => {
    let trips;
    if (_trips) {
      trips = _trips;
    } else {
      trips = [...selectedRaceTrips];
    }
    if (!trips[index].isEditing) {
      let preTrip = { ...trips[index] };
      for (let trip of trips) {
        trip.isEditing = false;
      }
      trips[index].isEditing = true;
      if (values.prevIndex !== -1) {
        trips[values.prevIndex] = values.prevRaceTrip;
      }
      setValues({
        prevRaceTrip: preTrip,
        prevIndex: index,
      });
      setSelectedRaceTrips([...sortRaceTrips(trips)]);
    }
  };
  return (
    <>
      {selectedRaceTrips && (
        <>
          <div className="pz-card mx-3">
            <div className="pz-card-header p-0 d-flex justify-content-between">
              <button onClick={changeShowFieldState} className="toggle-btn">
                <i className="fa fa-cogs"></i> &nbsp; Runner Fields
              </button>
            </div>

            <div className="pz-card-body">
              {runnerFields &&
                RunnerFieldCheckboxes(
                  isShowFields,
                  runnerFields,
                  onCheckBoxChange
                )}
            </div>
          </div>
          <div className="pz-card">
            <div style={{ overflowX: "auto" }}>
              <button
                onClick={(e) => op.toggle(e)}
                className="pz-btn icon-btn hover"
                style={{ margin: "5px" }}
              >
                <span>
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </span>
                &nbsp;&nbsp;
                <span>RaceTrip</span>
              </button>
              <table className="my-table">
                <thead>
                  <tr>
                    <th>Actions</th>
                    {runnerFieldCols?.map((col) => {
                      return (
                        <th key={col.field} style={{ width: col.width }}>
                          {col.header}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <OverlayPanel ref={(el) => (op = el)}>
                  <label>Enter Runner No.</label>
                  <input
                    type="text"
                    onChange={changeInput}
                    className="cell-input-btn"
                    style={{
                      color: "white",
                      outline: "none",
                      border: "2px solid #3f3f3f",
                    }}
                    maxLength={3}
                  />
                  <button
                    style={{
                      background: "#883cae",
                      color: "white",
                      boxShadow: "2px 4px 6px 0px #252525a1",
                      borderRadius: 2,
                      display: "inline-block",
                      padding: "0.25rem 1rem",
                      margin: "0 0.25rem",
                      marginTop: "1rem",
                      border: "1px solid #883cae",
                      height: 30,
                    }}
                    onClick={onAddRow}
                  >
                    ADD
                  </button>
                </OverlayPanel>
                <tbody>
                  {selectedRaceTrips.map((trip: RaceTripDTO, index: number) => {
                    if (!trip) {
                      return null;
                    }

                    return (
                      <tr key={index} onDoubleClick={() => onRowEdit(index)}>
                        <td className="text-center">
                          {!trip?.isEditing && (
                            <button
                              onClick={() => onRowEdit(index)}
                              className="action-btn"
                            >
                              <i className="fa fa-pencil"></i>
                            </button>
                          )}
                          {trip?.isEditing && (
                            <>
                              <button
                                onClick={() => onCloseEdit(index)}
                                className="action-btn text-danger"
                              >
                                <i className="fa fa-times"></i>
                              </button>
                              <button
                                onClick={() => onAccept(index)}
                                className="action-btn text-success"
                              >
                                <i className="fa fa-check"></i>
                              </button>
                            </>
                          )}
                        </td>
                        {RunnerFieldTemplate(trip, index)}
                      </tr>
                    );
                  })}
                  {!selectedRaceTrips?.length && (
                    <tr>
                      <td colSpan={runnerFieldCols?.length + 1}>
                        <h3 className="text-center d-block text-muted p-4">
                          Runner details not available for now!
                        </h3>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
}

import { ROUTES } from "../../../../Helpers/constants";
import { Link } from "react-router-dom";

export default function SidebarNavigation(props: any) {
  // variables
  const { customerType } = props;

  return (
    <>
      <div className="content-panel-header head multiple">Search Customer</div>
      <div className="b-bottom side-nav">
        <div className="" style={{ display: "flex", flexDirection: "column" }}>
          <Link
            to={ROUTES.ACTIVE_CUSTOMER}
            className={customerType === "active" ? "nav-btn active" : "nav-btn"}
          >
            {" "}
            Active Customer <i className="fa fa-caret-right"></i>
          </Link>
          <Link
            to={ROUTES.SEARCH_CUSTOMER}
            className={customerType === "search" ? "nav-btn active" : "nav-btn"}
          >
            Search Customer
            <i className="fa fa-caret-right"></i>
          </Link>
          <Link
            to={ROUTES.SEARCH_CUSTOMER_REPORT}
            className={
              customerType === "customer_report" ? "nav-btn active" : "nav-btn"
            }
          >
            Search Customer Report
            <i className="fa fa-caret-right"></i>
          </Link>
        </div>
      </div>
    </>
  );
}

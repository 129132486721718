import { ReactNode, useCallback, useContext, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { AppContext } from "../Context/appContext";
import { FORMAT } from "../Helpers/constants";
import { getPostTimeFormat } from "../Helpers/dateTimeHelpers";
import { sortObject } from "../Helpers/list-helpers";
import { showAPIToast } from "../Helpers/ui-helper";
import { getTrackName, raceKeySplit } from "../Helpers/valueHelper";
import { ActiveRaceDTO } from "../Models/activeRaceDTO";
import { DashboardService } from "../services/dashboardService";
import { upcRacesAtom } from "../store/scheduleManagement/races.store";

interface Props {
  children: ReactNode;
}

const RacesProvider = ({ children }: Props) => {
  const { user } = useContext(AppContext);
  const setUpcRaces = useSetRecoilState(upcRacesAtom);
  const getRaces = useCallback((races: any) => {
    for (let raceKey in races) {
      let _race = races[raceKey] as ActiveRaceDTO;
      _race.eventType = raceKeySplit(_race.raceKey.pzoneCode, "-", 2);
      _race.trackName = getTrackName(_race.raceKey.pzoneCode);
      _race.province = raceKeySplit(_race.raceKey.pzoneCode, "-", 0);
      _race.raceKeyString = raceKey;
      _race.localPostTime = Number(
        getPostTimeFormat(_race.timezone, _race.postTime, FORMAT.yyyyMMddHHmmss)
      );
    }
    return races;
  }, []);

  useEffect(() => {
    console.log("xcpDasDa: ACTIVE RACES EFFECT CALLED");

    const fetchActiveRace = () => {
      DashboardService.getActiveRaces()
        .then((res) => {
          if (res.data) {
            let racesObj = getRaces(res.data ?? []);
            // debugger;
            let sortedRaces = sortObject(
              racesObj,
              "raceKeyString",
              "localPostTime"
            );

            setUpcRaces(sortedRaces);
          }
        })
        .catch((err) => {
          console.log(err);
          showAPIToast(err, "while fetching active races", "act-races");
        });
    };

    if (user) fetchActiveRace();
  }, [getRaces, setUpcRaces, user]);

  return <>{children}</>;
};

export default RacesProvider;

import { PrimeItem } from "./race-data";

export const UserRoles: PrimeItem[] = [
  // { label: "Admin", value: "ADMIN", disabled: true },
  { label: "Customers - Read Only", value: "CUS_MGT_READ" },
  { label: "Customers - Modify", value: "CUS_MGT_MODIFY" },
  { label: "Customers - Delete", value: "CUS_MGT_DELETE" },
  { label: "User - Read Only", value: "USER_MGT_READ" },
  { label: "User - Modify", value: "USER_MGT_MODIFY" },
  { label: "User - Delete", value: "USER_MGT_DELETE" },
  { label: "Schedule - Read Only", value: "SCHEDULE_MGT_READ" },
  { label: "Schedule - Modify", value: "SCHEDULE_MGT_MODIFY" },
  { label: "Schedule - Delete", value: "SCHEDULE_MGT_DELETE" },
  { label: "Fixed Odds - Read Only", value: "FIX_ODDS_MGT_READ" },
  { label: "Fixed Odds - Modify", value: "FIX_ODDS_MGT_MODIFY" },
  { label: "Fixed Odds - Delete", value: "FIX_ODDS_MGT_DELETE" },
  { label: "Logs - Read Only", value: "LOG_READ" },
  { label: "Rules - Read Only", value: "RULE_MGT_READ" },
  { label: "Rules - Modify", value: "RULE_MGT_MODIFY" },
  { label: "Rules - Delete", value: "RULE_MGT_DELETE" },
  { label: "Connector - Read Only", value: "CONNECTOR_MGT_READ" },
  { label: "Connector - Modify", value: "CONNECTOR_MGT_MODIFY" },
  { label: "Connector - Delete", value: "CONNECTOR_MGT_DELETE" },
  { label: "Report - Read Only", value: "REPORT_READ" },
  { label: "Tote - Read Only", value: "TOTE_MGT_READ" },
];

export const RoleToDescMap: any = {
  ADMIN: { desc: "No restrictions", label: "Admin" },
  CONNECTOR_MGT_READ:{
    desc:"Read any connector data",
    label:"Connector - Read Only",
  },
  CONNECTOR_MGT_MODIFY:{
    desc:"Update/Modify connector data",
    label:"Connector - Modify",
  },
  CONNECTOR_MGT_DELETE:{
    desc:"Delete connector data",
    label:"Connector - Delete",
  },
  LOG_READ:{
    desc:"Read any log data",
    label:"Logs - Read Only",
  },
  REPORT_READ:{
    desc:"Read any report data",
    label:"Report - Read Only",
  },
  TOTE_MGT_READ:{
    desc:"Read any tote data",
    label:"Tote - Read Only",
  },
  CUS_MGT_READ: {
    desc: "Read any customers data",
    label: "Customers - Read Only",
  },
  CUS_MGT_MODIFY: {
    desc: "Update/Modify customer details",
    label: "Customers - Modify",
  },
  CUS_MGT_DELETE: {
    desc: "Delete any customer data",
    label: "Customers - Delete",
  },
  USER_MGT_READ: { desc: "Read any user data", label: "User - Read Only" },
  USER_MGT_MODIFY: {
    desc: "Update/Modify any user details",
    label: "User - Modify",
  },
  USER_MGT_DELETE: { desc: "Delete any user data", label: "User - Delete" },

  SCHEDULE_MGT_READ: {
    desc: "Read any schedule management data",
    label: "Schedule - Read Only",
  },
  SCHEDULE_MGT_MODIFY: {
    desc: "Update/Modify schedule management data",
    label: "Schedule - Modify",
  },
  SCHEDULE_MGT_DELETE: {
    desc: "Delete schedule management data",
    label: "Schedule - Delete",
  },
  FIX_ODDS_MGT_READ: {
    desc: "Read data in fixed odds management",
    label: "Fixed Odds - Read Only",
  },
  FIX_ODDS_MGT_MODIFY: {
    desc: "Update/Modify fixed odds management data",
    label: "Fixed Odds - Modify",
  },
  FIX_ODDS_MGT_DELETE: {
    desc: "Delete any fixed odds management data",
    label: "Fixed Odds - Delete",
  },
  TRAN_MGT_READ: {
    desc: "Read data in transaction management",
    label: "Transactions - Read Only",
  },
  TRAN_MGT_MODIFY: {
    desc: "Update/Modify transaction management data",
    label: "Transactions - Modify",
  },
  TRAN_MGT_DELETE: {
    desc: "Delete any transaction management data",
    label: "Transactions - Delete",
  },
  RULE_MGT_READ: {
    desc: "Read data in Rules Management",
    label: "Rules - Read",
  },
  RULE_MGT_MODIFY: {
    desc: "Modify data in Rules Management ",
    label: "Rules - Modify",
  },
  RULE_MGT_DELETE: {
    desc: "Delete any rule profile data",
    label: "Rules - Delete",
  },
};

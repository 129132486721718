import { PoolRuleDTO } from "../../Models/poolRuleDTO";
export const _formatRule = (rule: any) => {
  let tsp = rule.poolRuleKey.track.split("-");
  rule.ruleKey = rule.poolRuleKey.ruleKey;
  // rule.province = rule.poolRuleKey.province;
  rule.province = tsp[0];
  rule.eventType = tsp[2];
  rule.trackName = tsp[1].replace(/_/g, " ");
  return rule;
};

export const _sortPoolRules = (rules: PoolRuleDTO[]): PoolRuleDTO[] => {
  let sorted = rules.sort((a, b) => {
    if (!a.deleted && !b.deleted && a.province < b.province) return -1;
    if (!a.deleted && !b.deleted && a.province > b.province) return 1;
    if (a.deleted && !b.deleted) return 1;
    if (!a.deleted && b.deleted) return -1;
    return 0;
  });
  return sorted;
};

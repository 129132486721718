import { Column } from "primereact/column";
import { confirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { OverlayPanel } from "primereact/overlaypanel";
import { useEffect, useRef, useState } from "react";
import { columnsOfCustomerRule } from "../../../../../../Helpers/columns";
import {
  getFloatButton,
  showAPIToast,
  showToast,
  TOAST,
} from "../../../../../../Helpers/ui-helper";
import {
  CustomerRule,
  CustomerRules,
} from "../../../../../../Models/customerRulesDto";
import { RulesService } from "../../../../../../services/rulesApiService";
import { ScreenType } from "../../CustomerRules";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Dialog } from "primereact/dialog";
import "./viewCustomerRules.scss";
import LargeLoader from "../../../../../../UI-Components/Loader/loaders";
import JSONPretty from "react-json-pretty";

export interface IViewCustomerRulesProps {
  changeScreen: (screen: ScreenType) => void;
  setSelectedRowData: (data: CustomerRule) => void;
  setIsUpdated: any;
  setOnRuleIdAdded: any;
  setIsRuleIdAdded: any;
}

export default function ViewCustomerRules(props: IViewCustomerRulesProps) {
  const [customerRules, setCustomerRules] = useState([] as CustomerRules[]);
  const [editCustomerRule, setEditCustomerRule] = useState([]);
  const [prefConfirmDailog, setPrefConfirmDailog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const viewPanelRef = useRef(null as any);
  const draggableRules = useRef(null as any);

  useEffect(() => {
    getCustomerRules();
  }, []);

  const getCustomerRules = () => {
    setIsLoading(true);
    RulesService.getCustomerRules()
      .then((res) => {
        if (res && res.data) {
          res.data.sort((a: any, b: any) => (a.ruleId > b.ruleId ? 1 : -1));
          setCustomerRules(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        showToast("something went wrong!", TOAST.ERROR);
        console.log(err);
        setIsLoading(false);
      });
  };
  const ViewFilterPanel = (
    <OverlayPanel
      ref={viewPanelRef}
      showCloseIcon
      id="overlay_panel"
      style={{ width: "400px", color: "white" }}
      className="pz-overlaypanel"
    >
      <div className="p-2">
        <h5 style={{ fontWeight: "bold" }}>Filters </h5>
      </div>
      <div>
        <JSONPretty id="json-pretty" data={editCustomerRule}></JSONPretty>
      </div>
    </OverlayPanel>
  );

  const _header = (res: any) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <span>{res.ruleId}</span>
        <div style={{ display: "flex", justifyContent: "right" }}>
          <button
            className="btn-style"
            onClick={() => onRuleAdd(res)}
            style={{
              marginLeft: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <i className="fa fa-plus"></i>
          </button>
        </div>
      </div>
    );
  };

  const onRuleAdd = (res: CustomerRules) => {
    props.setOnRuleIdAdded(res.ruleId);
    props.setIsRuleIdAdded(true);
    props.setIsUpdated(false);
    props.changeScreen(ScreenType.ADD);
  };

  const onRowReorder = (e: any, dataObj: any) => {
    for (let i = 0; i < e.value.length; i++) {
      e.value[i].prefNo = i + 1;
    }
    dataObj.rules = e.value;
    draggableRules.current = dataObj;
    setPrefConfirmDailog(true);
  };

  const dynamicColumns = columnsOfCustomerRule.map((col, i) => {
    if (col.field === "filter")
      return (
        <Column
          key={col.field}
          style={{ width: col.width }}
          field={col.field}
          header={col.header}
          body={(rowData) => filteredTemplate(rowData)}
        />
      );
    if (col.field === "edit_delete") {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={(rowData) => edit_deleteCellTemplate(rowData)}
        />
      );
    }

    return (
      <Column
        key={col.field}
        columnKey={col.field}
        field={col.field}
        header={col.header}
        body={(rowData) => displayCustomerData(col, rowData)}
      />
    );
  });
  const filteredTemplate = (rowData: CustomerRule) => {
    return (
      <>
        <button className="pool-btn" onClick={(e) => openViewPanel(e, rowData)}>
          <i className="fa fa-eye"></i> &nbsp;&nbsp; Filter Data
        </button>
      </>
    );
  };
  const openViewPanel = (e: any, rowData: any) => {
    setEditCustomerRule(rowData.filter);
    if (viewPanelRef && viewPanelRef.current) viewPanelRef.current.toggle(e);
  };

  const edit_deleteCellTemplate = (rowData: CustomerRule) => {
    return (
      <>
        <button onClick={(e) => onEdit(e, rowData)} className="pool-btn">
          <i className="fa fa-pencil"></i> &nbsp; Edit
        </button>
        <button
          onClick={(e) => confirmDelete(e, rowData)}
          className="pool-btn danger"
        >
          <i className="fa fa-trash"></i> &nbsp; Delete
        </button>
      </>
    );
  };
  const confirmDelete = (event: any, rule: CustomerRule) => {
    console.log(rule);

    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to delete this rule?",
      icon: "pi pi-exclamation-triangle",
      accept: () => deleteRule(rule),
      reject: () => {},
    });
  };
  const onEdit = (e: any, rowData: CustomerRule) => {
    props.setIsUpdated(true);
    props.setSelectedRowData(rowData);
    props.changeScreen(ScreenType.ADD);
  };
  const displayCustomerData = (col: any, rowData: any) => {
    if (col.field === "ruleName") {
      return rowData.customerRuleKey[col.field];
    }
    return rowData[col.field];
  };

  const onUpdateCustomerRules = (index: number) => {
    setIsLoading(true);
    RulesService.updateCustomerRules(draggableRules.current)
      .then((res) => {
        if (res && res.data) {
          let _allValues = [...customerRules];
          _allValues[index] = res.data;
          setCustomerRules(_allValues);
          draggableRules.current = [];
          showToast("Customer Rules updated successfully!", TOAST.SUCCESS);
          setPrefConfirmDailog(false);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        draggableRules.current = [];
        showAPIToast(err, "while updating rules!");
        setIsLoading(false);
      });
  };

  const onRejectUpdateCustomerRules = () => {
    draggableRules.current = [];
    setPrefConfirmDailog(false);
    getCustomerRules();
  };

  const onAddBtn = () => {
    props.setSelectedRowData({} as CustomerRule);
    props.setIsUpdated(false);
    props.setIsRuleIdAdded(false);
    props.changeScreen(ScreenType.ADD);
  };

  const deleteRule = (rule: CustomerRule) => {
    setIsLoading(true);
    RulesService.deleteCustomerRule(rule.customerRuleKey)
      .then((res) => {
        if (res.data) {
          showToast("Rule deleted successfully!", TOAST.SUCCESS);
          let _allCustomerRules = [...customerRules];
          if (res.data.ruleId === null) {
            let index = _allCustomerRules.findIndex(
              (a) => a.ruleId === rule.customerRuleKey.ruleId
            );
            _allCustomerRules.splice(index, 1);
          } else {
            for (const val of _allCustomerRules) {
              if (val.ruleId === res.data.ruleId) {
                val.rules = res.data.rules;
              }
            }
          }
          setCustomerRules(_allCustomerRules);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        showAPIToast(err, "while deleting rule!", "del_pRule");
        setIsLoading(false);
      });
  };

  return (
    <div style={{ minWidth: "1000px" }}>
      <LargeLoader isLoading={isLoading} />
      <div className="content-panel-header head multiple">
        Customer Created Rules
      </div>
      <div className="rule-filter pz-card  mx-2 p-2 d-flex align-items-center justify-content-between">
        <div>
          <button className="refresh" onClick={getCustomerRules}>
            <i className="fa fa-refresh m-2" aria-hidden="true"></i>
            <span className="label">Refresh</span>
          </button>
        </div>
        <div className="search-input">
          <input
            type="text"
            onChange={(e: any) => setSearchTerm(e.target.value)}
            placeholder="Search Customer Rules"
            className="inputWrp"
          />
          <i className="icon fa fa-search"></i>
        </div>
      </div>
      <div>
        {ViewFilterPanel}
        {customerRules?.map((res: CustomerRules, index: number) => {
          res.rules.sort((a, b) => (a.prefNo < b.prefNo ? -1 : 1));
          return (
            res.ruleId.toLowerCase().includes(searchTerm) && (
              <Accordion multiple activeIndex={[0]} key={index} className="p-2">
                <AccordionTab
                  headerClassName="headerClass"
                  header={_header(res)}
                >
                  <div>
                    <DataTable
                      value={res.rules}
                      reorderableColumns
                      onRowReorder={(e: any) => onRowReorder(e, res)}
                      className="pz-prime-table"
                    >
                      <Column rowReorder style={{ width: "3em" }} />
                      {dynamicColumns}
                    </DataTable>
                    <Dialog
                      footer={
                        <div>
                          <button
                            className="button-style-selected"
                            onClick={() => onUpdateCustomerRules(index)}
                          >
                            Yes
                          </button>
                          <button
                            className="button-style-selected"
                            onClick={onRejectUpdateCustomerRules}
                          >
                            No
                          </button>
                        </div>
                      }
                      visible={prefConfirmDailog}
                      className="pref-dialog-class"
                      style={{ width: "20vw" }}
                      onHide={onRejectUpdateCustomerRules}
                      draggable={false}
                    >
                      <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                        Are You Sure You Want to change Preference Number ?
                      </span>
                    </Dialog>
                  </div>
                </AccordionTab>
              </Accordion>
            )
          );
        })}
        {getFloatButton({ onClick: onAddBtn })}
        {customerRules.length === 0 && (
          <div
            className="pz-card p-4"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <h2>Data Not Available !!!</h2>
          </div>
        )}
      </div>
    </div>
  );
}

import { createContext } from "react";
import { PoolRuleDTO } from "../Models/poolRuleDTO";
import { TrackInfoDTO } from "../Models/trackInfoDTO";

export interface RulesContextDTO {
  
  provinceList: { province: string }[];
  tracks: TrackInfoDTO[];
  poolRuleRows: PoolRuleDTO[],
  selectedProvince: string;
  selectedTrack: TrackInfoDTO;
  isLoading: boolean;
  startLoader: () => void;
  stopLoader: () => void;
  // Objects
  scrProfiles: any;
  poolRules: any;
  // getScrProfiles: () => void;
  updateRuleContext: (ruleContext: RulesContextDTO) => void;
}

export const RulesContext = createContext<RulesContextDTO>({} as any);

export const RulesContextProvider = RulesContext.Provider;

export const RulesContextConsumer = RulesContext.Consumer;

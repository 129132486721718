import { useEffect, useState } from "react";
import ViewSearchCustomer from "../ViewSearchCustomer/ViewSearchCustomer";
import { UserApiService } from "../../../../services/userApiService";
import { showToast, TOAST } from "../../../../Helpers/ui-helper";
import ViewCustomerSummary from "../ViewCustomerSummary/ViewCustomerSummary";

export enum CustomerScreenType {
  SEARCH_CUSTOMER,
  VIEW_CUSTOMER,
}

const SearchCustomer = () => {
  // variables
  const [customerData, setCustomerData] = useState([]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
  });
  const [selectedCustomer, setSelectedCustomer] = useState<any>({});

  const [isSet,setIsSet] = useState(false)
  const [customerScreen, setCustomerScreen] = useState(
    CustomerScreenType.SEARCH_CUSTOMER
  );
  const [customerFilter, setCustomerFilter] = useState("");

  // functions
  const changeScreen = (screen: number) => {
    if (screen === 1) {
      setCustomerScreen(CustomerScreenType.VIEW_CUSTOMER);
    } else {
      setCustomerScreen(CustomerScreenType.SEARCH_CUSTOMER);
    }
  };

  const openCustomerPanel = (rowData: any) => {
    setSelectedCustomer(rowData);
    changeScreen(CustomerScreenType.VIEW_CUSTOMER);
  };

  const onPage = (event: any) => {
    let values = { ...lazyParams };
    values = {
      first: event.first,
      rows: event.rows,
      page: event.page + 1,
    };
    setLazyParams(values);
  };

  useEffect(() => {
  setIsSet(true)
    onCustomerFilterChange("a");
  }, []);

  const onCustomerFilterChange = (e: any) => {

    if(isSet)
    setCustomerFilter(e);
    if (!e.length) {
      setCustomerData([]);
      return;
    }
    UserApiService.getClientFilteredData(e.trim())
      .then((res) => {
        if (res.data) {
          let list: any = [];
          console.log(res.data)
          for (const key of res.data) {
            let obj: any = {};
            obj["customerKey"] = key.customerKey.customerKey;
            // obj["email"] = key.email;
            // obj["phoneNo"] = key.phoneNo;
            obj["customerName"] = key.customerName;
            list.push(obj);
          }
          setCustomerData(list);
        }
      })
      .catch((err) => {
        setCustomerData([]);
        if (err.response) {
          showToast(err.response["data"].message, TOAST.ERROR);
        } else {
          showToast("Server did not respond! Please try later!", TOAST.ERROR);
        }
      });
  };
  const ScreenTemplate = () => {
    switch (customerScreen) {
      case CustomerScreenType.SEARCH_CUSTOMER:
        return (
          <ViewSearchCustomer
            customerData={customerData}
            customerType="search"
            onCustomerFilterChange={(e: any) =>
              onCustomerFilterChange(e.target.value)
            }
            customerFilter={customerFilter}
            lazyParams={lazyParams}
            openCustomerPanel={openCustomerPanel}
            onPage={onPage}
          />
        );
      case CustomerScreenType.VIEW_CUSTOMER:
        return (
          <ViewCustomerSummary
            changeScreen={changeScreen}
            selectedCustomer={selectedCustomer}
          />
        );
      default:
        break;
    }
  };

  return <div>{ScreenTemplate()}</div>;
};

export default SearchCustomer;

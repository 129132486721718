import { TabView, TabPanel } from "primereact/tabview";
import React, { useEffect, useState } from "react";
import { showToast, TOAST } from "../../../Helpers/ui-helper";
import { RulesService } from "../../../services/rulesApiService";
import PZInputField from "../../../UI-Components/Inputs/inputField";
import PzDropDown from "../../../UI-Components/Inputs/pzDropDown";
import LargeLoader from "../../../UI-Components/Loader/loaders";
import { ScreenType } from "../connectors";
import "./AddConnectors.scss";

export interface AddConnectorsScreenProps {
  changeScreen: (screen: ScreenType) => void;
  betFairLoginStatus: any;
  setIsLoading: any;
  isLoading: any;
  setAccountId: any;
  setBetfairBalanceResponse: any;
  betfairBalanceResponse: any;
  accountId: any;
  betfairUrlResponse: any;
  isLoggedIn: any;
  setIsLoggedIn: any;
  isAccountIdDisable: any;
  setConnectorInfo: any;
  connectorInfo: any;
  setBetfairUrlResponse: any;
  setIsAccountIdDisable: any;
}

export interface ConnectorInfo {
  connectorUrl: string;
  connectorName: string;
  currency: string;
  accountId: string;
  connectorType: string;
}

const CurrencyList = [
  { label: "AUD", value: "AUD" },
  { label: "USD", value: "USD" },
  { label: "GPB", value: "GPB" },
  { label: "EUR", value: "EUR" },
  { label: "HKD", value: "HKD" },
];

export default function AddConnectors(props: AddConnectorsScreenProps) {
  const [connectorName, setConnectorName] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [isUrlErr, setIsUrlErr] = useState(false);
  const [isValidateUrl, setIsValidateUrl] = useState(false);

  useEffect(() => {
    onChangeTab({ index: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkValidation = () => {
    if (!connectorName) {
      showToast("connector Name Should Not be Empty", TOAST.ERROR);
      props.setIsLoading(false);
      return;
    }
    if (!selectedCurrency) {
      showToast("Select Currency", TOAST.ERROR);
      props.setIsLoading(false);
      return;
    }
  };

  const onSetAccountID = (e: any) => {
    props.setAccountId(e.target.value);
  };

  const onCheckBetfairLoginstatus = (
    value: any,
    verifyUrlBodyReq: any,
    resData: any
  ) => {
    if (value.connectorType === "AMTOTE") {
      let reqbody: any = verifyUrlBodyReq;
      reqbody.accountId = resData.accountId;
      RulesService.checkBetfairLoginStatus(reqbody)
        .then((res) => {
          if (res.data) {
            props.setBetfairBalanceResponse(res.data);
            props.setIsLoggedIn(true);
          }
          props.setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            showToast(err.response["data"].message, TOAST.ERROR);
          } else {
            showToast("Server did not respond! Please try later!", TOAST.ERROR);
          }
          props.setIsLoading(false);
        });
    }
  };

  const onUrlVerify = () => {
    props.setIsLoading(true);
    let value = { ...props.connectorInfo };
    let verifyUrlBodyReq: any = {};
    verifyUrlBodyReq.url = value.connectorUrl;
    verifyUrlBodyReq.connectorType = value.connectorType;

    RulesService.getVerifyUrl(verifyUrlBodyReq)
      .then((res: any) => {
        if (res.data) {
          onCheckBetfairLoginstatus(value, verifyUrlBodyReq, res.data);
          props.setBetfairUrlResponse(res.data);
          setIsValidateUrl(true);
        }
        props.setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        if (err.response["data"].message) {
          showToast(err.response["data"].message, TOAST.ERROR);
        } else {
          showToast("Server did not respond! Please try later!", TOAST.ERROR);
        }
        props.setIsLoading(false);
      });
  };

  const onConnectorAdd = () => {
    props.setIsLoading(true);
    checkValidation();
    let addConnectorRequest: any = {};
    addConnectorRequest.accountId = props.accountId
      ? props.accountId
      : props.betfairUrlResponse.accountId;
    addConnectorRequest.connectorName = connectorName;
    addConnectorRequest.connectorType = props.betfairUrlResponse?.connectorType;
    addConnectorRequest.currency = selectedCurrency;
    addConnectorRequest.url = props.connectorInfo.connectorUrl;
    addConnectorRequest.vendorId = props.betfairUrlResponse?.accountId;
    RulesService.getAddConnectors(addConnectorRequest)
      .then((res: any) => {
        if (res.data) {
          props.setIsLoading(false);
          showToast("Successfully Added Connector", TOAST.SUCCESS);
          props.changeScreen(ScreenType.VIEW);
        }
      })
      .catch((err: any) => {
        console.log(err);
        if (err.response) {
          showToast(err.response["data"].message, TOAST.ERROR);
        } else {
          showToast("Server did not respond", TOAST.ERROR);
        }
        props.setIsLoading(false);
      });
  };

  const onChangeTab = (e: any) => {
    let value = {} as ConnectorInfo;
    setActiveIndex(e.index);
    props.setIsLoggedIn(false);
    setIsValidateUrl(false);
    props.setIsAccountIdDisable(false);
    setIsUrlErr(false);
    props.setAccountId("");

    if (e.index === 0) {
      value.connectorType = "AMTOTE";
    } else if (e.index === 1) {
      value.connectorType = "BETFAIR";
    }
    props.setConnectorInfo(value);
  };

  const validUrl = (value: string) => {
    var urlRegEx = new RegExp(
      "^(http|https|ftp)://([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&amp;%$-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]).(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0).(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0).(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|([a-zA-Z0-9-]+.)*[a-zA-Z0-9-]+.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(/($|[a-zA-Z0-9.,?'\\+&amp;%$#=~_-]+))*$"
    );
    if (!urlRegEx.test(value)) {
      setIsUrlErr(true);
    } else {
      setIsUrlErr(false);
    }
  };

  const onUrlChange = (e: any) => {
    validUrl(e.target.value);
    let value = { ...props.connectorInfo };
    value.connectorUrl = e.target.value;
    props.setConnectorInfo(value);
  };

  const urlTemplate = () => {
    return (
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: "30%", color: "white" }}>
            <PZInputField
              type="text"
              label="Connector URL"
              value={props.connectorInfo?.connectorUrl}
              name="url"
              placeholder="Enter URL"
              className=" my-4 "
              onChange={onUrlChange}
              disabled={isValidateUrl}
            />
          </div>
          <div style={{ marginLeft: "1rem" }}>
            <button className="pz-btn primary rounded" onClick={onUrlVerify}>
              Verify
            </button>
          </div>
        </div>
        {isUrlErr && (
          <span
            style={{
              color: "red",
              padding: "10px",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            Is Not Valid URL
          </span>
        )}
      </>
    );
  };

  const betfairTemplate = () => {
    return (
      <>
        <div>
          {urlTemplate()}
          {isValidateUrl && (
            <div className="d-flex align-items-center">
              <div style={{ width: "30%", color: "white" }}>
                <PZInputField
                  type="text"
                  label="Betfair User Name"
                  value={props.accountId}
                  name="accountId"
                  placeholder="Enter Betfair User Name"
                  className=" my-4 "
                  onChange={onSetAccountID}
                  disabled={props.isAccountIdDisable}
                />
              </div>
              <div>
                <button
                  className="pz-btn primary rounded"
                  onClick={props.betFairLoginStatus}
                  disabled={props.isLoggedIn}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
          )}
        </div>

        {props.isLoggedIn && (
          <div className="pz-card my-3 mx-0 p-3 " style={{ width: "30%" }}>
            <div
              className="d-flex justify-content-end"
              style={{ borderBottom: " 1px solid #525252" }}
            >
              <div className="balance-container">
                <span className="card-label">Balance :</span>
                <span
                  className="name"
                  style={{
                    color:
                      props.betfairBalanceResponse.balance >= 0
                        ? "rgb(54 217 54)"
                        : "red",
                  }}
                >
                  ${props.betfairBalanceResponse.balance}
                </span>
              </div>
            </div>
            <div className="p-3">
              <div>
                <PZInputField
                  type="text"
                  label="Connector Name"
                  value={connectorName}
                  name="Name"
                  placeholder="Enter Name"
                  className=" my-4 pt-3"
                  onChange={(e: any) => setConnectorName(e.target.value)}
                />
              </div>
              <div>
                <PzDropDown
                  options={CurrencyList}
                  onChange={(e: any) => setSelectedCurrency(e.target.value)}
                  placeHolder="Select a Currency"
                  name="currency"
                  value={selectedCurrency}
                />
              </div>
            </div>
            <div>
              <button
                className="pz-btn primary rounded"
                onClick={onConnectorAdd}
              >
                SUBMIT
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  const amtoteTemplate = () => {
    return (
      <div>
        {urlTemplate()}
        {props.isLoggedIn && (
          <div className="pz-card my-3 mx-0 p-3 " style={{ width: "30%" }}>
            <div className="p-3">
              <div
                className="d-flex justify-content-end"
                style={{ borderBottom: " 1px solid #525252" }}
              >
                <div className="balance-container">
                  <span className="card-label">Balance :</span>
                  <span
                    className="name"
                    style={{
                      color:
                        props.betfairBalanceResponse.balance >= 0
                          ? "rgb(54 217 54)"
                          : "red",
                    }}
                  >
                    ${props.betfairBalanceResponse.balance}
                  </span>
                </div>
              </div>
              <div>
                <PZInputField
                  type="text"
                  label="Account Id"
                  value={props.betfairBalanceResponse.accountId}
                  name="AccountId"
                  className=" my-4 "
                  onChange={() => {}}
                  disabled={true}
                />
              </div>
              <div>
                <PZInputField
                  type="text"
                  label="Connector Name"
                  value={connectorName}
                  name="Name"
                  placeholder="Enter Name"
                  className="my-4 pt-3"
                  onChange={(e: any) => setConnectorName(e.target.value)}
                />
              </div>
              <div>
                <PzDropDown
                  options={CurrencyList}
                  onChange={(e: any) => setSelectedCurrency(e.target.value)}
                  placeHolder="Select a Currency"
                  name="currency"
                  value={selectedCurrency}
                />
              </div>
            </div>
            <div>
              <button
                className="pz-btn primary rounded"
                onClick={onConnectorAdd}
              >
                SUBMIT
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="pz-card w-100">
      <LargeLoader isLoading={props.isLoading} />
      <header className="page-head b-bottom">
        <span className="b-right">
          <button
            onClick={() => props.changeScreen(ScreenType.VIEW)}
            className="pz-btn icon-btn hover"
            style={{ color: "white" }}
          >
            <i className="fa fa-arrow-left"></i> Back
          </button>
          &nbsp;&nbsp;&nbsp;
        </span>
        &nbsp;&nbsp;&nbsp;
        <span style={{ color: "white" }}>Connectors</span>
      </header>
      <div className="inline-height p-4">
        <div className="pz-card my-3 mx-0 p-3">
          <TabView
            className="tab-view"
            activeIndex={activeIndex}
            onTabChange={onChangeTab}
          >
            <TabPanel header="AMTOTE">{amtoteTemplate()}</TabPanel>
            <TabPanel header="BETFAIR">{betfairTemplate()}</TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  );
}

import { format } from "date-fns";
import { confirmPopup } from "primereact/confirmpopup";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { RulesContext } from "../../../../../Context/rulesContext";
import { FORMAT } from "../../../../../Helpers/constants";
import {
  CLOG,
  showAPIToast,
  showToast,
  TOAST,
} from "../../../../../Helpers/ui-helper";
import { filterValues } from "../../../../../Helpers/valueHelper";
import { ScratchProfileDTO } from "../../../../../Models/scratchProfileDTO";
import { RulesService } from "../../../../../services/rulesApiService";

export default function ViewProfiles() {
  const rlContext = useContext(RulesContext);
  const [filteredProfiles, setFilteredProfiles] = useState<any>({});

  useEffect(() => {
    getScrProfiles();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getScrProfiles = () => {
    rlContext.startLoader();
    RulesService.getScrProfiles()
      .then((res) => {
        let context = { ...rlContext };
        CLOG("=> API____SCR PROFILES FETCHED", "purple");

        if (res.data) {
          let profiles = res.data.sort((a: any, b: any) =>
            a.profileName.toLowerCase() < b.profileName.toLowerCase() ? -1 : 1
          ) as ScratchProfileDTO[];
          let profileObj = {} as any;
          for (let profile of profiles) {
            if (!profile.deleted)
              profileObj[profile.scratchedProfileKey.scratchedProfileId] =
                profile;
          }
          context.scrProfiles = profileObj;
          setFilteredProfiles(context.scrProfiles);
        }
        context.isLoading = false;
        context.updateRuleContext(context);
      })
      .catch((err) => {
        rlContext.stopLoader();
        console.error(err);
        showAPIToast(err, "while fetching Scratch Profiles");
      });
  };

  const onSearchProfile = (e: ChangeEvent<HTMLInputElement>) => {
    let filtered = filterValues(
      e.target.value,
      Object.values(rlContext.scrProfiles),
      Object.values(filteredProfiles),
      "profileName"
    ) as ScratchProfileDTO[];
    let tempObj = {} as any;
    for (let profile of filtered) {
      if (profile.scratchedProfileKey.scratchedProfileId) {
        tempObj[profile.scratchedProfileKey.scratchedProfileId] = profile;
      }
    }
    setFilteredProfiles(tempObj);
  };

  const deleteScrProfile = (scrProfile: ScratchProfileDTO) => {
    if (scrProfile.scratchedProfileKey?.scratchedProfileId) {
      let context = { ...rlContext };
      context.startLoader();
      RulesService.deleteScrProfile(
        scrProfile.scratchedProfileKey?.scratchedProfileId
      )
        .then((res) => {
          if (res) {
            getScrProfiles();
            showToast("scratch profile deleted successfully!", TOAST.SUCCESS);
          }
          context.isLoading = false;
          context.updateRuleContext(context);
        })
        .catch((err) => {
          context.stopLoader();
          console.error(err);
          showAPIToast(err, "while deleting profile", "del_profile");
        });
    }
  };

  const confirmDelete = (event: any, profile: ScratchProfileDTO) => {
    confirmPopup({
      target: event.currentTarget,
      message: `Are you sure you want to delete ${profile.profileName}?`,
      icon: "pi pi-exclamation-triangle",
      accept: () => deleteScrProfile(profile),
    });
  };

  let _filteredProfilesList = Object.values(
    filteredProfiles
  ) as ScratchProfileDTO[];
  return (
    <div className="" style={{ flexGrow: 1 }}>
      <header className="page-head">View Scratch Profile</header>
      <div className="inline-height p-4">
        <div className="rule-filter pz-card my-3 mx-0 p-3 d-flex align-items-center justify-content-between">
          <div></div>
          <div className="search-input" style={{border:"none"}}>
            <input
              type="text"
              onChange={onSearchProfile}
              placeholder="Search Profiles"
            />
            <i className="icon fa fa-search"></i>
          </div>
        </div>
        <div className="profile-card-container">
          {_filteredProfilesList.map((profile: ScratchProfileDTO) => {
            return (
              <div key={profile.profileName} className="profile-card">
                <header>
                  <div>
                    <div className="profile-name"> {profile.profileName}</div>
                    <div className="label">Profile Name </div>
                  </div>

                  <span className="deduction">
                    <div className="perc">
                      {profile.scratchedDedPer?.toFixed(2) ?? "-"}
                    </div>
                    <div className="label">Deduction %</div>
                  </span>
                </header>

                <section>
                  <div>
                    <div
                      className="profile-name"
                      style={{ fontSize: "1rem", fontWeight: 500 }}
                    >
                      {format(
                        profile.createdTimestamp,
                        FORMAT.ddMMyyyyHHmmss_DASH
                      )}
                    </div>
                    <span className="label">Created On</span>
                  </div>
                  <div className="deduction">
                    <div className="perc">{profile.deductionUsing ?? "-"}</div>
                    <div className="label text-right">Deduction Using</div>
                  </div>
                </section>

                <footer>
                  {!profile.deleted && (
                    <button
                      className="del"
                      onClick={(event: any) => confirmDelete(event, profile)}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  )}

                  {profile.deleted && <span className="badge">DELETED</span>}
                </footer>
              </div>
            );
          })}
          {!_filteredProfilesList.length && (
            <span className="h3 text-muted">Profiles not found!</span>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import "./ViewConnectors.scss";
import { confirmPopup } from "primereact/confirmpopup";

import { ChangeEvent } from "react";
import { ScreenType } from "../connectors";
import { RulesService } from "../../../services/rulesApiService";
import { showToast, TOAST } from "../../../Helpers/ui-helper";
import LargeLoader from "../../../UI-Components/Loader/loaders";

export interface ViewConnectorScreenProps {
  changeScreen: (screen: ScreenType) => void;
  onBetFairLoginWindow: any;
  getAllConnectorsData: any;
  getAllConnectors: any;
  filteredConnectors: any;
  setFilteredConnectors: any;
}

export default function ViewConnectors(props: ViewConnectorScreenProps) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    
    props.getAllConnectorsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmDelete = (event: any, data: any) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to delete this Connector ? ",
      icon: "pi pi-exclamation-triangle",
      accept: () => deleteConnector(data),
    });
  };

  const deleteConnector = (data: any) => {
    setIsLoading(true);
    RulesService.deleteConnector(data.accountId)
      .then((res: any) => {
        if (res.data) {
          setIsLoading(false);
          props.getAllConnectorsData();
        } else {
          setIsLoading(false);
        }
      })
      .catch((err: any) => {
        console.log(err);
        if (err.response) {
          showToast(err.response["data"].message, TOAST.ERROR);
        } else {
          showToast("Server did not respond! Please try later!", TOAST.ERROR);
        }
        setIsLoading(false);
      });
  };

  const onSearchConnectors = (e: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase();
    const filteredData = props.filteredConnectors.filter((value: any) => {
      return (
        value.connectorName.toLocaleLowerCase().includes(searchTerm) ||
        value.accountId.toLocaleLowerCase().includes(searchTerm) ||
        value.connectorType.toLocaleLowerCase().includes(searchTerm)
      );
    });
    if (e.target.value === null || e.target.value === "") {
      props.setFilteredConnectors(props.getAllConnectors);
    } else {
      props.setFilteredConnectors(filteredData);
    }
  };

  return (
    <div className="w-100">
      <LargeLoader isLoading={isLoading} />
      <div>
        <div
          className="content-panel-header head multiple p-2"
          style={{ color: "white" }}
        >
          Available Connectors
        </div>
        <div className="">
          <div className="rule-filter  p-2 d-flex align-items-center justify-content-between">
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                fontSize: "15px",
              }}
            >
              <button className="refresh" onClick={props.getAllConnectorsData}>
                <i className="fa fa-refresh m-2" aria-hidden="true"></i>
                <span className="label">Refresh</span>
              </button>
            </div>
            <div className="search-input ">
              <input
                type="text"
                onChange={onSearchConnectors}
                placeholder="Search Term"
                style={{border:"none"}}
              />
              <i className="icon fa fa-search"></i>
            </div>
          </div>

          <div className="card-container">
            {props.filteredConnectors.map((x: any, index: number) => {
              return (
                <div key={x.connectorName}>
                  <div className="cardConnector">
                    <header>
                      <div className="">
                        <span className="label">Account Id</span>
                        <div className="name" style={{ color: "white" }}>
                          {x.accountId}
                        </div>
                      </div>
                      <div style={{ textAlign: "end" }}>
                        <div className="label">Balance</div>
                        <div className="name" style={{ color: "white" }}>
                          {x.balance === null ? "-" : x.balance} - {x.currency}
                        </div>
                      </div>
                    </header>
                    <section>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          className="p-2"
                        >
                          <div>
                            <div className="label">Connector Name </div>
                            <div className="cname"> {x.connectorName}</div>
                          </div>
                          <div style={{ textAlign: "end" }}>
                            <div className="label">Connector Type</div>
                            <div className="cname">{x.connectorType}</div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          className="p-2"
                        >
                          <div>
                            <span className="label">Last Connected</span>
                            <div className="cname">
                              {x.lastConnected === 0 ? "-" : x.lastConnected}
                            </div>
                          </div>
                          <div style={{ textAlign: "end" }}>
                            <span className="label">Last Disconnected</span>
                            <div className="cname">
                              {x.lastDisconnected === 0
                                ? "-"
                                : x.lastDisconnected}
                            </div>
                          </div>
                        </div>
                        <div className="p-2">
                          <span className="label">Connector URL</span>
                          <div className="cname">{x.url}</div>
                        </div>
                      </div>
                    </section>
                    <footer>
                      {x.connectionsStatus === "EXPIRED" && (
                        <div>
                          <button
                            className="pz-btn primary py-2 px-4"
                            onClick={() =>
                              props.onBetFairLoginWindow("view", x)
                            }
                          >
                            Login
                          </button>
                        </div>
                      )}
                      {x.connectionsStatus !== "EXPIRED" && (
                        <>
                          <span
                            className="d-inline-block"
                            data-toggle="tooltip"
                            title={
                              x.connectionsStatus === "CONNECTED"
                                ? "CONNECTED"
                                : "DISCONNECTED"
                            }
                          >
                            <i
                              className="fa fa-globe"
                              style={{
                                color:
                                  x.connectionsStatus === "CONNECTED"
                                    ? "#07ff07"
                                    : "red",
                                fontSize: "15px",
                              }}
                            />
                          </span>
                        </>
                      )}
                      <button
                        className="del"
                        onClick={(event: any) => confirmDelete(event, x)}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </footer>
                  </div>
                </div>
              );
            })}
            {props.getAllConnectors.length === 0 && (
              <span className="h3 text-muted">Connectors not Available!</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

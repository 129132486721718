import React, { useState } from "react";
import ViewCustomerSummary from "../ViewCustomerSummary/ViewCustomerSummary";
import ViewSearchCustomerReport from "../ViewSearchCustomerReport/ViewSearchCustomerReport";
export enum CustomerScreenType {
  VIEW_SEARCH_CUSTOMER_REPORT,
  VIEW_CUSTOMER,
}

const SearchCustomerReport = () => {
  // variables
  const [changeCustomerScreen, setChangeCustomerScreen] = useState(
    CustomerScreenType.VIEW_SEARCH_CUSTOMER_REPORT
  );
  const [selectedCustomer, setSelectedCustomer] = useState({} as any);

  // functions
  const changeScreen = (screen: any) => {
    if (screen === 1) {
      setChangeCustomerScreen(CustomerScreenType.VIEW_CUSTOMER);
    } else {
      setChangeCustomerScreen(CustomerScreenType.VIEW_SEARCH_CUSTOMER_REPORT);
    }
  };

  const openCustomerPanal = (rowData: any) => {
    setSelectedCustomer(rowData);
    setChangeCustomerScreen(CustomerScreenType.VIEW_CUSTOMER);
  };

  // Send Props
  const viewCustomerySummaryProps = { changeScreen, selectedCustomer };
  const viewSearchCustomerReportProps = { openCustomerPanal };

  // screen templete
  const templeteScreen = () => {
    switch (changeCustomerScreen) {
      case CustomerScreenType.VIEW_SEARCH_CUSTOMER_REPORT:
        return <ViewSearchCustomerReport {...viewSearchCustomerReportProps} />;
      case CustomerScreenType.VIEW_CUSTOMER:
        return <ViewCustomerSummary {...viewCustomerySummaryProps} />;
    }
  };

  return <div>{templeteScreen()}</div>;
};

export default SearchCustomerReport;

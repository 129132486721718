import { OverlayPanel } from "primereact/overlaypanel";
import React, { useCallback, useContext, useRef, useState } from "react";
import { format } from "date-fns";
import { FixedOddsContext } from "../../../Context/fixedOddsContext";
import { ColsOddsINV, TableColumnsDTO } from "../../../Helpers/columns";
import { showAPIToast, showToast, TOAST } from "../../../Helpers/ui-helper";
import { __SelToNumbers } from "../../../Models/dynamicTypes";
import { OddsInvDTO, PoolDTO } from "../../../Models/PoolsDTO";
import { RaceTripDTO } from "../../../Models/raceTripsDTO";
import { FixedOddsManagementService } from "../../../services/fixedOddsManagementService";
import { ScheduleApiService } from "../../../services/scheduleApiService";
import "./TripsTable.scss";
import { dateConversionForInvOdds } from "../../../Helpers/dateTimeHelpers";
import { LOCAL_STORAGE } from "../../../Helpers/constants";
import {
  getLocalStorage,
  getLocalStorageObject,
  getPoolKeyStr,
} from "../../../Helpers/valueHelper";
import { resultedSort } from "../../../Helpers/list-helpers";
import { UserDTO } from "../../../Models/UserManagementDto";

import { useEffect } from "react";
import { IoMdTrophy } from "react-icons/io";
import { useRecoilValue } from "recoil";
import { resultsAtom } from "../../../store/scheduleManagement/schedule.store";

interface ITripsTableProps {
  columns: TableColumnsDTO[];
}
interface PanelDTO {
  selId: number;
  field: string;
  odds: number;
  selLb: number;
  maxBetAmount: number;
  poolLb: number;
  layOff: number;
  bookPer: number;
}

const TripsTable = ({ columns }: React.PropsWithChildren<ITripsTableProps>) => {
  const {
    selectedRaceTrips,
    selectedPool,
    selectedRace,
    totalsData,
    selProviderOdds,
    riskTableData,
    setSelectedPool,
    setSelProviderOdds,
    setRiskTableData,
  } = useContext(FixedOddsContext);

  const panelRef = useRef(null as any);
  const bookPerRef = useRef(null as any);
  const [newOdds, setNewOdds] = useState<any>();
  const [newBookPer, setNewBookPer] = useState("");
  const [newLayoff, setNewLayoff] = useState("");
  const [newMaxBetAmt, setNewMaxBetAmt] = useState("");
  const [newPoolLB, setNewPoolLB] = useState("");
  const [newSelLb, setNewSelLb] = useState("");
  const [panelObj, setPanelObj] = useState({} as PanelDTO);
  const [selectedProgramNo, setSelectedProgramNo] = useState("");
  const [oddsInv, setOddsInv] = useState([] as OddsInvDTO[]);
  const _user = getLocalStorageObject(LOCAL_STORAGE.USER) as UserDTO;
  const [payOff, setPayOff] = useState("");

  const resultsObj = useRecoilValue(resultsAtom);
  let _i = selectedPool?.poolDetails?.poolKey?.poolType?.endsWith("PLACE")
    ? 1
    : 0;

  const updatePayoff = useCallback(() => {
    let _payout: any = "--";

    if (
      selectedRace.raceStatus === "RESULTED" &&
      Object.keys(resultsObj ?? {}).length > 0
    ) {
      if (
        !riskTableData?.positions ||
        Object.keys(riskTableData?.positions ?? {}).length === 0
      ) {
        _payout = "--";
      } else {
        let selId = resultsObj?.finishingOrder
          ? resultsObj?.finishingOrder[_i]
          : null;
        if (selId) {
          if (riskTableData?.positions[selId] > 0) {
            _payout = "--";
          } else {
            _payout = Number(
              Math.abs(riskTableData?.positions[selId] ?? 0).toFixed(2)
            );
          }
        }
      }
    }

    setPayOff(_payout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskTableData]);

  useEffect(() => {
    updatePayoff();
  }, [riskTableData, updatePayoff]);


  // Table cell template for different column fields>
  const cellTemplate = (runner: RaceTripDTO, field: string, index: number) => {
    if (!runner?.raceTripKey) return null;
    let { selId } = runner.raceTripKey;
    switch (field) {
      case "horseNo":
        return runner?.raceTripKey.selId;
      case "runnerName":
        return runner.horseName;
      // case "layOff":
      //   return LiabilityTemplate(riskTableData.layOff, runner, field);
      case "suggestedOdds":
        return <SuggestedOddsTemplate runner={runner} />;
      case "odds":
        return LiabilityTemplate(riskTableData.odds, runner, field);
      case "selLb":
        return LiabilityTemplate(riskTableData.selLb, runner, field);
      case "maxBetAmounts":
        return LiabilityTemplate(riskTableData.maxBetAmounts, runner, field);
      case "usedLB":
        return !riskTableData.usedLB
          ? 0
          : riskTableData.usedLB[selId]
          ? riskTableData.usedLB[selId].toFixed(2)
          : 0;
      case "betsCount":
        return !riskTableData.betsCount
          ? 0
          : riskTableData.betsCount[selId]
          ? riskTableData.betsCount[selId]
          : 0;
      case "mai":
        return !riskTableData.mai || isNaN(riskTableData.mai[selId])
          ? 0
          : riskTableData.mai[selId]
          ? riskTableData.mai[selId]?.toFixed(2)
          : 0;
      case "layOffInvestment":
        return riskTableData.layOffInvestment &&
          riskTableData.layOffInvestment[selId]
          ? riskTableData.layOffInvestment[selId].toFixed(2)
          : 0;
      case "investment":
        return InvestmentTemplate(riskTableData.investment, selId);
      case "profit":
        return !riskTableData.positions
          ? 0
          : riskTableData.positions[selId]
          ? riskTableData.positions[selId].toFixed(2)
          : 0;
      case "payoff":
        return selectedPool?.poolDetails?.poolKey?.poolType === "FO_WIN"
          ? index === 0
            ? selectedRace?.raceStatus === "RESULTED" && payOff
            : "--"
          : selectedPool.poolDetails?.poolKey?.poolType === "FO_PLACE"
          ? index === 1
            ? selectedRace?.raceStatus === "RESULTED" && payOff
            : "--"
          : "";
      default:
        return "-";
    }
  };
  // cell template for investment related data
  const InvestmentTemplate = (invData: __SelToNumbers, selId: string) => {
    if (invData) return invData[selId]?.toFixed(2) ?? 0;
    return 0;
  };

  // cell template for liability related data
  const LiabilityTemplate = (
    lbData: __SelToNumbers,
    runner: RaceTripDTO,
    field: string
  ) => {
    let data = "0";
    if (lbData) data = lbData[runner.raceTripKey.selId]?.toFixed(2) ?? data;
    return EditableCellTemplate(data, field, runner);
  };

  // Cell with pencil button to edit certain data
  const EditableCellTemplate = (
    data: any,
    field: string,
    runner: RaceTripDTO
  ) => {
    if (field === "odds" && selectedPool?.poolConfig?.oddsManagedBy === "AUTO")
      return <span>{data}</span>;

    if (field === "usedLB") {
      return <span>{data}</span>;
    }
    return (
      <div className="d-flex justify-content-between">
        <span>{data}</span>

        {!runner.scratched &&
          selectedRace.raceStatus !== "CLOSED" &&
          selectedRace.raceStatus !== "RESULTED" && (
            <span>
              <button
                onClick={(e) => openPanel(e, field, runner.raceTripKey.selId)}
                className="pz-btn icon-btn"
              >
                <i className="fa fa-pencil"></i>
              </button>
            </span>
          )}
      </div>
    );
  };

  // Odds investment template for panel
  const oddsInvPanelTemplate = () => {
    return (
      <div
        className="pz-card text-light"
        style={{ position: "relative", minWidth: "17rem" }}
      >
        <div className="pz-card-header b-bottom pb-3">
          <h3>
            <strong>Odds & Investments</strong>
          </h3>
        </div>
        <div className="pz-card-body">
          <div className="panel-table-container">
            <table className="panel-table">
              <thead>
                <tr>
                  {ColsOddsINV.map((col) => {
                    return <th key={col.field}>{col.header}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {oddsInv.map((inv: any, index) => {
                  return (
                    <tr key={index}>
                      {ColsOddsINV.map((col, index) => {
                        return (
                          <td
                            className=" font-weight-bolder font-italic"
                            style={{ color: "#bdbdbd" }}
                            key={index}
                          >
                            {inv[col.field]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                {oddsInv.length === 0 && (
                  <tr>
                    <td colSpan={3} className="p-0">
                      <div className="p-4 bg bg-dark text-muted text-center">
                        Investment Data Not Found!
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  // Template for suggested odds column
  const SuggestedOddsTemplate = ({ runner }: { runner: RaceTripDTO }) => {
    const { selId } = runner.raceTripKey;
    const isBtnDisplay =
      selectedPool?.poolConfig?.oddsManagedBy !== "AUTO" &&
      (selectedRace?.raceStatus === "OPEN" ||
        selectedRace?.raceStatus === "NON_DISPLAY");

    let data =
      runner.scratched ||
      (riskTableData.suggestedOdds && !riskTableData.suggestedOdds[selId])
        ? 0
        : riskTableData.suggestedOdds
        ? riskTableData.suggestedOdds[selId]
        : 0;

    return (
      <div className="d-flex justify-content-between">
        <span>{data.toFixed(2)}</span>
        <span>
          {isBtnDisplay && !runner.scratched && (
            <button
              onClick={() => applySuggestedOdds(runner, data)}
              className="pz-btn icon-btn hover"
            >
              <i className="fa fa-send-o"></i>
            </button>
          )}
        </span>
      </div>
    );
  };

  // On click to apply selected runner suggested odds
  const applySuggestedOdds = (runner: RaceTripDTO, odds: number) => {
    let oddsObj = {
      poolKey: selectedPool.poolDetails.poolKey,
      reqOdds: { [runner.raceTripKey.selId]: odds },
    };
    FixedOddsManagementService.applySuggestedOdds(oddsObj)
      .then((res) => {
        if (res.data) {
          showToast("Odds Applied successfully!", TOAST.SUCCESS);
        }
      })
      .catch((err) => {
        console.error(err);
        showAPIToast(err, "while applying suggested odds", "so_update");
      });
  };

  // Apply all available runners suggested odds
  const applyAllSuggestedOdds = () => {
    let oddsObj = {
      poolKey: selectedPool.poolDetails.poolKey,
      reqOdds: riskTableData.suggestedOdds,
    };
    FixedOddsManagementService.applySuggestedOdds(oddsObj)
      .then((res) => {
        if (res.data) {
          showToast(
            "All Odds applied successfully!",
            TOAST.SUCCESS,
            "suggested-odds"
          );
        }
      })
      .catch((err) => {
        console.error(err);
        showAPIToast(err, "while applying suggested odds", "allSO");
      });
    // }
  };

  // Apply all suggested odds template
  const applyAllTemplate = () => {
    let isShow =
      selectedPool?.poolConfig?.oddsManagedBy !== "AUTO" &&
      (selectedRace?.raceStatus === "OPEN" ||
        selectedRace?.raceStatus === "NON_DISPLAY");
    if (isShow)
      return (
        <button
          onClick={() => applyAllSuggestedOdds()}
          className="apply-all-btn"
        >
          Apply All &nbsp; <i className="fa fa-send-o"></i>
        </button>
      );
  };
  const openBookPerPanel = (e: any) => {
    if (bookPerRef && bookPerRef.current) bookPerRef.current.toggle(e);
    setNewBookPer((riskTableData.bookPer * 100).toFixed(2));
  };

  const editBookPer = () => {
    let isShow =
      selectedPool?.poolConfig?.oddsManagedBy !== "AUTO" &&
      (selectedRace?.raceStatus === "OPEN" ||
        selectedRace?.raceStatus === "NON_DISPLAY");
    if (isShow) {
      return (
        <span>
          <button onClick={openBookPerPanel} className="pz-btn icon-btn">
            <i className="fa fa-pencil"></i>
          </button>
        </span>
      );
    }
  };
  // Footer row Template if LayOff is true
  const layOffFooterRowTemplate = () => {
    return (
      <tr>
        <td></td>
        <td></td>
        <td>{applyAllTemplate()}</td>
        <td
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Book : {(riskTableData.bookPer * 100).toFixed(2)}% {editBookPer()}
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>
          Total: <b>{totalsData.totalBets}</b>
        </td>
        <td></td>
        <td>
          Total: <b>{totalsData.totalInvestment}</b>
        </td>
        <td>{/* Total : <b>{totalsData.totalPosition}</b> */}</td>
      </tr>
    );
  };

  const SOFooterRowTemplate = () => {
    return (
      <tr>
        <td></td>
        <td></td>
        <td>{applyAllTemplate()}</td>
        <td
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Book : {(riskTableData.bookPer * 100).toFixed(2)} % {editBookPer()}
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>
          Total: <b>{totalsData.totalBets}</b>
        </td>
        <td>
          Total: <b>{totalsData.totalInvestment}</b>
        </td>
        <td>{/* Total : <b>{totalsData.totalPosition}</b> */}</td>
      </tr>
    );
  };

  // Not found data template
  const getNullMessage = () => {
    if (!selectedRace) {
      return (
        <tr>
          <td colSpan={9}>
            <span className="null-cell">Please select a race!</span>
          </td>
        </tr>
      );
    }
    let runners = Object.keys(selectedRaceTrips ?? {});
    if (!runners.length)
      return (
        <tr>
          <td colSpan={9}>
            <span className="null-cell">Pool Data Not Available...</span>
          </td>
        </tr>
      );
  };

  // Fetching selection wise provider odds
  const fetchProviderOdds = (selId: string) => {
    let { poolKeyString } = selectedPool.poolDetails;
    setSelProviderOdds([]);
    ScheduleApiService.getSelProviderOdds(poolKeyString, selId)
      .then((res) => {
        setSelectedProgramNo(selId);
        if (res.data) {
          setSelProviderOdds(res.data);
          console.log("=> PROVIDER ODDS", res.data);
        }
      })
      .catch((err) => {
        console.error(err);
        showAPIToast(err, "while fetching selection provider odds", "sp_odds");
      });
  };

  // On click of pencil button to open overlay panel
  const openPanel = (e: any, field: string, selId: string) => {
    e.stopPropagation();
    let _panelObj = { ...panelObj };
    _panelObj.field = field;

    _panelObj.maxBetAmount = riskTableData.maxBetAmounts
      ? riskTableData.maxBetAmounts[selId]
      : 0;
    _panelObj.odds = riskTableData.odds ? riskTableData.odds[selId] : 0;
    _panelObj.selLb = riskTableData.selLb ? riskTableData.selLb[selId] : 0;
    // _panelObj.layOff = riskTableData.layOff ? riskTableData.layOff[selId] : 0;
    setPanelObj(_panelObj);
    setNewMaxBetAmt("");
    if (field === "odds") {
      setNewOdds(_panelObj.odds);
    }
    setNewSelLb("");
    setNewPoolLB("");
    setNewLayoff("");

    // setSelectedPoolLB(riskTableData.poolLb);
    setSelectedProgramNo(selId);
    if (panelRef && panelRef.current) panelRef.current.toggle(e);
    if (field === "odds") {
      fetchProviderOdds(selId ?? "");
    }
  };

  // Keyup of inputs call on Enter Key
  const onKeyUpOfInputs = (e: any, field: string, isDeduct: boolean) => {
    if (e.shiftKey && e.key === "Enter") isDeduct = true;
    else if (e.key === "Enter") isDeduct = false;
    if (e.key === "Enter") {
      switch (field) {
        case "odds":
          updateOdds();
          break;
        case "selLb":
          updateSelLB(isDeduct);
          break;
        case "maxBetAmounts":
          updateMaxBetAmount(isDeduct);
          break;
        case "poolLb":
          updatePoolLB(isDeduct);
          break;
        case "layOff":
          updateLayoffPercentage(isDeduct);
          break;
        default:
          break;
      }
    } else if (e.key === "Escape") {
      panelRef.current?.hide();
    }
  };

  // Update odds data after edit in panel
  const updateOdds = () => {
    let odds = Number(newOdds);
    if (!odds) {
      return showToast("Enter odds first!", TOAST.ERROR);
    }

    let oddsObj = {
      poolKey: selectedPool.poolDetails.poolKey,
      reqOdds: { [selectedProgramNo]: odds },
    };
    FixedOddsManagementService.applySuggestedOdds(oddsObj)
      .then((res) => {
        if (res.data) {
          panelRef?.current.hide();
          showToast("Odds Updated successfully!", TOAST.SUCCESS);
        }
      })
      .catch((err) => {
        console.error(err);
        showAPIToast(err, "while updating odds", "u_odds");
      });
  };

  // Update selection liability after edit in panel
  const updateSelLB = (isDeduct: boolean) => {
    if (!newSelLb) return showToast("Enter Selection Liability!", TOAST.ERROR);

    let amount = Number(newSelLb);
    if (isDeduct && amount > 0) {
      amount = -amount;
    }

    FixedOddsManagementService.addSelLiabilities(
      selectedPool.poolDetails.poolKeyString,
      selectedProgramNo,
      amount
    )
      .then((res) => {
        if (res.data) {
          panelRef.current?.hide();
          showToast("Selection Liability Updated Successfully!", TOAST.SUCCESS);
        }
      })
      .catch((err) => {
        console.error(err);
        showAPIToast(err, "while updating selection liability", "uSelLb");
      });
  };

  // update max bet amount of selection in panel
  const updateMaxBetAmount = (isDeduct: boolean) => {
    if (!newMaxBetAmt) return showToast("Enter max bet amount!", TOAST.ERROR);

    let amount = Number(newMaxBetAmt);
    if (isDeduct && amount > 0) {
      if (amount > panelObj.maxBetAmount) {
        return showToast(
          "New Max Bet Amount should be less than current max bet amount!",
          TOAST.ERROR
        );
      }
      amount = -amount;
    }
    FixedOddsManagementService.addMaxBetAmounts(
      selectedPool.poolDetails.poolKeyString,
      selectedProgramNo,
      amount
    )
      .then((res) => {
        if (res.data) {
          panelRef?.current.hide();
          showToast("Max Bet Amount Updated!", TOAST.SUCCESS);
        }
      })
      .catch((err) => {
        console.error(err);
        showAPIToast(err, "while updating max bet amount", "u_m_bet");
      });
  };

  //  update pool lb
  const updatePoolLB = (isDeduct: boolean) => {
    if (!newPoolLB) return showToast("Enter Pool Liability!", TOAST.ERROR);

    let amount = Number(newPoolLB);
    if (isDeduct && amount > 0) {
      amount = -amount;
    }

    FixedOddsManagementService.updatePoolLB(
      amount,
      selectedPool.poolDetails.poolKeyString
    )
      .then((res) => {
        if (res.data) {
          panelRef.current?.hide();
          showToast("Pool Liability Updated Successfully!", TOAST.SUCCESS);
        }
      })
      .catch((err) => {
        console.error(err);
        showAPIToast(err, "while updating pool liability", "uPoolLb");
      });
  };

  // Update layoff percentage
  const updateLayoffPercentage = (isDeduct: boolean) => {
    if (!newLayoff) return showToast("Enter Lay Off first!", TOAST.ERROR);

    let amount = Number(newLayoff);
    if (isDeduct && amount > 0) {
      amount = -amount;
    }
    FixedOddsManagementService.addSelLayoff(
      selectedPool.poolDetails.poolKeyString,
      selectedProgramNo,
      amount
    )
      .then((res) => {
        if (res.data) {
          panelRef.current?.hide();
          setNewLayoff("");
          showToast("Pool Layoff Updated Successfully!", TOAST.SUCCESS);
        }
      })
      .catch((err) => {
        console.error(err);
        showAPIToast(err, "while updating pool layoff", "uPoolLayOff");
      });
  };

  // Panel template for odd edits
  const oddsPanelForm = () => {
    return (
      <div className="d-flex" style={{ position: "relative" }}>
        <div className="pz-card text-light" style={{ position: "relative" }}>
          <div className="selId-abs">{selectedProgramNo}</div>
          <div className="pz-card-header b-bottom pb-3">
            <h3>
              <strong>Odds</strong>
            </h3>
          </div>
          <div className="pz-card-body">
            <div>
              <label htmlFor="">Current Odds : </label>{" "}
              <span>{panelObj.odds?.toFixed(2)}</span>
            </div>
            <div className="py-2">
              <label htmlFor="" className="light-label">
                Enter New Odds
              </label>
              <input
                value={newOdds}
                onChange={(e) => setNewOdds(e.target.value)}
                type="number"
                autoFocus
                step={0.1}
                onKeyUp={(e: any) => onKeyUpOfInputs(e, "odds", false)}
                className="panel-input shadow"
              />
            </div>
            <div className="text-right">
              <button
                className="update-btn shadow shadow"
                onClick={() => updateOdds()}
              >
                Update
              </button>
            </div>
          </div>
        </div>
        {selProviderOdds?.length > 0 && (
          <div className="po-col">
            <div className="t-card">
              <table className="table m-0 table-striped table-dark shadow">
                <thead>
                  <tr>
                    <th>Provider</th>
                    <th>Odds</th>
                  </tr>
                </thead>
                <tbody>
                  {selProviderOdds?.map((obj: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td className="p-2 font-weight-bold">{obj.provider}</td>
                        <td className="p-2 text-warning">{obj.odds}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    );
  };

  const updateBookPer = () => {
    FixedOddsManagementService.editBookPer(
      selectedPool.poolDetails.poolKeyString,
      newBookPer
    )
      .then((res) => {
        if (res.data) {
          setRiskTableData(res.data);
          if (bookPerRef && bookPerRef.current) bookPerRef.current.hide();
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          showToast(err.response.data.message, TOAST.ERROR, "bookPer-err");
        }
      });
  };

  const onKeyUpOfBookPer = (e: any) => {
    if (e.key === "Enter") {
      updateBookPer();
    }
  };
  const bookPerPanelForm = () => {
    return (
      <div className="d-flex" style={{ position: "relative" }}>
        <div className="pz-card text-light" style={{ position: "relative" }}>
          <div className="pz-card-body">
            <div className="py-2">
              <label htmlFor="" className="light-label">
                Book Per :
              </label>
              <input
                value={newBookPer}
                onChange={(e) => setNewBookPer(e.target.value)}
                type="number"
                autoFocus
                step={0.1}
                onKeyUp={onKeyUpOfBookPer}
                className="panel-input shadow"
              />
            </div>
            <div className="text-right">
              <button
                className="update-btn shadow shadow"
                onClick={updateBookPer}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Editable panel to update runner data
  const getPanelTemplate = () => {
    switch (panelObj.field) {
      case "odds":
        return oddsPanelForm();
      // case "poolLb":
      //   return panelForm({
      //     field: "poolLb",
      //     data: selectedPoolLB,
      //     heading: "Pool Liability",
      //     onChange: (e: any) => setNewPoolLB(e.target.value),
      //     inputValue: newPoolLB,
      //   });
      case "selLb":
        return panelForm({
          field: "selLb",
          data: panelObj.selLb,
          heading: "Selection Liabilities",
          onChange: (e: any) => setNewSelLb(e.target.value),
          inputValue: newSelLb,
        });
      case "maxBetAmounts":
        return panelForm({
          field: "maxBetAmounts",
          data: panelObj.maxBetAmount,
          heading: "Max Bet Amount",
          onChange: (e: any) => setNewMaxBetAmt(e.target.value),
          inputValue: newMaxBetAmt,
        });
      case "layOff":
        return panelForm({
          field: "layOff",
          data: panelObj.layOff,
          heading: "Lay Off",
          onChange: (e: any) => setNewLayoff(e.target.value),
          inputValue: newLayoff,
        });
      case "oddsInv":
        return oddsInvPanelTemplate();
      case "bookPer":
        return bookPerPanelForm();
      default:
        break;
    }
  };

  // Click on selection id cell to fetch odds investment
  const onSelIdClick = (e: any, runner: RaceTripDTO) => {
    if (runner.scratched) return;
    FixedOddsManagementService.getOddsInv(
      selectedPool.poolDetails.poolKeyString,
      runner.raceTripKey.selId
    )
      .then((res) => {
        if (res.data) {
          let oddsInvData = [...res.data] as any;

          oddsInvData.sort((a: any, b: any) =>
            a.lastUpdated > b.lastUpdated ? -1 : 1
          );
          for (let inv of oddsInvData) {
            if (inv.odds) inv.odds = inv.odds.toFixed(2);

            if (inv.investment) inv.investment = inv.investment.toFixed(2);

            if (inv.lastUpdated) {
              let t = format(new Date(inv.lastUpdated), "yyyyMMddHHmmss");
              let tz = getLocalStorage(LOCAL_STORAGE.TIMEZONE);
              if (tz)
                inv.formattedLastUpdated = dateConversionForInvOdds(tz, t);
            }
          }
          let _panel = { ...panelObj };
          _panel.field = "oddsInv";

          setPanelObj(_panel);
          setOddsInv(oddsInvData);
          if (panelRef && panelRef.current) panelRef.current.toggle(e);
        }
      })
      .catch((err) => {
        setOddsInv([]);
        console.log(err);
        showAPIToast(err, "while fetching odds investment.");
      });
    // setSelectedRow(rowData);
  };

  const getReqButton = (pool: PoolDTO) => {
    // debugger;
    if (_user.uid !== pool.poolConfig?.userId) {
      // console.log("CALLED SET  MANUAL ");
      return (
        <div className="d-flex align-items-center">
          <button onClick={() => requestManagePool(pool)} className="btn-req">
            Request to manage
          </button>
        </div>
      );
    } else if (_user.uid === pool.poolConfig?.userId) {
      // console.log("CALLED SET AUTO ");

      return (
        <div className="d-flex align-items-center">
          <button
            onClick={() => requestManagePoolToAuto(pool)}
            className="btn-req-auto"
          >
            Set Auto manage
          </button>
        </div>
      );
    }
    return null;
  };

  const requestManagePool = (pool: PoolDTO) => {
    let user = getLocalStorageObject(LOCAL_STORAGE.USER) as UserDTO;
    if (!user) {
      return showToast("Session not found! Please login again!", TOAST.ERROR);
    }
    ScheduleApiService.managePoolRequest(pool.poolDetails.poolKey)
      .then((res) => {
        console.log("-------------CALLED MANAGE MANUAL");
      })
      .catch((err) => {
        console.log(err);
        showAPIToast(err, "while requesting pool management", "pool_mng");
      });
  };

  const requestManagePoolToAuto = (pool: PoolDTO) => {
    let user = getLocalStorageObject(LOCAL_STORAGE.USER) as UserDTO;
    if (!user) {
      return showToast("Session not found! Please login again!", TOAST.ERROR);
    }

    ScheduleApiService.managePoolRequestToAuto(pool.poolDetails.poolKey)
      .then((res) => {
        if (res && res.data) {
          // debugger;
          let poolObj = res.data;
          poolObj.poolDetails.poolKeyString = getPoolKeyStr(
            res.data.poolDetails.poolKey
          );
          setSelectedPool(poolObj);
        }
      })
      .catch((err) => {
        console.log(err);
        showAPIToast(err, "while requesting pool management Auto", "pool_mng");
      });
  };

  return (
    <div className="table-card">
      {/* PANEL */}
      <OverlayPanel
        ref={panelRef}
        showCloseIcon
        dismissable={false}
        onHide={() => {
          setSelectedProgramNo("");
        }}
        id="overlay_panel"
        style={{ minWidth: "260px" }}
        className="pz-overlaypanel"
      >
        {getPanelTemplate()}
      </OverlayPanel>
      <OverlayPanel
        ref={bookPerRef}
        showCloseIcon
        dismissable={false}
        id="overlay_panel"
        style={{ minWidth: "260px" }}
        className="pz-overlaypanel"
      >
        {bookPerPanelForm()}
      </OverlayPanel>

      <div className="table-card-header">
        <div>
          <h6>
            <label>Managed By : </label>{" "}
            <strong>{selectedPool?.poolConfig?.oddsManagedBy}</strong>
          </h6>
        </div>
        <div style={{ display: "flex" }}>
          {/* <div className="payoff">
            <span>Payout : &nbsp; </span>
            <span></span>
          </div> */}
          <div className="p-1">
            {selectedPool &&
              selectedPool.poolDetails &&
              selectedPool.poolDetails.poolKey.poolType.startsWith("FO_") &&
              selectedPool.poolDetails.poolStatus !== "CLOSED" &&
              selectedPool.poolDetails.poolStatus !== "RESULTED" &&
              selectedRace.raceStatus !== "CLOSED" &&
              selectedRace.raceStatus !== "RESULTED" &&
              !selectedPool.poolDetails.cancelled &&
              (selectedPool.poolDetails.payoff === null ||
                Object.keys(selectedPool.poolDetails.payoff ?? {}).length ===
                  0) &&
              getReqButton(selectedPool)}
          </div>

          <div className="p-1">
            <span
              className={`poolStatus ${selectedPool?.poolDetails?.poolStatus?.toLowerCase()}`}
            >
              {selectedPool?.poolDetails?.poolStatus}
            </span>
          </div>
        </div>
      </div>

      <table className="pz-table">
        <thead>
          <tr>
            {columns.map((col) => {
              return (
                <th
                  key={col.field}
                  style={{ width: col.width ?? "", cursor: "pointer" }}
                  data-toggle="tooltip"
                  data-placement="right"
                  title={col.toolTip}
                >
                  {col.header}
                  {/* <span className="tooltipText">{col.header}</span> */}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {resultedSort(
            Object.values(selectedRaceTrips),
            resultsObj,
            selectedRace
          ).map((runner: RaceTripDTO, index: number) => {
            return (
              <tr className="row-t" key={index}>
                {columns.map((col) => {
                  if (col.field === "selId")
                    return (
                      <td
                        onClick={(e: any) => onSelIdClick(e, runner)}
                        key={col.field}
                        className="sel-id-btn"
                      >
                        {!runner?.scratched && (
                          <span className="ic fa fa-plus-circle pt-1"></span>
                        )}

                        {/* <span className="float-right">
                            {runner.raceTripKey.selId}
                          </span> */}

                        {!runner?.scratched &&
                        selectedRace?.raceStatus === "RESULTED" &&
                        index === _i ? (
                          <span className="winners">
                            {index + 1} <IoMdTrophy size={20} />
                          </span>
                        ) : (
                          ""
                        )}
                      </td>
                    );
                  return (
                    <td
                      key={col.field}
                      className={`${
                        runner?.scratched === true ? "scratched" : ""
                      }  ${
                        selectedRace.raceStatus === "RESULTED" && index === _i
                          ? "winnerRow"
                          : ""
                      }`}
                    >
                      {cellTemplate(runner, col.field, index)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {getNullMessage()}
          {columns.length === 12
            ? layOffFooterRowTemplate()
            : SOFooterRowTemplate()}
        </tbody>
      </table>
    </div>
  );

  function panelForm(props: {
    heading: string;
    data: any;
    onChange: any;
    inputValue: string;
    field: string;
  }) {
    return (
      <div className="pz-card text-light" style={{ position: "relative" }}>
        <div className="selId-abs">{selectedProgramNo}</div>
        <div className="pz-card-header b-bottom pb-3">
          <h3>
            <strong>{props.heading}</strong>
          </h3>
        </div>
        <div className="pz-card-body">
          <div>
            <label htmlFor="">Current {props.heading} : </label>{" "}
            <span>{props.data?.toFixed(2) ?? 0.0}</span>
          </div>
          <div className="py-2">
            <label htmlFor="" className="light-label">
              Enter New {props.heading}
            </label>
            <input
              value={
                props.field === "odds"
                  ? newOdds
                  : props.inputValue
                  ? props.inputValue
                  : ""
              }
              onChange={props.onChange}
              type="number"
              autoFocus
              step={props.field === "odds" ? 0.1 : 1}
              onKeyUp={(e: any) => onKeyUpOfInputs(e, props.field, false)}
              className="panel-input shadow"
            />
          </div>
          <div className="text-right">
            {props.field === "odds" && (
              <button
                className="update-btn shadow"
                onClick={() => updateOdds()}
              >
                Update
              </button>
            )}

            {props.field === "selLb" && (
              <>
                <button
                  className="update-btn shadow danger mx-2"
                  onClick={() => updateSelLB(true)}
                >
                  Deduct
                </button>
                <button
                  className="update-btn shadow"
                  onClick={() => updateSelLB(false)}
                >
                  Add
                </button>
              </>
            )}

            {props.field === "maxBetAmounts" && (
              <>
                <button
                  className="update-btn shadow danger mx-2"
                  onClick={() => updateMaxBetAmount(true)}
                >
                  Deduct
                </button>
                <button
                  className="update-btn shadow"
                  onClick={() => updateMaxBetAmount(false)}
                >
                  Add
                </button>
              </>
            )}

            {props.field === "layOff" && (
              <>
                <button
                  className="update-btn shadow danger mx-2"
                  onClick={() => updateLayoffPercentage(true)}
                >
                  Deduct
                </button>
                <button
                  className="update-btn shadow"
                  onClick={() => updateLayoffPercentage(false)}
                >
                  Add
                </button>
              </>
            )}

            {props.field === "poolLb" && (
              <>
                <button
                  className="update-btn shadow danger mx-2"
                  onClick={() => updatePoolLB(true)}
                >
                  Deduct
                </button>
                <button
                  className="update-btn shadow"
                  onClick={() => updatePoolLB(false)}
                >
                  Add
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default TripsTable;

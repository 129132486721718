/* eslint-disable react-hooks/exhaustive-deps */
//react
import { Tooltip } from "primereact/tooltip";
import { useContext } from "react";
import { useHistory } from "react-router";

//context
import { ScheduleContext } from "../../../Context/scheduleContext";

//helper
import { LOCAL_STORAGE, ROUTES } from "../../../Helpers/constants";
import { PoolDTO } from "../../../Models/PoolsDTO";

//form
import AddNewPoolForm from "./AddNewPoolForm/addNewPoolForm";
import EditPoolForm from "./EditPoolForm/poolEditForm";
import PoolCards from "./PoolsCards/poolsCards";

import "./poolsComponent.scss";
import "./_poolForms.scss";
import { BackDrop } from "../../../UI-Components/BackDrop/backDrop";
interface IPoolComponentProps {
  isFormVisible: boolean;
  connectorInfo: any;
  isPoolsOpen: any;
  setIsPoolsOpen: any;
}
export default function PoolsComponent(props: IPoolComponentProps) {
  const history = useHistory();

  const {
    poolToEdit,
    selectedRace,
    togglePoolForm,
    setPoolToEdit,
    connectorsOptions,
  } = useContext(ScheduleContext);

  const toggleSidebar = (state: boolean) => {
    togglePoolForm(false);
    props.setIsPoolsOpen(state);
  };

  const onAddNewPool = () => {
    togglePoolForm(true);
    setPoolToEdit(null);
  };

  const openRaceInSchedule = () => {
    localStorage.setItem(
      LOCAL_STORAGE.SELECTED_RACE_KEY,
      JSON.stringify(selectedRace.raceKey)
    );
    history.push(ROUTES.FIXED_ODDS_MANAGEMENT);
  };

  const FloatButtonTemplate = () => {
    return (
      <div className="float-btn">
        {selectedRace?.foGenerated && (
          <>
            <Tooltip
              target=".tp"
              content="Open This Race in Fixed Odds Management"
              position="left"
            />
            <button className="tp" onClick={openRaceInSchedule}>
              <i className="fa fa-external-link"></i>
            </button>
          </>
        )}
        {(selectedRace?.raceStatus === "OPEN" ||
          (selectedRace?.raceStatus === "NON_DISPLAY" &&
            !selectedRace.jumpTime)) && (
          <button onClick={onAddNewPool}>
            <i className="fa fa-plus"></i> &nbsp; Add Pool
          </button>
        )}
      </div>
    );
  };

  const onFilterConnectorList = (pool: PoolDTO) => {
    let _connectorsOptions = [];
    let connectors = connectorsOptions;

    for (let connector of connectors) {
      if (pool?.poolDetails?.currency === connector.currency) {
        _connectorsOptions.push(connector);
      }
    }

    return _connectorsOptions;
  };

  return (
    <>
      <div style={{ position: "absolute", right: 0 }}>
        <button
          className="nav-btn"
          style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          onClick={() => toggleSidebar(true)}
        >
          <span>Pools</span>&nbsp;&nbsp;
          <i className="fa fa-bars"></i>
        </button>
      </div>
      {/* <BackDrop open={props.isPoolsOpen} setOpen={props.setIsPoolsOpen} noBackDrop noDismissableMask> */}
      {
        props.isPoolsOpen &&
        <div className="pools-wrapper">
          {props.isFormVisible && (
            <div className="pool-form">
              {!poolToEdit && (
                <AddNewPoolForm onFilterConnectorList={onFilterConnectorList} />
              )}
              {poolToEdit && (
                <EditPoolForm
                  selectedPool={poolToEdit}
                  onFilterConnectorList={onFilterConnectorList}
                  isPoolsOpen={props.isPoolsOpen}
                  setIsPoolsOpen={props.setIsPoolsOpen}
                />
              )}
            </div>
          )}
          <div className="pool-cards">
            <PoolCards
              connectorInfo={props.connectorInfo}
              toggleSidebar={(state: any) => toggleSidebar(state)}
            />
          </div>
          <FloatButtonTemplate />
        </div>
      }
      {/* </BackDrop> */}
    </>
  );
}

import { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import "./ViewCustomerSummary.scss";
import { TabPanel, TabView } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import Header from "../../../../UI-Components/Header/header";
import { CustomerScreenType } from "../../Screens/ActiveCustomer/ActiveCustomer";
import { CUSTOMER_SUMMARY } from "../../../../Helpers/StaticData/customer-data-static";
import { customerSummaryCols } from "../../../../Helpers/columns";
import {
  CustomerSummaryData,
  CustomerSummaryReq,
} from "../../../../Models/CustomerSummaryData";
import { UserApiService } from "../../../../services/userApiService";
import { format } from "date-fns";
import { FORMAT } from "../../../../Helpers/constants";
import { getStringToDate } from "../../../../Helpers/dateTimeHelpers";
import { showToast, TOAST } from "../../../../Helpers/ui-helper";

// custom component
import CustomerSummeryChartData from "../../CustomerSummeryChartData/CustomerSummeryChartData";
import { ActiveCustomerSessionsDTO } from "../../../../Models/activeCustomerSessionsDTO";
import CustomerRules from "../../CustomerRules/CustomerRules";
import BackDrop from "../../../../UI-Components/BackDrop/backDrop";

export interface IViewCustomerSummaryProps {
  changeScreen: (screen: CustomerScreenType) => void;
  selectedCustomer: any;
  updateCustomer?: any;
}

export default function ViewCustomerSummary(props: IViewCustomerSummaryProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [summaryData, setSummaryData] = useState<CustomerSummaryData[]>([]);
  const [selectedTab, setSelectedTab] = useState(CUSTOMER_SUMMARY[0]);
  const [dateRange, setDateRange] = useState<Date | Date[] | undefined>();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [collectData, setCollectData] = useState<any>({});
  const [provinceData, setProvinceData] = useState({});
  const [isCheckRules, setIsCheckRules] = useState(false);
  const [totals, setTotals] = useState({
    bets: 0,
    winningBets: 0,
    betAmount: 0,
    winBetAmount: 0,
  });
  const [betCurrency, setBetCurrency] = useState({} as any);
  const [winCurrency, setWinCurrency] = useState({} as any);
  const [submitted, setSubmitted] = useState(false);
  const [toggleBtn, setToggleBtn] = useState(true);
  const [activeSessions, setActiveSessions] = useState<
    ActiveCustomerSessionsDTO[]
  >([]);
  let customerId = props.selectedCustomer.customerKey;

  let bets: number;
  let winningBets: number;
  let betAmount: number;
  let winBetAmount: number;
  let tsDate: string;
  let teDate: string;

  // callbacks
  useEffect(() => {
    onGetCustomerData(customerId);
    onGetActiveCustomerData(customerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Send Props
  const customerRulesProps = { isCheckRules, setIsCheckRules, customerId };
  const customerSummeryChartDataProps = {
    provinceData,
    isSubmitted,
  };

  // functions
  const onChangeTab = (e: any) => {
    setTotals({ bets: 0, winningBets: 0, betAmount: 0, winBetAmount: 0 });
    setActiveIndex(e.index);
    let selectedTab = CUSTOMER_SUMMARY[e.index];
    setSelectedTab(selectedTab);
    onDateSet();
    getCustomerSummaryData(selectedTab);
  };
  const onDateChange = (e: any) => {
    setDateRange(e.value);
  };
  const onGetCustomerData = (customerId: string) => {
    UserApiService.getCustomerData(customerId)
      .then((res) => {
        setCollectData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onGetActiveCustomerData = (customerId: string) => {
    UserApiService.getActiveCustomerSessions(customerId)
      .then((res) => {
        if (res && res.data) {
          setActiveSessions(res.data);
        }
      })
      .catch((err) => {
        showToast(err?.response?.data?.message, TOAST.ERROR);
      });
  };

  const onDateSet = () => {
    if (dateRange) {
      let tempDate: any = dateRange;
      if (!tempDate[0] || !tempDate[1]) {
        showToast("Please Select Start & End Date", TOAST.ERROR);
        return;
      }
      tsDate = format(tempDate[0], "yyyyMMdd");
      teDate = format(tempDate[1], "yyyyMMdd");
    }
  };
  const onSubmit = () => {
    onDateSet();
    getCustomerSummaryData(selectedTab);
    setIsSubmitted(true);
    let provinceGraphBoard = createCustomerReqDto("PROVINCE");
    setProvinceData(provinceGraphBoard);
    if (dateRange) {
      setSubmitted(true);
    }
  };
  const createCustomerReqDto = (selectedTab: string) => {
    let customerSummaryReq = {} as CustomerSummaryReq;
    customerSummaryReq.startDate = Number(tsDate);
    customerSummaryReq.endDate = Number(teDate);
    customerSummaryReq.customerId = props.selectedCustomer.customerKey;
    customerSummaryReq.summaryType = selectedTab;
    return customerSummaryReq;
  };

  const getCustomerSummaryData = (selectedTab: string) => {
    let customerSummaryReq = createCustomerReqDto(selectedTab);
    UserApiService.getCustomerSummaryData(customerSummaryReq)
      .then((res) => {
        if (res.data) {
          bets = 0;
          winningBets = 0;
          betAmount = 0;
          winBetAmount = 0;
          let customersData: CustomerSummaryData[] = [];
          for (const key of res.data) {
            let obj: any = {};
            if (customerSummaryReq.summaryType === "EVENT") {
              obj["localDate"] = getStringToDate(
                key.csByEventKey.localDate.toString()
              );
              obj["walletId"] = key.csByEventKey.walletId;
              obj["default"] = key.csByEventKey.eventType;
            } else if (customerSummaryReq.summaryType === "POOL") {
              obj["localDate"] = getStringToDate(
                key.csByPoolKey.localDate.toString()
              );
              obj["walletId"] = key.csByPoolKey.walletId;
              obj["default"] = key.csByPoolKey.poolType;
            } else if (customerSummaryReq.summaryType === "PROVINCE") {
              obj["localDate"] = getStringToDate(
                key.csByProvinceKey.localDate.toString()
              );
              obj["walletId"] = key.csByProvinceKey.walletId;
              obj["default"] = key.csByProvinceKey.province;
            } else if (customerSummaryReq.summaryType === "TRACK") {
              obj["localDate"] = getStringToDate(
                key.csByPzoneKey.localDate.toString()
              );
              obj["walletId"] = key.csByPzoneKey.walletId;
              obj["default"] = key.csByPzoneKey.pzoneCode;
            }
            obj["bets"] = key.totalBets;
            obj["winningBets"] = key.totalWinningBets;
            obj["betAmount"] = Number(key.betAmount.toFixed(2));
            obj["winBetAmount"] = Number(key.winAmount.toFixed(2));
            obj["strickRate"] = (
              (key.totalWinningBets / key.totalBets) *
              100
            ).toFixed(2);
            calculateTotals(obj);
            setTotals({
              bets: bets,
              betAmount: betAmount,
              winningBets: winningBets,
              winBetAmount: winBetAmount,
            });
            customersData.push(obj);
          }
          let betTemp = {} as any;
          let winTemp = {} as any;
          for (let { walletId, betAmount, winBetAmount } of customersData) {
            let prev = betTemp[walletId] ?? 0;
            let winPrev = winTemp[walletId] ?? 0;
            betTemp = { ...betTemp, [walletId]: prev + betAmount };
            winTemp = { ...winTemp, [walletId]: winPrev + winBetAmount };
          }
          setBetCurrency(betTemp);

          setWinCurrency(winTemp);
          setSummaryData([...customersData]);
        } else {
          setSummaryData([]);
        }
      })
      .catch((err) => {
        setSummaryData([]);
        console.log(err);
        if (err.response) {
          showToast(err.response["data"].message, TOAST.ERROR);
        } else {
          showToast("Server did not respond! Please try later!", TOAST.ERROR);
        }
      });
  };

  const calculateTotals = (obj: any) => {
    bets += obj.bets;
    betAmount += obj.betAmount;
    winBetAmount += obj.winBetAmount;
    winningBets += obj.winningBets;
  };

  const dateTemplate = (rowData: any) => {
    return format(rowData.localDate, FORMAT.ddMMMyyyy_DASH);
  };
  const handleCheckRules = () => {
    setIsCheckRules(true);
  };

  let footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Totals:"
          colSpan={3}
          footerStyle={{ textAlign: "right" }}
        />
        <Column footer={totals.bets.toFixed(2)} />
        <Column footer={totals.winningBets.toFixed(2)} />
        <Column footer={totals.betAmount.toFixed(2)} />
        <Column footer={totals.winBetAmount.toFixed(2)} />
        <Column />
      </Row>
    </ColumnGroup>
  );
  const header = (
    <div className="d-flex justify-content-end">
      <div className="search-input">
        <input
          placeholder="Global Search"
          type="search"
          value={globalFilter}
          onChange={(e: any) => setGlobalFilter(e.target.value)}
          className="inputWrp"
        />
        <i className="fa fa-search icon"></i>
      </div>
    </div>
  );

  return (
    <div className="view_customer_summary_wrapper">
      {" "}
      <Header pageTitle="Customer Summary" />
      <div className="content heightFull">
        <div className="content-left-panel w-28">
          <div className="content-panel-header head multiple">
            {" "}
            Customer Info{" "}
          </div>
          <div className="cards">
            <div className="card card-1 d-table">
              <div className="card-header">Customer Information</div>
              <div className="card_content">
                <span className="month_bid_info">
                  Customer Key : {props.selectedCustomer.customerKey}
                </span>
                <span className="month_bid_info text-capitalize">
                  Customer Name : {props.selectedCustomer.customerName}
                </span>
                <span className="month_bid_info">
                  Email : {props.selectedCustomer.email}
                </span>
                <span className="month_bid_info">
                  Phone Number : {props.selectedCustomer.phoneNo}
                </span>
              </div>
            </div>
          </div>
          <div className="cards">
            <div className="card card-1 d-table">
              <div className="card-header">
                <span>Active Customer Sessions</span>
                <i
                  className="fa fa-refresh"
                  aria-hidden="true"
                  role="button"
                  onClick={() =>
                    onGetActiveCustomerData(props.selectedCustomer.customerKey)
                  }
                ></i>
              </div>
              {activeSessions.length > 0 ? (
                <TabView className="tab-view-session">
                  {activeSessions.map((data, index) => {
                    return (
                      <TabPanel
                        key={index}
                        header={`Session-${index}`}
                        headerClassName="m-1"
                      >
                        <div className="card_content">
                          <span className="month_bid_info">
                            Browser : {data.browser}
                          </span>
                          <span className="month_bid_info">
                            Country : {data.country}
                          </span>
                          <span className="month_bid_info">
                            CustomerId : {data.customerId}
                          </span>
                          <span className="month_bid_info">Ip : {data.ip}</span>
                          <span className="month_bid_info">
                            Platform : {data.plateform}
                          </span>
                          <span className="month_bid_info">
                            Session Id : {data.sessionId}
                          </span>
                        </div>
                      </TabPanel>
                    );
                  })}
                </TabView>
              ) : (
                <span className="empty-data">No Session Available</span>
              )}
            </div>
          </div>
          <div className="cards">
            <div className="card card-1 d-table">
              <div className="card-header">
                Customer Summery
                {submitted ? (
                  <button
                    className={`overAllBtn ${!toggleBtn ? "active" : ""}`}
                    onClick={() => setToggleBtn(!toggleBtn)}
                  >
                    OverAll
                  </button>
                ) : (
                  ""
                )}
              </div>
              {toggleBtn && submitted ? (
                <div>
                  {Object.keys(summaryData).length > 0 ? (
                    <div className="card_content">
                      <span className="month-bid-info-secondary">
                        Bet Amount : {totals.betAmount?.toFixed(2)}
                        {Object.keys(betCurrency).map((cur, index) => {
                          return (
                            <div className="currencyWrp" key={index}>
                              <span>{cur}</span>
                              &nbsp;:&nbsp;
                              <span>{betCurrency[cur].toFixed(2)}</span>
                            </div>
                          );
                        })}
                      </span>
                      <span className="month-bid-info-secondary">
                        Total Bets : {totals.bets}
                      </span>
                      <span className="month-bid-info-secondary">
                        Total Winning Bets : {totals.winningBets}
                      </span>
                      <span className="month-bid-info-secondary">
                        Win Amount : {totals.winBetAmount?.toFixed(2)}
                        {Object.keys(winCurrency).map((winCur, index) => {
                          return (
                            <div className="currencyWrp" key={index}>
                              <span>{winCur}</span>
                              &nbsp;:&nbsp;
                              <span>{winCurrency[winCur].toFixed(2)}</span>
                            </div>
                          );
                        })}
                      </span>
                    </div>
                  ) : (
                    <span className="empty-data">No Data Available</span>
                  )}
                </div>
              ) : (
                <div>
                  {Object.keys(collectData).length !== 0 ? (
                    <div className="card_content">
                      <span className="month_bid_info">
                        Bet Amount : {collectData.betAmount?.toFixed(2)}
                      </span>
                      <span className="month_bid_info">
                        Total Bets : {collectData.totalBets}
                      </span>
                      <span className="month_bid_info">
                        Total Winning Bets : {collectData.totalWinningBets}
                      </span>
                      <span className="month_bid_info">
                        Win Amount : {collectData.winAmount?.toFixed(2)}
                      </span>
                    </div>
                  ) : (
                    <span
                      className="empty-data"
                      style={{ borderRadius: "0.5rem" }}
                    >
                      No Data Available
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="content-middle-panel w-100">
          <header className=" page-head b-bottom">
            <span className="b-right">
              <button
                onClick={() =>
                  props.changeScreen(CustomerScreenType.SEARCH_CUSTOMER)
                }
                className="pz-btn icon-btn hover"
              >
                <i className="fa fa-arrow-left"></i> Back
              </button>
              &nbsp;&nbsp;&nbsp;
            </span>
            &nbsp;&nbsp;&nbsp;
            <span>Customer Summary Data</span>
          </header>
          <div className="sub_header">
            <div className="pz-card d-flex">
              <Calendar
                id="range"
                value={dateRange}
                onChange={onDateChange}
                selectionMode="range"
                monthNavigator
                yearNavigator
                yearRange="2020:3000"
                placeholder="Select Date Range"
                className="calender-css w-100 calender"
              />
              <div className="btn-container p-3">
                <button
                  className="pz-btn primary rounded text-center rule_btn"
                  onClick={onSubmit}
                >
                  SUBMIT
                </button>
              </div>
            </div>
            <button
              onClick={handleCheckRules}
              className="pz-btn primary text-center rule_btn"
            >
              Check Rules
            </button>
          </div>
          <BackDrop open={isCheckRules} setOpen={setIsCheckRules} noBackDrop>
            <CustomerRules {...customerRulesProps} />
          </BackDrop>
          {isSubmitted && summaryData.length > 0 && (
            <CustomerSummeryChartData {...customerSummeryChartDataProps} />
          )}
          {summaryData.length > 0 && (
            <div className="inline-height p-4">
              <div className="pz-card my-3 mx-0 p-3 data_table_wrapper">
                <TabView
                  className="tab-view"
                  activeIndex={activeIndex}
                  onTabChange={onChangeTab}
                >
                  {CUSTOMER_SUMMARY.map((x) => {
                    return (
                      <TabPanel key={x} header={x} headerClassName="m-1">
                        <DataTable
                          value={summaryData}
                          className="pz-prime-table"
                          footerColumnGroup={footerGroup}
                          globalFilter={globalFilter}
                          header={header}
                        >
                          {customerSummaryCols.map((col, index) => (
                            <Column
                              key={index}
                              field={col.field}
                              header={
                                col.field === "default"
                                  ? selectedTab
                                  : col.header
                              }
                              sortable
                              body={col.field === "localDate" && dateTemplate}
                            />
                          ))}
                        </DataTable>
                      </TabPanel>
                    );
                  })}
                </TabView>
              </div>
            </div>
          )}
          {isSubmitted && summaryData.length === 0 && (
            <div className="errMsg">Data not found!</div>
          )}
          {!isSubmitted && summaryData.length === 0 && (
            <div className="errMsg">Data Not Available ! Select Date Range</div>
          )}
        </div>
      </div>
    </div>
  );
}

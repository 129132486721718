import React, { useState } from "react";
import { format } from "date-fns";
import { FORMAT, ROUTES } from "../../../Helpers/constants";
import {
  CLOG,
  showAPIToast,
  showToast,
  TOAST,
} from "../../../Helpers/ui-helper";
import {
  filterValues,
  getTrackName,
  raceKeySplit,
} from "../../../Helpers/valueHelper";
import DateTimePicker from "../../../UI-Components/Inputs/dateTime";
import { RaceDetailsDTO } from "../../../Models/RaceDetailsDTO";
import "./AddTrackPools.scss";

import { getStringToDate } from "../../../Helpers/dateTimeHelpers";

import { useEffect } from "react";
import { ScheduleApiService } from "../../../services/scheduleApiService";
import AddTrackPoolForm from "./AddTrackPoolForm/addTrackPoolForm";

export interface PropsDetails {
  setisAddPools: any;
}
export default function AddTrackPools(props: PropsDetails) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [trackNameList, setTrackNameList] = useState([]);

  const [trackCountryList, setTrackCountryList] = useState([]);
  const [filteredCountryList, setFilteredCountryList] = useState([]);

  const [trackSplitList, setTrackSpliList] = useState([]);
  const [filteredTrackList, setFilteredTrackList] = useState([]);

  const [isProvinceSelected, setIsProvinceSelected] = useState(false);

  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedTrackName, setSelectedTrackName] = useState("");

  const [raceDetailsList, setRaceDetailsList] = useState([]);
  const [trackwiseRaces, setTrackwiseRaces] = useState([]);

  useEffect(() => {
    getAllRaceData(selectedDate);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeDate = (date: Date) => {
    resetListData();
    if (date) {
      setSelectedDate(date);
      getAllRaceData(date);
    }
  };

  const resetListData = () => {
    setTrackwiseRaces([]);
    setTrackCountryList([]);
    setFilteredCountryList([]);
    setRaceDetailsList([]);
    setSelectedProvince("");
    setSelectedTrackName("");
    setIsProvinceSelected(false);
    setTrackNameList([]);
  };
  const getAllRaceData = (date: Date) => {
    let tDate: any = format(date, FORMAT.yyyyMMdd);
    ScheduleApiService.getScheduleRaces(tDate)
      .then((res) => {
        if (res?.data?.length) {
          let raceDetails = res.data as RaceDetailsDTO[];
          setTrackDetails(raceDetails, date);
          CLOG("=> API___TODAY RACES FETCH___", "purple");
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.response?.data?.statusCode === 401) {
          showToast("Session Expired! Please Login Again!", TOAST.ERROR);
          window.location.href = ROUTES.LOGIN;
          return;
        }
        showAPIToast(err, "while fetching races data", "race_schedular");
      });
  };
  const setTrackDetails = (raceDetails: any, date: any) => {
    let _trackNameList: any = new Set();
    let _trackCountryList: any = new Set();
    for (const race of raceDetails) {
      if (
        race.raceKey.localDate.toString() === format(date, FORMAT.yyyyMMdd) &&
        !race.jumpTime
      ) {
        _trackCountryList.add(raceKeySplit(race.raceKey.pzoneCode, "-", 0));
        _trackNameList.add(race.raceKey.pzoneCode);
      }
    }
    let list: any = Array.from(_trackNameList);
    setTrackNameList(list);
    setRaceDetailsList(raceDetails);
    setTrackCountryList(Array.from(_trackCountryList));
    setFilteredCountryList(Array.from(_trackCountryList));
  };

  const onProvinceSelect = (ctry: string) => {
    let _trackNameList = [...trackNameList];
    let tlist: any = [];
    for (const trackName of _trackNameList) {
      if (ctry === raceKeySplit(trackName, "-", 0)) {
        let obj: any = {};
        obj.trackName = getTrackName(trackName);
        obj.event = raceKeySplit(trackName, "-", 2);
        tlist.push(obj);
      }
    }
    setSelectedProvince(ctry);
    setFilteredTrackList(tlist);
    setTrackSpliList(tlist);
    setIsProvinceSelected(true);
  };

  const onTrackSelect = (track: any) => {
    let _raceDetails: any = [...raceDetailsList];
    let tList: any = [];

    for (const race of _raceDetails) {
      console.log(race.raceKey.localDate);
      if (
        track.trackName === getTrackName(race.raceKey.pzoneCode) &&
        track.event === raceKeySplit(race.raceKey.pzoneCode, "-", 2) &&
        (race.raceStatus === "OPEN" || race.raceStatus === "NON_DISPLAY") &&
        race.raceKey.localDate &&
        race.raceKey.localDate.toString() ===
          format(selectedDate, FORMAT.yyyyMMdd) &&
        race.postTime &&
        !race.jumpTime
      ) {
        let dateFormatee = getStringToDate(race.raceKey.localDate.toString());
        race.filteredLocalDate = format(
          new Date(dateFormatee.toString()),
          "dd-MM-yyyy"
        );
        tList.push(race);
      }
    }
    setSelectedTrackName(track);
    setTrackwiseRaces(tList);
    setRaceDetailsList(_raceDetails);
  };

  const onSearchProvince = (e: any) => {
    let res: any = filterValues(
      e.target.value,
      trackCountryList,
      filteredCountryList,
      ""
    );
    setFilteredCountryList(res);
  };

  const onSearchTrack = (e: any) => {
    let res: any = filterValues(
      e.target.value,
      trackSplitList,
      filteredTrackList,
      "trackName"
    );
    setFilteredTrackList(res);
  };

  return (
    <div>
      <div className="pz-card">
        <div className="pz-card-header">
          <div className="">
            <DateTimePicker
              value={selectedDate}
              onChange={(e: any) => onChangeDate(e.target.value)}
              label="Select Date"
              className="inputDateContain"
            />
          </div>
        </div>
        <div className="pz-card-body">
          <div className="m-auto">
            <div>
              <div className="add-form-content inline-height">
                <div className="ctry-container-pool-add">
                  <div className="search-box">
                    <input
                      onChange={(e) => onSearchProvince(e)}
                      type="search"
                      className="search-ctry"
                      maxLength={2}
                    />
                    <span className="search-icon">
                      <i className="fa fa-search"></i>
                    </span>
                  </div>

                  {filteredCountryList.map((ctry) => {
                    return (
                      <button
                        key={ctry}
                        onClick={() => onProvinceSelect(ctry)}
                        className={
                          selectedProvince === ctry
                            ? "ctry-btn active"
                            : "ctry-btn"
                        }
                      >
                        {ctry}
                      </button>
                    );
                  })}
                </div>
                {isProvinceSelected && (
                  <>
                    <div className="track-filter-pools">
                      <div className="filter-header">
                        <div>Select Tracks</div>
                        <div className="search-input mx-0 mt-2">
                          <input
                            type="text"
                            placeholder="Search Track Here..."
                            onChange={onSearchTrack}
                          />
                          <i className="fa fa-search icon"></i>
                        </div>
                        <div className="track-grid-pools">
                          {filteredTrackList.map((track: any) => {
                            return (
                              <button
                                key={`${track.trackName} - ${track.event}`}
                                onClick={() => onTrackSelect(track)}
                                className={
                                  selectedTrackName === track
                                    ? "track-btn active"
                                    : "track-btn"
                                }
                              >
                                <span>{track.trackName}</span>
                                <span>{track.event}</span>
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="add-pool-card">
                      <div className="pool-card-header">
                        Selected Track Open Races
                      </div>
                      <div className="b-bottom race-card-container">
                        {trackwiseRaces.map((race: any): JSX.Element => {
                          return (
                            <div
                              className="race-card-poolAdd"
                              key={`${race.raceKey.pzoneCode}~${race.raceKey.raceNo}~${race.postTime}`}
                            >
                              <div className="d-row d-flex flex-column">
                                <div className="d-flex justify-content-between">
                                  <span className="trackName p-1">
                                    {getTrackName(race.raceKey.pzoneCode)} -{" "}
                                    {race.raceKey.raceNo}
                                  </span>
                                  <span className="trackName p-1">
                                    {raceKeySplit(
                                      race.raceKey.pzoneCode,
                                      "-",
                                      2
                                    )}
                                  </span>
                                </div>
                                <span className="p-1">
                                  {race.filteredLocalDate}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          background: "#464646",
                        }}
                      >
                        {trackwiseRaces.length === 0 && (
                          <div
                            style={{
                              fontSize: "large",
                              padding: "10px",
                              color: "var(--text-Non-Active)",
                            }}
                          >
                            No open Races Available !
                          </div>
                        )}
                      </div>
                      {trackwiseRaces.length > 0 && (
                        <div>
                          <AddTrackPoolForm
                            trackwiseRaces={trackwiseRaces}
                            selectedDate={selectedDate}
                            selectedTrackName={selectedTrackName}
                            setisAddPools={props.setisAddPools}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import DO from "../assets/images/icons/DO.png";
import TH from "../assets/images/icons/TH.png";
import HS from "../assets/images/icons/HS.png";

const Images: any = {
  DO,
  HS,
  TH,
};
export const getDynamicImage = (image: any) => {
  return Images[image];
};

import { PrimeItem } from "../../Helpers/StaticData/race-data";
import { MultiSelect } from "primereact/multiselect";
export interface IPzMultiSelectProps {
  className?: string;
  placeHolder?: string;
  onChange?: any;
  options: PrimeItem[];
  value: any;
  name?: string;
  label?: string;
  disabled?: boolean;
  emptyMsg?: string;
  style?: any;
  optionDisabled?: any;
}


export default function PzMultiSelect(props: IPzMultiSelectProps) {
  return (
    <div className={`input-container ${props.className}`} style={props.style}>
      <div className="input">
        <MultiSelect
          optionDisabled={props.optionDisabled}
          optionLabel="label"
          optionValue="value"
          className="prime-drop"
          value={props.value}
          options={props.options}
          onChange={(e) => props.onChange(e)}
          placeholder={props.placeHolder}
          name={props.name}
          disabled={props.disabled}
          emptyFilterMessage={props.emptyMsg}
        />

        {props.label && (
          <label className="input-label" htmlFor={props.label}>
            {props.label}
          </label>
        )}
      </div>
    </div>
  );
}

import { Dropdown } from "primereact/dropdown";
import { PrimeItem } from "../../Helpers/StaticData/race-data";

export interface IPzDropDownProps {
  className?: string;
  classNameWrp?:string;
  placeHolder?: string;
  onChange?: any;
  options: PrimeItem[];
  value: any;
  name?: string;
  label?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  emptyMsg?: string;
  filterName?: string;
  filter?: boolean;
  valueTemplate?: any;
  itemTemplate?: any;
  showclear?: boolean;
  inputClass?: string;
}

export default function PzDropDown(props: IPzDropDownProps) {
  return (
    <div className={`input-container ${props.classNameWrp}`}>
      <div className="input">
        <Dropdown
          optionLabel="label"
          optionValue="value"
          className={`prime-drop ${props.className}`}
          value={props.value}
          options={props.options}
          onChange={(e) => props.onChange(e)}
          placeholder={props.placeHolder}
          name={props.name}
          disabled={props.disabled}
          emptyFilterMessage={props.emptyMsg}
          
          autoFocus={props.autoFocus}
          filterBy={props.filterName}
          filter={props.filter}
          valueTemplate={props.valueTemplate}
          itemTemplate={props.itemTemplate}
          showClear={props.showclear}
        />
        {props.label && (
          <label className="input-label" htmlFor={props.label}>
            {props.label}
          </label>
        )}
      </div>
    </div>
  );
}

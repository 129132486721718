import { DateTimeFormatter } from "@js-joda/core";

export const ROUTES = {
  LOGIN: "/",

  DASHBOARD: "/dashboard",

  FIXED_ODDS_MANAGEMENT: "/fixed-odds-management",

  USER_ACCESS: "/user-access-management",

  // LOGS: "/logs/wagers-history",
  SCHEDULE: "/schedule-management",

  TOTE_MANAGEMENT: "/tote-management",

  RULES: "/rules-management",

  CONNECTORS: "/connectors",

  REPORTS: "/reports/wagers-history",
  WAGERS_HISTORY: "/reports/wagers-history",
  CUSTOMER_HISTORY: "/reports/customer-wagers-history",
  WAGERS_SUMMARY: "/reports/wagers-summary",

  LOGS: "/logs/race-logs",
  RACE_LOGS: "/logs/race-logs",
  RACE_TRIP: "/logs/race-trip-logs",
  POOL_LOGS: "/logs/pool-logs",

  CUSTOMER_SUMMARY: "/customer-summary/active-customer",
  ACTIVE_CUSTOMER: "/customer-summary/active-customer",
  SEARCH_CUSTOMER: "/customer-summary/search-customer",
  SEARCH_CUSTOMER_REPORT: "/customer-summary/search-customer-report",
};

export const LOCAL_STORAGE = {
  RM_SEL_EVENTTYPE: "rm_sel_eventtype",
  RM_SEL_RACE: "rm_sel_race",
  SM_RUNNER_FIELDS: "sm_run_fields",
  IS_FIELDS_SHOW: "fields_show",
  USER: "user",
  JWT: "jwtResponse",
  REQ_OBJ: "req_obj",
  TIMEZONE: "tz",
  SELECTED_RACE_KEY: "selectedRaceKey",
};

export const FORMAT = {
  HHmma_COLON: "hh:mm a",
  HHmmss: "HHmmss",
  yyyyMMddHHmmss: "yyyyMMddHHmmss",
  // yyyyMMddHHmmss: "yyyyMMddHHmmss",
  yyyyMMdd: "yyyyMMdd",
  ddMMMyyyy_DASH: "dd-MMM-yyyy",
  ddMMyyyyHHmmss_DASH: "dd-MM-yyyy HH:mm:ss",
  ddMMyyyy: "dd-MM-yyyy",
};

export const DATE_FORMATTER = {
  YYYYMMDDHHMMSS_FORMATTER: DateTimeFormatter.ofPattern(FORMAT.yyyyMMddHHmmss),
  DDMMYYYYHHmmss_FORMATTER: DateTimeFormatter.ofPattern(
    FORMAT.ddMMyyyyHHmmss_DASH
  ),
};

// export const SERVER = {
//   URL_USER_MNG: "https://prodadminapi.goracing.app/",
//   URL_SCHEDULE_MNG: "https://prodadminapi.goracing.app/",
//   URL_RISK: "https://prodadminapi.goracing.app/",
//   URL_RULES: "https://prodadminapi.goracing.app/",
//   WS_URL: "wss://prodadminapi.goracing.app:443/",
//   WS_URL_RACE_POOLS: "wss://prodadminapi.goracing.app:443/",
//   MODULE: {
//     SCHEDULE: "schedule/",
//     USER: "user/",
//     RISK: "risk/",
//     RULES: "rules/",
//     BET: "bet/",
//   },
// };

// export const SERVER = {
//   URL_USER_MNG: "http://192.168.90.213:9750/",
//   URL_SCHEDULE_MNG: "http://192.168.90.213:9750/",
//   URL_RISK: "http://192.168.90.213:9750/",
//   URL_RULES: "http://192.168.90.213:9750/",
//   WS_URL: "ws://192.168.90.213:8880/",
//   WS_URL_RACE_POOLS: "ws://192.168.90.213:8880/",

//   MODULE: {
//     SCHEDULE: "schedule/",
//     USER: "user/",
//     RISK: "risk/",
//     RULES: "rules/",
//     BET: "bet/",
//   },
// };

export const SERVER = {
  URL_USER_MNG: process.env.REACT_APP_API_SERVER,
  URL_SCHEDULE_MNG: process.env.REACT_APP_API_SERVER,
  URL_RISK: process.env.REACT_APP_API_SERVER,
  URL_RULES: process.env.REACT_APP_API_SERVER,
  WS_URL: process.env.REACT_APP_WS_SERVER,
  WS_URL_RACE_POOLS: process.env.REACT_APP_WS_SERVER,

  MODULE: {
    SCHEDULE: "schedule/",
    USER: "user/",
    RISK: "risk/",
    RULES: "rules/",
    BET: "bet/",
  },
};

// User Roles
export const ROLES = {
  ADMIN: "ADMIN",
  CUS_MGT_READ: "CUS_MGT_READ",
  CUS_MGT_MODIFY: "CUS_MGT_MODIFY",
  CUS_MGT_DELETE: "CUS_MGT_DELETE",
  USER_MGT_READ: "USER_MGT_READ",
  USER_MGT_MODIFY: "USER_MGT_MODIFY",
  USER_MGT_DELETE: "USER_MGT_DELETE",
  SCHEDULE_MGT_READ: "SCHEDULE_MGT_READ",
  SCHEDULE_MGT_MODIFY: "SCHEDULE_MGT_MODIFY",
  SCHEDULE_MGT_DELETE: "SCHEDULE_MGT_DELETE",
  FIX_ODDS_MGT_READ: "FIX_ODDS_MGT_READ",
  FIX_ODDS_MGT_MODIFY: "FIX_ODDS_MGT_MODIFY",
  FIX_ODDS_MGT_DELETE: "FIX_ODDS_MGT_DELETE",
  TOTE_MGT_READ: "TOTE_MGT_READ",

  REPORT_READ: "REPORT_READ",

  LOG_READ: "LOG_READ",

  RULE_MGT_READ: "RULE_MGT_READ",
  RULE_MGT_MODIFY: "RULE_MGT_MODIFY",
  RULE_MGT_DELETE: "RULE_MGT_DELETE",

  CONNECTOR_MGT_READ: "CONNECTOR_MGT_READ",
  CONNECTOR_MGT_MODIFY: "CONNECTOR_MGT_MODIFY",
  CONNECTOR_MGT_DELETE: "CONNECTOR_MGT_DELETE",
};

export const POOLS = {
  FIXED_ODDS: "FO_",
};

export const FixOddsRuleList = [
  { label: "MIN", value: "MIN" },
  { label: "TIMED", value: "TIMED" },
  { label: "LIB", value: "LIB" },
  { label: "FIXED", value: "FIXED" },
];

export const MAIValuesList = [
  { label: "AMOUNT", value: "AMOUNT" },
  // { label: "PERCENTAGE", value: "PERCENTAGE" },
];

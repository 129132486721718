import { confirmPopup } from "primereact/confirmpopup";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState, useContext } from "react";

//context
import { ScheduleContext } from "../../../../Context/scheduleContext";

//helper
import {
  CurrencyList,
  InitPool,
  InitPoolConfig,
  InitPoolKey,
  FOPoolsListTrack,
} from "../../../../Helpers/StaticData/pool-data-static";
import { showAPIToast, showToast, TOAST } from "../../../../Helpers/ui-helper";
import { validateMinValue } from "../../../../Helpers/Validators/input-validations";
import { getNumberValue, getTrackName } from "../../../../Helpers/valueHelper";

//service
import { ScheduleApiService } from "../../../../services/scheduleApiService";

//component
import PZInputField from "../../../../UI-Components/Inputs/inputField";
import PzDropDown from "../../../../UI-Components/Inputs/pzDropDown";
import "./addTrackPoolForm.scss";
import { MultiSelect } from "primereact/multiselect";
import { format } from "date-fns";
import { FixOddsRuleList, MAIValuesList } from "../../../../Helpers/constants";
import LargeLoader from "../../../../UI-Components/Loader/loaders";
import { ProviderOptions } from "../../../../Helpers/StaticData/race-data";
import { ScratchProfileDTO } from "../../../../Models/scratchProfileDTO";
import { timeZoneConversion } from "../../../../Helpers/dateTimeHelpers";
import DateTimePicker from "../../../../UI-Components/Inputs/dateTime";

export interface PropsDetails {
  trackwiseRaces: any;
  selectedDate: any;
  selectedTrackName: any;
  setisAddPools: any;
}

enum PoolCategory {
  TOTE,
  FO,
}
export default function AddTrackPoolForm(props: PropsDetails) {
  const { scrProfileList, usersList, connectorsOptions } =
    useContext(ScheduleContext);

  const [pool, setPool] = useState(InitPool);

  const [linkedPoolOptions, setlinkedPoolOptions] = useState([]);
  const [isFOPool, setisFOPool] = useState(false);

  const [poolTypeList, setPoolTypeList] = useState([]);
  const [selectedPoolCategory, setSelectedPoolCategory] = useState(
    PoolCategory.TOTE
  );
  const [openTime, setOpenTime] = useState(new Date());

  const [poolSelected, setPoolSelected] = useState(null);
  const [selectedLinkedWith, setSelectedLinkedWith] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  // const [providers, setProviders] = useState<PrimeItem[]>([]);

  useEffect(() => {
    resetPoolDetails();
    onSetPoolCategory(PoolCategory.TOTE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedTrackName]);

  const resetPoolDetails = () => {
    let _pool = { ...pool };
    _pool.poolDetails.poolKey = { ...InitPoolKey };
    _pool.poolDetails.poolKey.poolType = "";
    _pool.poolDetails.linkedWith = "";
    _pool.poolDetails.minBetAmt = null as any;
    _pool.poolDetails.incBetAmt = null as any;
    _pool.poolDetails.otherLegs = "";
    _pool.poolDetails.currency = "";
    _pool.poolDetails.layOff = false;
    _pool.poolConfig = { ...InitPoolConfig };
    setPool(_pool);
    setlinkedPoolOptions([]);
    setPoolSelected(null);
    setSelectedLinkedWith(null);
  };

  const onSetPoolCategory = (poolCategory: PoolCategory) => {
    let tList: any = [];

    if (poolCategory === PoolCategory.TOTE) {
      tList = FOPoolsListTrack.filter((x) => !x.value.startsWith("FO_"));
    } else if (poolCategory === PoolCategory.FO) {
      tList = FOPoolsListTrack.filter((x) => x.value.startsWith("FO_"));
    }
    setSelectedPoolCategory(poolCategory);
    setPoolTypeList(tList);
    setlinkedPoolOptions([]);
    setPoolSelected(null);
    setSelectedLinkedWith(null);
  };

  const onPoolTypeChange = (e: any) => {
    let races = { ...props.trackwiseRaces } as any;
    let poolCategory = selectedPoolCategory === 0 ? "TOTE" : "FO";
    let _pool = { ...pool };
    _pool.poolDetails.poolKey = { ...InitPoolKey };
    _pool.poolDetails.linkedWith = "";
    _pool.poolDetails.minBetAmt = null as any;
    _pool.poolDetails.incBetAmt = null as any;
    _pool.poolDetails.otherLegs = "";
    _pool.poolDetails.currency = "";
    _pool.poolConfig = { ...InitPoolConfig };

    if (poolCategory === "FO") {
      setisFOPool(true);
      let dateConvertStr = format(new Date(), "yyyyMMddHHmmss");
      let strDateTime = timeZoneConversion(races[0].timezoneId, dateConvertStr);
      setOpenTime(strDateTime);
      _pool.poolConfig.openTime = format(
        new Date(strDateTime),
        "yyyyMMddHHmmss"
      );
    } else {
      _pool.poolDetails.layOff = false;
      setisFOPool(false);
    }
    setPool(_pool);

    let country = races[0].raceKey.pzoneCode.split("-")[0];
    setPoolSelected(e.value);
    _getLinkedWithPools(country, poolCategory);
    setSelectedLinkedWith(null);
  };

  // const onPoolBooleansChange = (e: any) => {
  //   let _pool = { ...pool } as any;
  //   _pool.poolDetails[e.name] = e.value;
  //   setPool(_pool);
  // };

  const _getLinkedWithPools = (country: string, poolCategory: string) => {
    ScheduleApiService.getProviderList(country, poolCategory)
      .then((res) => {
        if (res.data) {
          setlinkedPoolOptions(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        showAPIToast(err, "while fetchin linked with pools!", "linked_with");
      });
  };

  const onLinkedWithPoolChange = (e: any) => {
    let _pool = { ...pool };
    _pool.poolDetails.linkedWith = e.target.value.provider;
    _pool.poolDetails.currency = e.target.value.currency;
    setSelectedLinkedWith(e.target.value);
    setPool(_pool);
  };

  const onPoolKeyDropDownChange = (e: any) => {
    let _pool = { ...pool } as any;
    _pool.poolDetails.poolKey[e.target.name] = e.target.value;
    setPool(_pool);
  };
  const onDropdownChange = (e: any) => {
    let values = { ...pool } as any;
    values.poolDetails[e.target.name] = e.target.value;
    setPool(values);
  };

  const onPoolDetailChange = (e: any) => {
    let values = { ...pool } as any;
    let name = e.target.name;
    if (name === "minBetAmt" || name === "incBetAmt") {
      let valid = validateMinValue(Number(e.target.value), 0);
      if (!valid) return console.error("not valid");
    }
    values.poolDetails[e.target.name] = e.target.value;
    setPool(values);
  };

  const validateEditPool = () => {
    if (!poolSelected) {
      showToast("Please select pool type!", TOAST.ERROR, "poolType");
      return false;
    }
    if (
      !pool.poolDetails.linkedWith &&
      pool.poolConfig?.oddsManagedBy === "AUTO"
    ) {
      showToast("Please select linked with pool.", TOAST.ERROR);
      return false;
    }
    if (!pool.poolDetails.currency) {
      showToast("Please select currency!", TOAST.ERROR, "currency");
      return false;
    }
    if (!pool.poolDetails.minBetAmt || !pool.poolDetails.incBetAmt) {
      showToast("Please select Bet Amount!", TOAST.ERROR, "BetAmount");
      return false;
    }
    if (isFOPool && Number(pool.poolConfig?.minBookPer) < 100) {
      showToast("minBookPer value should be greaterthan 100 !", TOAST.ERROR);
      return false;
    }
    // if (!isFOPool && !pool.poolConfig?.accountId) {
    //   showToast("Please select Connector!", TOAST.ERROR, "Connector");
    //   return false;
    // }
    // if (!pool.poolDetails.poolKey.provider) {
    //   showToast("Please select provider!", TOAST.ERROR, "provider");
    //   return false;
    // }

    return true;
  };

  const confirmNewAdd = (event: any) => {
    if (validateEditPool()) {
      confirmPopup({
        target: event.currentTarget,
        message: "Are you sure you want to proceed to add this pools?",
        icon: "pi pi-exclamation-triangle",
        accept: () => addNewAllPoolData(),
        reject: () => {},
      });
    }
  };
  // const handleLayoutPool = (e: any) => {
  //   let _pool = { ...pool } as any;
  //   const value = e.target.value;
  //   console.log(value);

  //   if ((Number(value) && value <= 0) || value > 100) {
  //     return showToast(
  //       "Layoff Percentage should be between 1 to 100",
  //       TOAST.ERROR
  //     );
  //   }
  //   _pool.poolConfig[e.target.name] = value;
  //   setPool(_pool);
  // };

  const modifyPoolData = () => {
    let _pool = { ...pool } as any;
    delete _pool.poolDetails.payOffList;
    delete _pool.poolDetails.poolKeyString;
    if (!isFOPool) {
      // delete _pool.poolConfig.mbaType;
      delete _pool.poolConfig.defaultMaxBetAmount;
    }
    let reqPoolObj: any = {};
    let temp_trackwiseRaces = [...props.trackwiseRaces] as any;
    let tList = [];
    for (const race of temp_trackwiseRaces) {
      tList.push(race.raceKey);
    }
    // _pool.poolConfig.timeZone = temp_trackwiseRaces[0].timezoneId;
    // reqPoolObj.poolDto.poolConfig.layoffPer = Number(
    //   pool.poolConfig?.layoffPer
    // );
    reqPoolObj.poolDto = _pool;
    reqPoolObj.pools = poolSelected;
    reqPoolObj.raceKeys = tList;
    delete reqPoolObj.poolDto.poolDetails.poolKey;
    delete reqPoolObj.poolDto.poolConfig.poolKey;
    reqPoolObj.poolDto.poolDetails.providerId = pool?.poolDetails?.providerId;
    // reqPoolObj.poolDto.poolConfig.layoffPer = Number(
    //   pool.poolConfig?.layoffPer
    // );
    const scrProfile: ScratchProfileDTO = scrProfileList.find(
      (x) => x.value === _pool.poolConfig.scratchedProfileId
    );
    if (scrProfile) {
      reqPoolObj.poolDto.poolConfig.deductionType = scrProfile.deductionUsing;
      reqPoolObj.poolDto.poolConfig.deductionValue = scrProfile.scratchedDedPer;
    }
    delete reqPoolObj.poolDto.poolConfig.scratchedProfileId;
    if (selectedPoolCategory !== PoolCategory.FO)
      delete reqPoolObj.poolDto.poolConfig;
    return reqPoolObj;
  };

  const addNewAllPoolData = () => {
    setIsLoading(true);
    let poolReq = modifyPoolData();
    ScheduleApiService.getPoolsByTrack(poolReq)
      .then((res) => {
        if (res.data) {
          for (const data of res.data) {
            if (data.trackPoolStatus === "SUCCESS") {
              showToast(
                "In " +
                  onPoolKeySeparate(data.poolKey) +
                  "  Pool Added Successfully",
                TOAST.SUCCESS,
                data.poolKey
              );
            } else if (data.trackPoolStatus === "FAIL") {
              showToast(
                data.message + " In  " + onPoolKeySeparate(data.poolKey),
                TOAST.ERROR,
                data.poolKey
              );
            }
          }
        }
        setIsLoading(false);
        props.setisAddPools(false);
      })
      .catch((err) => {
        console.error(err);
        showAPIToast(err, "while updating pool data", "u_pool");
        setIsLoading(false);
      });
  };

  const onPoolKeySeparate = (poolKey: any): string => {
    let splitStr = poolKey.split("~");
    let _getTrackName = getTrackName(splitStr[1]);
    let str = _getTrackName + " " + splitStr[2] + " " + splitStr[3];
    return str;
  };

  // const getProviders = () => {
  //   // let _obj: any = {};

  //   // _obj["localDate"] = props;
  //   // _obj["pools"] = poolSelected;
  //   // _obj["pzoneCode"] = props.selectedTrackName.pzoneCode;
  //   // _obj["raceNo"] = props.selectedTrackName.raceNo;

  //   // console.log(props);

  //   // FixedOddsManagementService.getProviders(_obj)
  //   //   .then((res) => {
  //   //     let _providers = res.data;
  //   //     console.log(_providers);
  //   //     let _items: PrimeItem[] = [];
  //   //     for (const _pro of _providers) {
  //   //       _items.push({ label: _pro, value: _pro });
  //   //     }
  //   //     setProviders(_items);
  //   //   })
  //   //   .catch((error) => {
  //   //     console.log(error);
  //   //   });
  // };

  const onConfigDropChange = (e: any) => {
    if (e.target.value === null) {
      return;
    }
    let _pool = { ...pool } as any;
    if (!_pool.poolConfig) {
      _pool.poolConfig = InitPoolConfig;
    }
    if (e.target.name === "fixOddsRule") {
      // if (_pool.poolConfig.fixOddsRule === "FIXED") getProviders();
      _pool.poolConfig["fixOddsValue"] = 0;
    }

    if (e.target.name === "providerId") {
      _pool.poolDetails[e.target.name] = e.target.value;
    } else {
      _pool.poolConfig[e.target.name] = e.target.value;
    }
    // _pool.poolConfig[e.target.name] = e.target.value;
    if (e.target.name === "openTime") {
      setOpenTime(e.target.value);
      let dateConvertStr = format(e.target.value, "yyyyMMddHHmmss");
      _pool.poolConfig[e.target.name] = dateConvertStr;
    }
    setPool(_pool);
  };

  const connectorTemplate = () => {
    let _pool = { ...pool };
    if (!poolSelected) return null;
    if (isFOPool && !_pool.poolDetails.layOff) return null;
    let _connectorsOption = [...connectorsOptions];
    let connList = [];
    for (const connector of _connectorsOption) {
      if (_pool?.poolDetails?.currency === connector.currency) {
        connList.push(connector);
      }
    }
    _connectorsOption = connList;
    return (
      <>
        {/* <PzDropDown
          label="Connector"
          value={pool.poolConfig?.accountId ?? ""}
          options={_connectorsOption ?? []}
          classNameWrp="py-4"
          emptyMsg="No connector account info available!"
          name="accountId"
          onChange={onConfigDropChange}
        /> */}
        {/* {pool.poolDetails?.layOff && (
          <PZInputField
            className="py-4"
            label="Layoff Per"
            value={getNumberValue(pool.poolConfig?.layoffPer ?? 0)}
            onChange={handleLayoutPool}
            type="number"
            name="layoffPer"
            min="0"
            max="100"
          />
        )} */}
      </>
    );
  };
  const FOInputTemplate = () => {
    if (isFOPool)
      return (
        <>
          {/* <PzDropDown
            label="Odds Manage By"
            value={pool.poolConfig?.oddsManagedBy}
            options={ManagedByList}
            className="py-4"
            name="oddsManagedBy"
            onChange={onConfigDropChange}
          /> */}

          <PzDropDown
            label="Scratch Profile ID"
            value={pool.poolConfig?.scratchedProfileId}
            options={scrProfileList}
            classNameWrp="py-4"
            name="scratchedProfileId"
            onChange={onConfigDropChange}
          />
          <PZInputField
            className="py-4"
            onChange={onConfigDropChange}
            type="number"
            name="minBookPer"
            value={getNumberValue(pool?.poolConfig?.minBookPer ?? 0)}
            label="minBookPer"
          />
          <PzDropDown
            label="Max Bet Amount Type"
            value={pool?.poolConfig?.mbaType}
            options={MAIValuesList}
            classNameWrp="py-4"
            name="mbaType"
            onChange={onConfigDropChange}
          />
          <PZInputField
            className="py-4"
            onChange={onConfigDropChange}
            type="number"
            name="defaultMaxBetAmount"
            value={getNumberValue(pool?.poolConfig?.defaultMaxBetAmount ?? 0)}
            label="Max Bet Amount Value"
          />
          <PzDropDown
            label="FixOdds Rule"
            value={pool?.poolConfig?.fixOddsRule}
            options={FixOddsRuleList}
            classNameWrp="py-4"
            name="fixOddsRule"
            onChange={onConfigDropChange}
          />
          {pool.poolConfig?.fixOddsRule === "LIB" && (
            <PZInputField
              className="py-4"
              onChange={onConfigDropChange}
              type="number"
              name="fixOddsValue"
              value={getNumberValue(pool?.poolConfig?.fixOddsValue ?? 0)}
              label={"Liability Value (%)"}
            />
          )}
          {pool?.poolConfig?.fixOddsRule === "FIXED" && (
            <PzDropDown
              label="Providers"
              value={pool.poolConfig.fixOddsValue}
              options={ProviderOptions ?? []}
              classNameWrp="py-4"
              name="fixOddsValue"
              onChange={onConfigDropChange}
            />
          )}

          <div className="input-container py-4">
            <div className="input">
              <label className="input-label">Open Time : -</label>
              <DateTimePicker
                value={openTime}
                onChange={onConfigDropChange}
                name="openTime"
                id="time24"
                showTime
                showSeconds
                className="dateTimeWrp"
              />
            </div>
          </div>
        </>
      );
  };

  const manualManageTemplate = () => {
    if (pool.poolConfig?.oddsManagedBy === "MANUAL")
      return (
        <>
          <PzDropDown
            label="Managing User"
            placeHolder="Select user to manage"
            value={pool.poolConfig?.userId ?? ""}
            options={usersList}
            classNameWrp="py-4"
            name="userId"
            onChange={onConfigDropChange}
          />
        </>
      );
  };

  return (
    <div className="form-body">
      <LargeLoader isLoading={isLoading} />
      <div>
        <span className="b-right">
          <button
            className="pz-btn icon-btn hover"
            style={{ background: "gray" }}
            onClick={() => props.setisAddPools(false)}
          >
            <i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp;
            <span className=""> back</span>
          </button>
        </span>
      </div>
      <div className="row m-0">
        <div className="col-6 b-right m-0">
          <div className="col-title">Enter Pool Details</div>
          <div className="pooltype">
            <button
              className={
                selectedPoolCategory === PoolCategory.TOTE
                  ? "active-selectedPoolCategory"
                  : "selectedPoolCategory"
              }
              onClick={() => onSetPoolCategory(PoolCategory.TOTE)}
            >
              TOTE
            </button>
            <button
              className={
                selectedPoolCategory === PoolCategory.FO
                  ? "active-selectedPoolCategory"
                  : "selectedPoolCategory"
              }
              onClick={() => onSetPoolCategory(PoolCategory.FO)}
            >
              FO
            </button>
          </div>

          <div className="input-grid-2 p-2">
            <div
              className="input-container"
              style={{
                width: "auto",
                display: "inline",
                alignSelf: "center",
              }}
            >
              <div className="input">
                <label className="input-label">Pool Type</label>
                <MultiSelect
                  value={poolSelected}
                  options={poolTypeList}
                  onChange={onPoolTypeChange}
                  optionLabel="label"
                  className="prime-drop"
                />
              </div>
            </div>
            <div
              className="input-container"
              style={{
                width: "auto",
                display: "inline",
                alignSelf: "center",
              }}
            >
              <div className="input">
                <label className="input-label">Linked With</label>
                <Dropdown
                  options={linkedPoolOptions}
                  onChange={onLinkedWithPoolChange}
                  className="prime-drop"
                  optionLabel="provider"
                  value={selectedLinkedWith ?? ""}
                />
              </div>
            </div>
            <PZInputField
              className="py-4"
              onChange={onPoolKeyDropDownChange}
              type="string"
              name="minBetAmt"
              value="DEFAULT"
              label="Provider"
              disabled
            />

            <PzDropDown
              label="Currency"
              value={pool?.poolDetails?.currency ?? ""}
              options={CurrencyList}
              classNameWrp="py-4"
              name="currency"
              onChange={onDropdownChange}
              disabled
            />
            <PZInputField
              className="py-4"
              onChange={onPoolDetailChange}
              type="number"
              name="minBetAmt"
              value={getNumberValue(pool?.poolDetails?.minBetAmt ?? 0)}
              label="Min Bet Amount"
            />
            <PZInputField
              className="py-4"
              onChange={onPoolDetailChange}
              type="number"
              name="incBetAmt"
              value={getNumberValue(pool?.poolDetails?.incBetAmt ?? 0)}
              label="Inc. Bet Amount"
            />
            {/* <PZInputField
              className="py-4"
              onChange={onPoolDetailChange}
              type="text"
              name="otherLegs"
              value={pool.poolDetails?.otherLegs ?? ""}
              label="Other Legs"
            /> */}
          </div>

          <div className="d-flex pl-3">
            {/* {isFOPool && (
              <PzCheckBox
                value={pool.poolDetails?.layOff}
                onChange={onPoolBooleansChange}
                label="Layoff ?"
                name="layOff"
              />
            )} */}
          </div>
        </div>
        <div className="col-6 m-0">
          <div className="input-grid-2 p-2" style={{ marginTop: "5rem" }}>
            {connectorTemplate()}
            {FOInputTemplate()}
            {manualManageTemplate()}
          </div>
        </div>
        <div className="form-footer">
          <button
            onClick={confirmNewAdd}
            className="pz-btn primary rounded my-4"
          >
            <i className="fa fa-check"></i> &nbsp; Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export const betHistorycolumns = [
  { field: "timestamp", header: "Time Id", width: "12%" },
  { field: "clientId", header: "Customer Id", width: "12%" },
  { field: "transactionId", header: "Transaction Id", width: "12%" },
  { field: "raceKey", header: "Race Key", width: "15%" },
  { field: "poolType", header: "Pool", width: "7%" },
  { field: "eventType", header: "Type", width: "7%" },
  // { field: "provider", header: "Provider", width: "1.2%" },
  // { field: "isResulted", header: "Resulted", width: "1.2%" },
  { field: "betStatus", header: "Bet Status", width: "7%" },
  { field: "totalRefunds", header: "Refund", width: "7%" },
  { field: "pnl", header: "PNL", width: "7%" },
  { field: "totalWinnings", header: "Return", width: "7%" },
  { field: "totalInvested", header: "Invested", width: "7%" },
];
export const wagersCSV_Headers = [
  { label: "Time ID", key: "timestamp" },
  { label: "Customer ID", key: "clientId" },
  { label: "TRANSACTION ID", key: "transactionId" },
  { label: "RACE KEY", key: "raceKey" },
  { label: "POOL", key: "poolType" },
  { label: "TYPE", key: "eventType" },
  { label: "RESULTED", key: "isResulted" },
  { label: "BET STATUS", key: "betStatus" },
  { label: "REFUND", key: "totalInvested" },
  { label: "PNL", key: "pnl" },
  { label: "RETURN", key: "totalWinnings" },
  { label: "INVESTED", key: "totalInvested" },
  { label: "SELECTION", key: "selection" },
  { label: "SELECTION ODDS", key: "selectionsOdds" },
  { label: "PAYOUT SELECTION", key: "payoutSelection" },
  { label: "PAYOUT SELECTION ODDS", key: "payoutOdds" },
];
export const WagersSummary_Header = [
  { label: "RACE NO", key: "raceNo" },
  { label: "DATE/TIME", key: "localDate" },
  { label: "PZONE-CODE", key: "pzoneCode" },
  { label: "POOL TYPE", key: "poolType" },
  { label: "BLOCKED", key: "blocked" },
  { label: "RESULT PROCESSED", key: "resultProcessed" },
  { label: "PAYOFF PROCESSED", key: "payoffProcessed" },
  { label: "TOTAL lAYOFF", key: "totalLayoff" },
  { label: "TOTAL INVESTMENT", key: "totalInvestment" },
  { label: "TOTAL LAYOFF INV", key: "totalLayoffInv" },
  { label: "TOTAL PAYOFF", key: "totalPayoff" },

];
export const customerCSV_Headers = [
  { label: "Time ID", key: "timestamp" },
  { label: "Customer ID", key: "clientId" },
  { label: "TRANSACTION ID", key: "transactionId" },
  { label: "RACE KEY", key: "raceKey" },
  { label: "POOL", key: "poolType" },
  { label: "TYPE", key: "eventType" },
  { label: "RESULTED", key: "isResulted" },
  { label: "BET STATUS", key: "betStatus" },
  { label: "REFUND", key: "totalRefunds" },
  { label: "PNL", key: "pnl" },
  { label: "RETURN", key: "totalWinnings" },
  { label: "INVESTED", key: "totalInvested" },
  { label: "SELECTION", key: "selection" },
  { label: "SELECTION ODDS", key: "selectionsOdds" },
  { label: "PAYOUT SELECTION", key: "payoutSelection" },
  { label: "PAYOUT SELECTION ODDS", key: "payoutOdds" },
];

export const clientColumns = [
  { field: "timestamp", header: "Time Id", width: "1.5%" },
  { field: "transactionId", header: "Transaction Id", width: "1%" },
  { field: "raceKey", header: "Race Key", width: "2.5%" },
  { field: "poolType", header: "Pool", width: "1%" },
  { field: "eventType", header: "Type", width: "1%" },
  // { field: "provider", header: "Provider", width: "1.2%" },
  // { field: "isResulted", header: "Resulted", width: "1%" },
  { field: "betStatus", header: "Bet Status", width: "1.2%" },
  { field: "pnl", header: "PNL", width: "1%" },
  { field: "totalInvested", header: "Invested", width: "1%" },
  { field: "totalRefunds", header: "Refund", width: "0.9%" },
  { field: "totalWinnings", header: "Winnings", width: "0.9%" },
  { field: "poolCurrency", header: "Currency", width: "0.9%" },
  { field: "totalWinnings", header: "Return", width: "0.9%" },
];
export const investDataColumns = [
  { field: "selection", header: "Selection", width: "2%" },
  { field: "amount", header: "Amount", width: "2%" },
  { field: "ticket", header: "Ticket", width: "3%" },
];
export const payoutColumns = [
  { field: "selection", header: "Selection", width: "2%" },
  { field: "odds", header: "Odds", width: "2%" },
];
export const walletDataList = [
  { label: "AUD", value: "AUD" },
  { label: "USD", value: "USD" },
  { label: "GPB", value: "GPB" },
  { label: "EUR", value: "EUR" },
  { label: "HKD", value: "HKD" },
];
export const raceList = [
  { field: "timeId", header: "Time Id" },
  { field: "action", header: "Action" },
  { field: "localDate", header: "Local Date" },
  { field: "TrackCode", header: "Track Code" },
  { field: "RaceNo", header: "Race No" },
];
export const raceTripList = [
  { field: "timeId", header: "Time Id" },
  { field: "action", header: "Action" },
  { field: "localDate", header: "Local Date" },
  { field: "TrackCode", header: "Track Code" },
  { field: "RaceNo", header: "Race No" },
  { field: "selId", header: "Sel Id" },
  { field: "Scratched", header: "Scratched" },
];
export const poolList = [
  { field: "timeId", header: "Time Id" },
  { field: "action", header: "Action" },
  { field: "localDate", header: "Local Date" },
  { field: "TrackCode", header: "Track Code" },
  { field: "RaceNo", header: "Race No" },
  { field: "poolType", header: "Pool Type" },
  { field: "provider", header: "Provider" },
];
export const toteSummaryDataList = [
  { field: "localDate", header: "Local Date" },
  { field: "pzoneCode", header: "Track Code" },
  { field: "raceNo", header: "Race No" },
  { field: "poolType", header: "Pool Type" },
  { field: "totalInvestment", header: "Total InvestMent" },
  { field: "totalLayoff", header: "Total Layoff" },
  { field: "totalPayoff", header: "Total Payoff" },
  { field: "totalLayoffInv", header: "Total layoff Investment" },
  { field: "payoffProcessed", header: "Payoff Processed" },
  { field: "resultProcessed", header: "Resulted Processed" },
  { field: "blocked", header: "Payoff isBlocked" },
];

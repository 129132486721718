import React from "react";
import FieldLogs from "../FieldLogs/FieldLogs";
import Header from "../../../../UI-Components/Header/header";
import InsideTransactionHeader from "../InsideTransactionHeader";

const RaceLogs = () => {
  return (
    <>
      <Header pageTitle="Logs Management" />
      <div className="content heightFull cont">
        <div className="content-left-panel" style={{ width: "25rem" }}>
          <InsideTransactionHeader selectionType="raceLogs" />
        </div>
        <FieldLogs logType="RACE" />
      </div>
    </>
  );
};

export default RaceLogs;

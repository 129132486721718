import React from "react";
import { CRUD } from "../Helpers/StaticData/race-data";
import { UserDetailsDTO } from "../Models/UserManagementDto";

export interface UserContextDTO {
    usersList: UserDetailsDTO[],
    isLoading: boolean;
    startLoader: () => void;
    stopLoader: () => void;
    updateUserList: (user: UserDetailsDTO, mode: CRUD) => void
}

export const UserContext = React.createContext<UserContextDTO>({} as UserContextDTO);

export const UserContextProvider = UserContext.Provider;

export const UserContextConsumer = UserContext.Consumer;
export default function GenericNotFound(props: { msg: string }) {
  return (
    <>
      <div className="content" style={{ height: "calc(100vh - 4vh)" }}>
        <div
          className="content-middle-panel w-100"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1>{props.msg ? props.msg : "404 Not Found !"}</h1>
          {/* <button onClick={() => history.push("/")} className="btn btn-primary">
            GO TO HOME
          </button> */}
        </div>
      </div>
    </>
  );
}

export const ReportFieldList = [
  { label: "totalRaces", value: "totalRaces" },
  { label: "totalBets", value: "totalBets" },
  { label: "totalWinBets", value: "totalWinBets" },
  { label: "avgNpr", value: "avgNpr" },
  { label: "totalInvested", value: "totalInvested" },
  { label: "totalWinnings", value: "totalWinnings" },
  { label: "pnl", value: "pnl" },
  { label: "investmentPerRace", value: "investmentPerRace" },
  { label: "strickRate", value: "strickRate" },
  { label: "roi", value: "roi" },
];

export const Functions = [
  { name: "#EQ", value: "#EQ" },
  { name: "#GT", value: "#GT" },
  { name: "#GTE", value: "#GTE" },
  { name: "#LT", value: "#LT" },
  { name: "#LTE", value: "#LTE" },
  { name: "##AND", value: "##AND" },
  { name: "##OR", value: "##OR" },
];

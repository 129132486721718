import { RunnerFieldDTO } from "../../../Helpers/StaticData/race-data";
import PzCheckBox from "../../../UI-Components/Inputs/checkBoxInput";

export default function RunnerFieldCheckboxes(
  isShowFields: boolean,
  runnerFields:RunnerFieldDTO,

  onCheckBoxChange: (e: any) => void
) {
  if (isShowFields)
    return (
      <div
        className={
          isShowFields
            ? "boxes-container b-bottom"
            : "boxes-container hide-boxes"
        }
      >
      {Object.values(runnerFields ?? []).map((runner: any) => {
          if (runner.key && runner.key !== "providerId")
            return (
              <PzCheckBox
                key={runner.key}
                label={runner.label}
                value={runner.isTrue}
                name={runner.key}
                onChange={onCheckBoxChange}
                disabled={runner.key === "programNo" ? true : false}
              />
            );
          return null;
        })}
      </div>
    );
  return null;
}
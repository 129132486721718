import { useContext } from "react";
import { ChangeEvent, useState } from "react";

//context
import { ScheduleContext } from "../../../../Context/scheduleContext";

//@ts-ignore
import ReactCountryFlag from "react-country-flag";

//service
import { ScheduleApiService } from "../../../../services/scheduleApiService";
import { showAPIToast, showToast, TOAST } from "../../../../Helpers/ui-helper";
import { filterValues, raceKeySplit } from "../../../../Helpers/valueHelper";

//dateTime
import { format } from "date-fns";
import DateTimePicker from "../../../../UI-Components/Inputs/dateTime";

//helpers
import { FORMAT } from "../../../../Helpers/constants";

//models
import { TrackInfoDTO } from "../../../../Models/trackInfoDTO";
import { RaceDetailsDTO } from "../../../../Models/RaceDetailsDTO";

export interface ITrackDetailsStepProps {
  onStepChange: (index: number) => void;

  newRaceDetails: RaceDetailsDTO;
  setNewRaceDetails: (raceDetails: RaceDetailsDTO) => void;

  selectedTrack: TrackInfoDTO;
  setSelectedTrack: (track: TrackInfoDTO) => void

  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
}

export default function TrackDetailsStep(props: ITrackDetailsStepProps) {
  const {provinces} = useContext(ScheduleContext);

  const [tracks, setTracks] = useState<TrackInfoDTO[]>([]);
  const [filteredTracks, setFilteredTracks] = useState<TrackInfoDTO[]>([]);

  const onCountrySelect = (province: string) => {

    ScheduleApiService.getProvinceTrack(province)
      .then((res) => {
        if (res.data) {
          let tracks = res.data as TrackInfoDTO[];
          for (let track of tracks) {
            track.province = raceKeySplit(track.pzoneCode, "-", 0);
            track.eventType = raceKeySplit(track.pzoneCode, "-", 2);
          }
          setTracks(tracks);
          setFilteredTracks(tracks);

          let raceDetails = { ...props.newRaceDetails };
          raceDetails.country = province;
          raceDetails.timezoneId = tracks[0].timezone;
          raceDetails.raceKey.pzoneCode=tracks[0].pzoneCode;
          raceDetails.eventType=tracks[0].eventType;
          raceDetails.trackName=tracks[0].trackName;
          props.setSelectedTrack(tracks[0]);

          props.setNewRaceDetails(raceDetails);


        }
      })
      .catch((err) => {
        console.log(err);
        showAPIToast(err, "while fetching tracks", "tracks");
      });
  };

  const onTrackSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (tracks && filteredTracks) {
      let searchedTracks = filterValues(
        e.target.value,
        tracks,
        filteredTracks,
        "trackName"
      );
      setFilteredTracks(searchedTracks);
    }
  };

  const onTrackChange = (track: TrackInfoDTO) => {
    props.setSelectedTrack(track);
    let race = { ...props.newRaceDetails };
    race.timezoneId = track.timezone;
    race.raceKey.pzoneCode=track.pzoneCode;
    race.trackName=track.trackName;
    race.eventType=track.eventType;
    props.setNewRaceDetails(race);
  }


  const onNext = () => {
    if (!props.selectedTrack?.pzoneCode) {
      showToast("Please select a Track!", TOAST.ERROR);
      return;
    }
    props.onStepChange(2);
  };

  const onDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    let selectedDate: any = e.target.value;
    let race = { ...props.newRaceDetails };
    props.setSelectedDate(selectedDate);
    race.raceKey.localDate = Number(format(selectedDate, FORMAT.yyyyMMdd));
    race.formattedDate = format(selectedDate, FORMAT.ddMMMyyyy_DASH);
    props.setNewRaceDetails(race);
  };

  return (
    <div className="pz-card w-75 m-auto">
      <div className="pz-card-header">Select Track From Below...</div>

      <div className="pz-card-body d-flex p-3">
        <div className="row m-auto w-100">
          <div className="col-6">
            <div>
              <DateTimePicker
                value={props.selectedDate}
                onChange={onDateChange}
                label="Local Date"
                minDate={new Date()}
              />
            </div>
            <div className="selector country">
              <div className="search-container">
                <label htmlFor="">Select Country From Below</label>
              </div>

              <div className="country-grid">
                {provinces?.map((ctry) => {
                  return (
                    <button
                      onClick={() => onCountrySelect(ctry)}
                      key={ctry}
                      className={
                        props.selectedTrack.province === ctry
                          ? "country-card active"
                          : "country-card"
                      }
                    >
                      <ReactCountryFlag
                        style={{
                          fontSize: "1.2em",
                        }}
                        svg
                        aria-label={ctry}
                        className="emojiFlag"
                        countryCode={ctry}
                        title={ctry}
                      />
                      &nbsp;&nbsp;&nbsp;{ctry}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-6 d-flex">
            <div className="selector tracks w-100">
              <div className="search-container">
                <label htmlFor="">Select Track Name</label>
                <input
                  onChange={onTrackSearch}
                  type="search"
                  placeholder="Search track name here..."
                  name=""
                  id=""
                />
              </div>

              <div className="track-grid">
                {filteredTracks?.map((track) => {
                  return (
                    <button
                      onClick={() => onTrackChange(track)}
                      className={
                        props.selectedTrack?.pzoneCode === track.pzoneCode
                          ? "active"
                          : ""
                      }
                      key={track.pzoneCode}
                    >
                      <span>{track.trackName}</span>
                      <span>{track.eventType}</span>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pz-card-footer text-right pr-4">
        <button onClick={onNext} className="pz-btn primary">
          Next &nbsp;&nbsp;&nbsp;<i className="fa fa-chevron-right"></i>
        </button>
      </div>
    </div>
  );
}

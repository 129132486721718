import { useContext } from "react";
//context
import {
  ScheduleContext,
} from "../../../../Context/scheduleContext";


//service
import { ScheduleApiService } from "../../../../services/scheduleApiService";

//helpers
import PzTable from "../../../../UI-Components/Table/pzTable";
import { getDynamicImage } from "../../../../Helpers/imagesImport";
import {
  detailTemplate,
  showToast,
  TOAST,
} from "../../../../Helpers/ui-helper";

//models
import { RaceDetailsDTO } from "../../../../Models/RaceDetailsDTO";
import { TrackInfoDTO } from "../../../../Models/trackInfoDTO";
import { RaceTripDTO } from "../../../../Models/raceTripsDTO";

export interface IVerificationStepProps {
  toggleAddRace: (state: any) => void;
  resetStates: () => void;

  newRaceDetails: RaceDetailsDTO;
  setNewRaceDetails: (raceDetails: RaceDetailsDTO) => void;

  raceTrips: RaceTripDTO[];
  setRaceTrips: (raceTrips: RaceTripDTO[]) => void;

  selectedTrack: TrackInfoDTO;
  setSelectedTrack: (track: TrackInfoDTO) => void
}

export default function VerificationStep(props: IVerificationStepProps) {
  const sContext = useContext(ScheduleContext);

  const onCancel = () => {
    props.resetStates();
  };



  const onConfim = () => {
    let race = { ...props.newRaceDetails } as any;

    delete race["country"];
    delete race["raceKeyString"];
    delete race["eventType"];
    delete race["formattedDate"];
    delete race["formattedPosttime"];
    delete race["localPostTime"];
    if (race.jumpTime === "") race.jumpTime = null;
    ScheduleApiService.addRaceDetails(race)
      .then((res) => {
        if (res.data) {
          showToast(
            `Race Details added successfully for [${res.data}]`,
            TOAST.SUCCESS
          );
          saveRaceTrips();
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.response?.data?.message) {
          showToast(err.response.data.message, TOAST.ERROR, "race-err");
        }
      });
  };
  const saveRaceTrips = () => {
    let trips = [...props.raceTrips] as any;


    if (trips.length) {
      for (let trip of trips) {
        delete trip.isEditing;
        delete trip.raceTripKeyStr;
      }
      ScheduleApiService.saveAllRaceTrips(trips)
        .then((res) => {
          if (res.data) {
            showToast(
              "Race Scheduled Successfully!",
              TOAST.SUCCESS,
              "raceTrips"
            );
            props.resetStates();
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.response) {
            showToast(err.response.data.message, TOAST.ERROR, "trip-err");
          }
        });
    }
  };
  return (
    <div className="pz-card m-auto">
      <div className="pz-card-header">
        Verify below details entered by you and click on confirm to proceed...
      </div>

      <div className="pz-card-body d-flex">
        <div className="row w-100 m-0 p-0">
          <div className="col-3 p-0 b-right">
            <div className="p-2 b-bottom text-muted">
              <b>Race Details</b>
            </div>

            <div className="detail-card-header">
              <div className="event-icon">
                <img
                  src={`${getDynamicImage(props.newRaceDetails.eventType)}`}
                  alt={props.newRaceDetails.eventType}
                />
                <label className="m-0 pt-2">
                  {props.newRaceDetails.eventType}
                </label>
              </div>
              <div className="track-detail p-2">
                <div className="d-flex flex-column justify-content-between">
                  <span className="track-name">
                    {props.newRaceDetails.trackName}
                  </span>
                  <span>{props.newRaceDetails.formattedDate}</span>
                </div>
                <div className="">
                  <span className="race-no">
                    {props.newRaceDetails.raceKey.raceNo}
                  </span>
                  {/* <span>{sContext.newRaceDetails.timezoneId}</span> */}
                </div>
              </div>
            </div>
            <div>
              {detailTemplate([
                {
                  label: "Country",
                  data: props.newRaceDetails.country,
                },

                {
                  label: "Event Type",
                  data: props.newRaceDetails.eventType,
                },
                {
                  label: "Track Name",
                  data: props.newRaceDetails.trackName,
                },
                {
                  label: "Time Zone",
                  data: props.newRaceDetails.timezoneId,
                },
                {
                  label: "Race Date",
                  data: props.newRaceDetails.formattedDate,
                },
                {
                  label: "Post Time",
                  data: props.newRaceDetails.formattedPosttime,
                },
                {
                  label: "Race Type",
                  data: props.newRaceDetails.raceType,
                },
                {
                  label: "Race Status",
                  data: props.newRaceDetails.raceStatus,
                },
                {
                  label: "Distance",
                  data: props.newRaceDetails.distance,
                },
                {
                  label: "Temprature",
                  data: props.newRaceDetails.raceTemp,
                },
                {
                  label: "Course Type",
                  data: props.newRaceDetails.courseType,
                },
                {
                  label: "Surface Type",
                  data: props.newRaceDetails.surfaceType,
                },
                {
                  label: "Track Condition",
                  data: props.newRaceDetails.trackCondition,
                },
                {
                  label: "Track Variant",
                  data: props.newRaceDetails.trackVariant,
                },
                {
                  label: "Weather",
                  data: props.newRaceDetails.weather,
                },
                {
                  label: "Wind Direction",
                  data: props.newRaceDetails.windDirection,
                },
                {
                  label: "Wind Speed",
                  data: props.newRaceDetails.windSpeed,
                },
              ])}
            </div>
          </div>
          <div className="col-9 p-0 d-flex flex-column">
            <div className="p-2 b-bottom text-muted">
              <b>Runner Details</b>
            </div>
            <div className="p-2 d-flex flex-column justify-content-between h-100">
              <PzTable
                columns={sContext.runnerFieldCols}
                data={props.raceTrips}
              />
              <div className="ml-auto">
                <button onClick={onCancel} className="pz-btn primary mt-3">
                  Cancel
                </button>
                <button onClick={onConfim} className="pz-btn primary mt-3 ml-2">
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useEffect, useRef, useState } from "react";
import { ToteContextDTO, ToteContextProvider } from "../../Context/toteContext";
import { LOCAL_STORAGE, SERVER } from "../../Helpers/constants";
import { CLOG, showAPIToast } from "../../Helpers/ui-helper";
import {
  getLocalStorageObject,
  getPoolKeyStr,
  getRaceTripKeyStr,
} from "../../Helpers/valueHelper";
import { __SelToRunner } from "../../Models/dynamicTypes";
import { PoolDTO, PoolKeyDTO } from "../../Models/PoolsDTO";
import { RaceDetailsDTO } from "../../Models/RaceDetailsDTO";
import { RaceTripDTO } from "../../Models/raceTripsDTO";
import { ToteTableDTO } from "../../Models/RiskTableDTO";
import { JWTResponseDTO } from "../../Models/UserManagementDto";
import { FixedOddsManagementService } from "../../services/fixedOddsManagementService";
import { ScheduleApiService } from "../../services/scheduleApiService";
import { ToteApiService } from "../../services/toteApiService";
import Header from "../../UI-Components/Header/header";
import LargeLoader from "../../UI-Components/Loader/loaders";
import DisplayTrackRaces from "../Components/DisplayTrackRaces/displayTrackRaces";
import RaceSchedular from "../Components/RaceSchedularComponent/raceSchedular";
import SelectedRaceComponent from "../Components/SelectedRace/selectedRaceComponent";
import ToteDataTable from "./ToteDataTable/ToteDataTable";
import ToteExotics from "./TotePoolTab/toteExPoolTab";
import TotePoolTab from "./TotePoolTab/totePoolTab";

export default function ToteManagement() {
  const [isLoading, setIsLoading] = useState(false);
  const [toteContext] = useState({} as ToteContextDTO);
  const [selectedPool, setSelectedPool] = useState("");
  const [selectedRaceTrips, setSelectedRaceTrips] = useState<__SelToRunner>({});
  const [selectedRace, setSelectedRace] = useState<any>({});
  const [selectedTrack, setSelectedTrack] = useState<any>();
  const [toteDataTable, setToteDataTable] = useState({} as any);
  const [exoticTableData, setExoticTableData] = useState({} as any);
  const [waitingToReconnect, setWaitingToReconnect] = useState<Boolean>(true);
  const [raceConnectorInfo, setRaceConnectorInfo] = useState<any>({});

  const wsToteRacePools = useRef<any>(null);
  const toteDataTableRef = useRef<any>();
  const exoticDataTableRef = useRef<any>();
  const selectedRaceRef = useRef<RaceDetailsDTO>(selectedRace);
  const selectedRaceTripRef = useRef<__SelToRunner>(selectedRaceTrips);

  useEffect(() => {
    if (waitingToReconnect) return;

    if (!wsToteRacePools.current) {
      let jwtData: JWTResponseDTO = getLocalStorageObject(LOCAL_STORAGE.JWT);
      if (!jwtData) {
        return;
      }

      let socketUrl =
        SERVER.WS_URL_RACE_POOLS +
        "toteRacePools?raceKey=" +
        selectedRaceRef.current.raceKeyString +
        "&token=" +
        jwtData.token;

      const client = new WebSocket(socketUrl);
      wsToteRacePools.current = client;
      wsToteRacePools.current.onopen = () => {
        CLOG(
          `___WSO____TOTE__RACE_POOLS SOCKET OPEN FOR ${selectedRaceRef.current.raceKeyString}`,
          "green"
        );
        setIsLoading(false);
        // getRacePools(selectedRaceRef.current?./*  */raceKeyString);
      };

      wsToteRacePools.current.onmessage = (message: any) => {
        if (message && message.data) {
          let data = JSON.parse(message.data);
          if (data.msg.poolKey) {
            normalUpdates(data);
          }
        }

        function normalUpdates(data: any) {
          if (data.raceKey === selectedRaceRef.current?.raceKeyString) {
            switch (data.messageType) {
              case "TOTE_POOL":
                updateToteTableData(data.msg);
                break;
            }
          }
        }
      };
      wsToteRacePools.current.onclose = () => {
        if (wsToteRacePools.current) {
          // Connection failed
          // setIsLoading(true);
          CLOG(`___WSO____TOTE__RACE_POOLS SOCKET CLOSED BY SERVER`, "red");
        } else {
          setIsLoading(false);
          // Cleanup initiated from app side, can return here, to not attempt a reconnect
          CLOG(
            `___WSO____TOTE__RACE_POOLS SOCKET CLOSED BY APP COMPONENT`,
            "red"
          );
          return;
        }

        if (waitingToReconnect) {
          return;
        }

        // Setting this will trigger a re-run of the effect,
        // cleaning up the current websocket, but not setting
        // up a new one right away
        setWaitingToReconnect(true);

        // This will trigger another re-run, and because it is false,
        // the socket will be set up again
        setTimeout(() => setWaitingToReconnect(false), 500);
      };
      return () => {
        CLOG(
          `___WSO____TOTE__RACE_POOLS SOCKET CLEAN UP FOR ${selectedRaceRef.current.raceKeyString} `,
          "gray"
        );
        // Dereference, so it will set up next time
        wsToteRacePools.current = null;

        client.close();
      };
    }
  }, [waitingToReconnect]);

  const updatePoolDataFromSocket = (pool: PoolDTO) => {
    if (!pool.poolDetails.poolKey.poolType.startsWith("FO_")) {
      if (
        pool.poolDetails.poolKey.poolType === "WIN" ||
        pool.poolDetails.poolKey.poolType === "PLACE" ||
        pool.poolDetails.poolKey.poolType === "SHOW"
      ) {
        let toteObj = {} as any;
        if (toteDataTableRef.current) {
          toteObj = { ...toteDataTableRef.current };
        }
        if (!toteObj[pool.poolDetails.poolKey.poolType]) {
          let toteTableDto = setInitialToteTableDto(pool.poolDetails.poolKey);
          toteObj[pool.poolDetails.poolKey.poolType] = toteTableDto;
        }
        toteObj[pool.poolDetails.poolKey.poolType].poolStatus =
          pool.poolDetails.poolStatus;
        setPoolData(toteObj);
        setToteDataTable({ ...toteObj });
        toteDataTableRef.current = toteObj;
      } else {
        let exoticObj = {} as any;
        if (exoticDataTableRef.current) {
          exoticObj = { ...exoticDataTableRef.current };
        }
        if (!exoticObj[pool.poolDetails.poolKey.poolType]) {
          let toteTableDto = setInitialToteTableDto(pool.poolDetails.poolKey);
          exoticObj[pool.poolDetails.poolKey.poolType] = toteTableDto;
        }
        exoticObj[pool.poolDetails.poolKey.poolType].poolStatus =
          pool.poolDetails.poolStatus;
        setExoticTableData({ ...exoticObj });
        exoticDataTableRef.current = exoticObj;
      }
    }
  };

  const setPoolData = (poolObj: any) => {
    if (poolObj["WIN"]) {
      setSelectedPool("WIN");
    } else if (poolObj["PLACE"]) {
      setSelectedPool("PLACE");
    } else if (poolObj["SHOW"]) {
      setSelectedPool("SHOW");
    }
  };

  const setInitialToteTableDto = (poolKey: PoolKeyDTO) => {
    let toteTableDto = {} as ToteTableDTO;
    toteTableDto.poolKey = poolKey;
    toteTableDto.poolTotal = 0;
    toteTableDto.totalLayoff = 0;
    toteTableDto.investment = {};
    toteTableDto.totalPayoff = 0;
    return toteTableDto;
  };

  const updateRaceTripsFromSocket = (trip: RaceTripDTO) => {
    if (selectedRaceTripRef.current) {
      trip.raceTripKeyStr = getRaceTripKeyStr(trip.raceTripKey);
      let _selectedTrips: __SelToRunner = { ...selectedRaceTripRef.current };
      _selectedTrips[trip.raceTripKeyStr] = trip;
      setSelectedRaceTrips(_selectedTrips);
      selectedRaceTripRef.current = _selectedTrips;
    }
  };

  const onRaceClick = (race: RaceDetailsDTO) => {
    if (selectedRace?.raceKeyString === race.raceKeyString) return;
    if (!race?.raceKeyString) return;
    setExoticTableData([]);

    toteDataTableRef.current = null;
    exoticDataTableRef.current = null;
    selectedRaceRef.current = race;
    setSelectedRace(race);
    setWaitingToReconnect(false);

    if (wsToteRacePools.current) {
      wsToteRacePools.current.close();
    }
    setIsLoading(true);
    getRaceTrips(race);
    getRacePools(race);
    getToteBucketSummary(race);
  };

  const updateToteTableData = (pool: any) => {
    if (!pool.poolKey.poolType.startsWith("FO_")) {
      if (
        pool.poolKey.poolType === "WIN" ||
        pool.poolKey.poolType === "PLACE" ||
        pool.poolKey.poolType === "SHOW"
      ) {
        let totePools = {} as any;
        if (toteDataTableRef.current) {
          totePools = { ...toteDataTableRef.current };
        }
        pool["poolStatus"] = totePools[pool.poolKey.poolType]?.poolStatus
          ? totePools[pool.poolKey.poolType].poolStatus
          : "";
        totePools[pool.poolKey.poolType] = pool;
        console.log({ totePools });

        setToteDataTable(totePools);
        toteDataTableRef.current = totePools;
      } else {
        let exoticPools = {} as any;
        if (exoticDataTableRef.current) {
          exoticPools = { ...exoticDataTableRef.current };
        }
        pool["poolStatus"] = exoticPools[pool.poolKey.poolType]?.poolStatus
          ? exoticPools[pool.poolKey.poolType].poolStatus
          : "";
        exoticPools[pool.poolKey.poolType] = pool;
        setExoticTableData(exoticPools);
        exoticDataTableRef.current = exoticPools;
      }
    }
  };

  const getToteBucketSummary = (race: RaceDetailsDTO) => {
    // setIsLoading(true);
    ToteApiService.getToteBucketSummary(race.raceKeyString)
      .then((res) => {
        console.log(res.data, "<<<<<<<<<M pppp");
        if (res && res.data.length > 0) {
          let pools = res.data;
          for (const pool of pools) {
            updateToteTableData(pool);
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        showAPIToast(
          err,
          "while fetching Tote Bucket Summary ...!",
          "toteBucketSummary"
        );
        setIsLoading(false);
      });
  };

  const getRaceTrips = (race: RaceDetailsDTO) => {
    setIsLoading(true);
    ScheduleApiService.getRaceTrips(race.raceKeyString)
      .then((res) => {
        let tripObj: __SelToRunner = {};
        if (res.data) {
          let trips = res.data as RaceTripDTO[];
          CLOG("=> API____RACE TRIPS FETCHED____", "purple");

          for (let trip of trips) {
            trip.raceTripKeyStr = getRaceTripKeyStr(trip.raceTripKey);
            tripObj[trip.raceTripKeyStr] = trip;
          }
        }
        // setIsLoading(false);
        selectedRaceTripRef.current = tripObj;
        setSelectedRaceTrips(tripObj);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
        setSelectedRaceTrips({});
        selectedRaceTripRef.current = {};
        showAPIToast(err, "while fetching runner detail", "tripsFetch");
      });
  };

  const getRacePools = (race: RaceDetailsDTO) => {
    setIsLoading(true);
    if (race.raceKeyString) {
      FixedOddsManagementService.getRacePools(race.raceKeyString)
        .then((res) => {
          if (res && res.data) {
            console.log("Response, ", res.data);

            CLOG("__API__GET_POOLS", "#45cd54");
            let poolsData = res.data as PoolDTO[];
            for (let pool of poolsData) {
              if (!pool.poolDetails.poolKey.poolType.startsWith("FO_")) {
                pool.poolDetails.poolKeyString = getPoolKeyStr(
                  pool.poolDetails.poolKey
                );
                updatePoolDataFromSocket(pool);
              }
            }
            if (!exoticDataTableRef.current) {
              setExoticTableData({});
            }
            if (!toteDataTableRef.current) {
              setToteDataTable({});
            }
          }
          // setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(err);
          showAPIToast(err, "while fetching race pools data", "racePools");
        });
    }
  };

  return (
    <ToteContextProvider
      value={{
        ...toteContext,
        selectedRaceTrips,
        selectedPool,
        poolTableData: toteDataTable,
      }}
    >
      <div>
        <Header pageTitle="Tote Management" />
        <LargeLoader isLoading={isLoading} />
        <div className="content heightFull">
          <div className="content-left-panel">
            <RaceSchedular
              updateRaceDetails={setSelectedRace}
              updatePoolData={updatePoolDataFromSocket}
              updateRaceTrips={updateRaceTripsFromSocket}
              onRaceClick={onRaceClick}
              selectedRace={selectedRace}
              selectedTrack={selectedTrack}
              setSelectedTrack={setSelectedTrack}
              // setIsSelectedTrackwisePools={setIsSelectedTrackwisePools}
              raceConnectorInfo={raceConnectorInfo}
              setRaceConnectorInfo={setRaceConnectorInfo}
            />
          </div>
          <div className="content-middle-panel w-100">
            <div className="selected-event-container p-1">
              <DisplayTrackRaces
                onRaceClick={onRaceClick}
                raceConnectorInfo={raceConnectorInfo}
                selectedRaces={selectedTrack?.races ?? []}
                selectedRace={selectedRace}
              />
            </div>
            <SelectedRaceComponent race={selectedRace} />
            {toteDataTable && selectedPool && toteDataTable[selectedPool] && (
              <>
                <div className="p-3">
                  <TotePoolTab
                    toteDataTable={toteDataTable}
                    setSelectedPool={setSelectedPool}
                    selectedPoolType={selectedPool}
                  />
                </div>
                <ToteDataTable />
              </>
            )}
            {Object.keys(exoticTableData).length !== 0 && (
              <div className="p-2">
                <ToteExotics exoticTableData={exoticTableData} />
              </div>
            )}
            {Object.keys(toteDataTable).length === 0 &&
              Object.keys(exoticTableData).length === 0 && (
                <div className="pz-card p-3 h-50 d-flex justify-content-center align-items-center">
                  <h4 style={{ color: "GrayText" }}>
                    Tote Pools Not Created ...!
                  </h4>
                </div>
              )}
          </div>
        </div>
      </div>
    </ToteContextProvider>
  );
}

export interface IPzCheckBoxProps {
  className?: string;
  value: boolean;
  label?: string;
  name?: string;
  onChange: any;
  disabled?: boolean;
}

export default function PzCheckBox(props: IPzCheckBoxProps) {
  if (!props.disabled)
    return (
      <div className={`checkbox-container ${props.className}`}>
        <button
          onClick={() =>
            props.onChange({ name: props.name, value: !props.value })
          }
          className={props.value ? "checkbox selected" : "checkbox"}
        >
          {props.value && <i className="fa fa-check"></i>}
        </button>
        <span
          className="label"
          onClick={() =>
            props.onChange({ name: props.name, value: !props.value })
          }
        >
          {props.label && props.label}
        </span>
      </div>
    );
  return null;
}

import React, {    ReactNode, useState } from "react";
import { PrimeItem } from "../Helpers/StaticData/race-data";

export interface SCRContextDTO {
  countryList: any;
  eventTypeList: PrimeItem[];
  poolList: PrimeItem[];
  searchCustomerReportList: [];
  setCountryList: (country_list: any) => void;
  setSearchCustomerReportList: (data: any) => void;
  ruleId: { country: string; eventType: string; poolType: string };
  setRuleId: (_ruleId: any) => void;
}

export const SCRContext = React.createContext<SCRContextDTO>(
  {} as SCRContextDTO
);

const SCRProvider = ({ children }: {children: ReactNode}) => {
  const [countryList, setCountryList] = useState<any[]>([]);
  const [poolList] = useState([] as PrimeItem[]);
  const eventTypeList : PrimeItem[] = [];
  const [searchCustomerReportList, setSearchCustomerReportList] = useState<any>(
    []
  );
  const [ruleId, setRuleId] = useState({
    country: "",
    eventType: "",
    poolType: "",
  });
  return (
    <SCRContext.Provider
      value={{
        countryList,
        setCountryList,
        eventTypeList,
        poolList,
        searchCustomerReportList,
        setSearchCustomerReportList,
        ruleId,
        setRuleId,
      }}
    >
      {children}
    </SCRContext.Provider>
  );
};
export default SCRProvider;
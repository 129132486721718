import axios from "axios";
import { SERVER } from "../Helpers/constants";
import { getHeaders } from "../Helpers/valueHelper";
import {
  N_PoolRuleDTO,
  PoolRuleDTO,
  PoolRuleKeyDTO,
} from "../Models/poolRuleDTO";
import { ScratchProfileDTO } from "../Models/scratchProfileDTO";
import { CustomerTypeDTO } from "../Models/CustomerTypeDTO";
import { OddsProfileDTO } from "../Models/OddsProfileDTO";
import { CustomerRuleKey, CustomerRule } from "../Models/customerRulesDto";

export const RulesService = {
  getScrProfiles() {
    let url = SERVER.URL_RULES + SERVER.MODULE.RULES + "get-scratched-profiles";
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  addScratchedProfile(profile: ScratchProfileDTO) {
    let url = SERVER.URL_RULES + SERVER.MODULE.RULES + "add-scratched-profile";
    let headers = getHeaders();
    return axios.post(url, profile, { headers: headers });
  },
  deleteScrProfile(profileId: string) {
    let url =
      SERVER.URL_RULES +
      SERVER.MODULE.RULES +
      "delete-scratched-profile/" +
      profileId;

    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  // addPoolRules(poolRule: PoolRuleDTO) {
  //   let url = SERVER.URL_RULES + SERVER.MODULE.RULES + "add-pool-rules";
  //   let headers = getHeaders();
  //   return axios.post(url, poolRule, { headers: headers });
  // },

  addPoolRules(poolRule: N_PoolRuleDTO) {
    let url = SERVER.URL_RULES + SERVER.MODULE.RULES + "add-pool-rules";
    let headers = getHeaders();
    return axios.post(url, poolRule, { headers: headers });
  },
  getPoolRules() {
    let url = SERVER.URL_RULES + SERVER.MODULE.RULES + "pool-rules";
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  deletePoolRule(ruleKey: PoolRuleKeyDTO) {
    let url = SERVER.URL_RULES + SERVER.MODULE.RULES + "delete-pool-rules";
    let headers = getHeaders();
    return axios.post(url, ruleKey, { headers: headers });
  },
  updatePoolRule(poolRule: PoolRuleDTO) {
    let url = SERVER.URL_RULES + SERVER.MODULE.RULES + "update-pool-rules";
    let headers = getHeaders();
    return axios.post(url, poolRule, { headers: headers });
  },
  // addOddsRule(oddsRule: any) {
  //   let url = SERVER.URL_RULES + SERVER.MODULE.RULES + "odds-profile";
  //   let headers = getHeaders();
  //   return axios.post(url, oddsRule, { headers: headers });
  // },
  // getAllOddsProfile() {
  //   let url = SERVER.URL_RULES + SERVER.MODULE.RULES + "odds-profile";
  //   let headers = getHeaders();
  //   return axios.get(url, { headers: headers });
  // },
  // deleteOddsProfileById(profileId: string) {
  //   let url =
  //     SERVER.URL_RULES + SERVER.MODULE.RULES + "odds-profile/" + profileId;
  //   let headers = getHeaders();
  //   return axios.delete(url, { headers: headers });
  // },
  // updateOddsProfile(profileId: string, requestBody: any) {
  //   let url =
  //     SERVER.URL_RULES + SERVER.MODULE.RULES + "odds-profile/" + profileId;
  //   let headers = getHeaders();
  //   return axios.post(url, requestBody, { headers: headers });
  // },
  getOddsProfiles() {
    let url = SERVER.URL_RULES + SERVER.MODULE.RULES + "";
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },

  getVerifyUrl(requestBody: any) {
    let url = SERVER.URL_RULES + SERVER.MODULE.RULES + "validate-url";
    let headers = getHeaders();
    return axios.post(url, requestBody, { headers: headers });
  },

  checkBetfairLoginStatus(requestBody: any) {
    let url = SERVER.URL_RULES + SERVER.MODULE.RULES + "check-login-status";
    let headers = getHeaders();
    return axios.post(url, requestBody, { headers: headers });
  },

  checkBetfairReLoginStatus(accountId: string) {
    let url =
      SERVER.URL_RULES + SERVER.MODULE.RULES + "relogin-connector/" + accountId;
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  checkAccountIdAlreadyAddedOrNot(accountId: string) {
    let url =
      SERVER.URL_RULES + SERVER.MODULE.RULES + "check-accountId/" + accountId;
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },

  getAddConnectors(bodyReq: any) {
    let url = SERVER.URL_RULES + SERVER.MODULE.RULES + "add-connector";
    let headers = getHeaders();
    return axios.post(url, bodyReq, { headers: headers });
  },

  getConnectors() {
    let url = SERVER.URL_RULES + SERVER.MODULE.RULES + "connectors";
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },

  deleteConnector(connectorId: any) {
    let url =
      SERVER.URL_RULES +
      SERVER.MODULE.RULES +
      "delete-connector/" +
      connectorId;
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  createCustomerType(data: CustomerTypeDTO) {
    let url = SERVER.URL_RULES + SERVER.MODULE.RULES + "add-customer-type";
    let headers = getHeaders();
    return axios.post(url, data, { headers: headers });
  },
  getCustomerType() {
    let url = SERVER.URL_RULES + SERVER.MODULE.RULES + "get-customer-type";
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  updateCustomerType(data: CustomerTypeDTO) {
    let url = SERVER.URL_RULES + SERVER.MODULE.RULES + "update-customer-type";
    let headers = getHeaders();
    return axios.post(url, data, { headers: headers });
  },
  createOddsProfile(data: OddsProfileDTO) {
    let url =
      SERVER.URL_RULES + SERVER.MODULE.RULES + "add-odds-change-profile";
    let headers = getHeaders();
    return axios.post(url, data, { headers: headers });
  },
  getOddsProfile() {
    let url =
      SERVER.URL_RULES + SERVER.MODULE.RULES + "get-odds-change-profiles";
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  updateOddsProfile(data: OddsProfileDTO) {
    let url =
      SERVER.URL_RULES + SERVER.MODULE.RULES + "update-odds-change-profile";
    let headers = getHeaders();
    return axios.post(url, data, { headers: headers });
  },
  addCustomerRule(customerRule: CustomerRule) {
    let url = SERVER.URL_RULES + SERVER.MODULE.RULES + "add-customer-rule";
    let headers = getHeaders();
    return axios.post(url, customerRule, { headers: headers });
  },
  getCustomerRules() {
    let url = SERVER.URL_RULES + SERVER.MODULE.RULES + "get-customer-rules";
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
  updateCustomerRule(customerRule: CustomerRule) {
    let url = SERVER.URL_RULES + SERVER.MODULE.RULES + "update-customer-rule";
    let headers = getHeaders();
    return axios.post(url, customerRule, { headers: headers });
  },
  updateCustomerRules(customerRules: any) {
    let url = SERVER.URL_RULES + SERVER.MODULE.RULES + "update-customer-rules";
    let headers = getHeaders();
    return axios.post(url, customerRules, { headers: headers });
  },
  deleteCustomerRule(customerKey: CustomerRuleKey) {
    let url =
      SERVER.URL_RULES +
      SERVER.MODULE.RULES +
      "delete-customer-rule/" +
      customerKey.ruleId +
      "/" +
      customerKey.ruleName;
    let headers = getHeaders();
    return axios.get(url, { headers: headers });
  },
};

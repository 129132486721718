import { format } from "date-fns";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/components/column/Column";
import { DataTable } from "primereact/datatable";
import { useState, useRef } from "react";
import {
  betHistorycolumns,
  payoutColumns,
  investDataColumns,
  wagersCSV_Headers,
} from "../../../../Helpers/StaticData/transactionData";
import { showToast, TOAST } from "../../../../Helpers/ui-helper";
import { UserApiService } from "../../../../services/userApiService";
import Header from "../../../../UI-Components/Header/header";
import InsideTransactionHeader from "../InsideTransactionHeader";
import { MultiSelect } from "primereact/multiselect";
import {
  BetHistoryDetails,
  investMentData,
  payOutData,
} from "../../../../Models/TransactionDto";
import "./WagerHistory.scss";
import { Row } from "react-bootstrap";
import { ColumnGroup } from "primereact/columngroup";
import { Dialog } from "primereact/dialog";
import { dateTimeConvert } from "../../../../Helpers/dateTimeHelpers";
import { numberFormate } from "../../../../Helpers/valueHelper";
import { CSVLink } from "react-csv";
import { useEffect } from "react";
import LargeLoader from "../../../../UI-Components/Loader/loaders";
import { options } from "../../../../Helpers/StaticData/pool-data-static";
import PzDropDown from "../../../../UI-Components/Inputs/pzDropDown";

export default function WagerData() {
  // const [date, setDate] = useState<Date | undefined>();
  const [betHistoryData, setBetHistoryData] = useState<BetHistoryDetails[]>([]);
  const [filteredData, setFilteredData] = useState<BetHistoryDetails[]>([]);
  const [selectedProvince, setSelectedProvince] = useState<string[]>([]);
  const [selectedPool, setSelectedPool] = useState<string[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<string[]>([]);
  const [provinceList, setProvinceList] = useState<any[]>([]);
  const [poolList, setPoolList] = useState<any[]>([]);
  const [eventTypeList, setEventTypeList] = useState<any[]>([]);
  const [displayInvest, setDisplayInvest] = useState(false);
  const [displayPayout, setDisplayPayout] = useState(false);
  const [successSelection, setSuccessSelection] = useState<number>();
  const [invData, setInvData] = useState<investMentData[]>([]);
  const [payOutData, setPayOutData] = useState<payOutData[]>([]);
  const [isDataNotAvailable, setisDataNotAvailable] = useState(false);
  const [isMainData, setIsMainData] = useState(false);
  const [loading, setloading] = useState(false);
  const [exportCSV_Data, setExportCSV_Data] = useState<any[]>([]);
  const [isResponseDataNotAvailable, setisResponseDataNotAvailable] =
    useState(false);

  const [dateRange, setDateRange] = useState<Date[]>([]);

  const [totals, setTotals] = useState({
    pnlTotal: 0,
    refundTotal: 0,
    investedTotal: 0,
  });
  const [poolType, setPoolType] = useState<string>("");

  let dt = useRef(null);

  useEffect(() => {
    onExport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betHistoryData]);

  const investmentDataColumns = investDataColumns.map((col, i) => {
    return <Column key={col.field} field={col.field} header={col.header} />;
  });

  const payOutColumns = payoutColumns.map((col, i) => {
    return <Column key={col.field} field={col.field} header={col.header} />;
  });

  const onChangeDate = (e: any) => {
    if (!e.value) {
      showToast("Select Valid DateRange", TOAST.ERROR);
      return;
    }
    console.log(e.value);
    setDateRange(e.value);
  };

  let tsdate: string;
  let tedate: string;
  let sDateYear: number;
  let eDateYear: number;

  const onDateSet = () => {
    let tempDate: any = dateRange;
    if (tempDate[1] !== null && tempDate[0]) {
      sDateYear = tempDate[0].getFullYear();
      eDateYear = tempDate[1].getFullYear();
    }
    if (tempDate[0] && tempDate[1] === null) {
      sDateYear = tempDate[0].getFullYear();
      eDateYear = tempDate[0].getFullYear();
      tempDate[1] = tempDate[0];
    }
    if (sDateYear !== eDateYear) {
      console.log("selected Date Is Not okay");
      showToast("select same year date range", TOAST.ERROR);
      return;
    }

    tsdate = format(tempDate[0], "yyyyMMdd");
    tedate = format(tempDate[1], "yyyyMMdd");
  };

  const getHistoryData = () => {
    onDateSet();

    let tempDate: any = {
      startDate: Number(tsdate),
      endDate: Number(tedate),
      poolType: poolType,
    };

    UserApiService.wagersHistoryData(tempDate)
      .then((res) => {
        if (res.data) {
          onSetDisplayData(res.data);
          setIsMainData(true);
        }
        if (res.data.length === 0) {
          setIsMainData(false);
          setisResponseDataNotAvailable(true);
        }
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        setisResponseDataNotAvailable(true);
        if (err.response) showToast(err.response.data.message, TOAST.ERROR);
        else
          showToast("Server did not respond! Please try later!", TOAST.ERROR);
        setloading(false);
      });
  };

  const onSubmit = () => {
    if (!poolType) {
      showToast("Select Bet Option", TOAST.ERROR);
      return;
    }
    if (!dateRange) {
      showToast("Select Date range", TOAST.ERROR);
      return;
    }
    setloading(true);
    setSelectedProvince([]);
    setSelectedPool([]);
    setSelectedEvent([]);
    getHistoryData();
    setisDataNotAvailable(false);
    setisResponseDataNotAvailable(false);
  };
  let tPnlTotal: number;
  let tRefundTotal: number;
  let tInvestedTotal: number;

  const onSetDisplayData = (data: any) => {
    let tempData = data;
    let provinceDataSet = new Set();
    let poolDataSet = new Set();
    let eventDataSet = new Set();
    tPnlTotal = 0;
    tRefundTotal = 0;
    tInvestedTotal = 0;
    for (const tdata of tempData) {
      tdata.timestamp = dateTimeConvert(tdata.timezone, tdata.timestamp);
      totalCalculation(tdata);
      provinceDataSet.add(tdata.province);
      poolDataSet.add(tdata.poolType);
      eventDataSet.add(tdata.eventType);
    }
    setTotals({
      pnlTotal: tPnlTotal,
      refundTotal: tRefundTotal,
      investedTotal: tInvestedTotal,
    });
    localFilteredData(provinceDataSet, poolDataSet, eventDataSet);
    tempData.sort((a: any, b: any) =>
      a.localDateTime > b.localDateTime
        ? -1
        : a.localDateTime < b.localDateTime
        ? 1
        : 0
    );
    setBetHistoryData(tempData);
    setFilteredData(tempData);
  };

  const totalCalculation = (tdata: any) => {
    tPnlTotal += tdata.pnl;
    tRefundTotal += tdata.totalRefunds;
    tInvestedTotal += tdata.totalInvested;
  };

  const localFilteredData = (
    provinceDataSet: any,
    poolDataSet: any,
    eventDataSet: any
  ) => {
    let proList = [];
    let pList = [];
    let eveDataList = [];
    let provinceData = Array.from(provinceDataSet);
    let poolData = Array.from(poolDataSet);
    let eventData = Array.from(eventDataSet);
    for (const data of provinceData) {
      proList.push({ label: data, value: data });
    }
    for (const data of poolData) {
      pList.push({ label: data, value: data });
    }
    for (const data of eventData) {
      eveDataList.push({ label: data, value: data });
    }
    setProvinceList(proList);
    setPoolList(pList);
    setEventTypeList(eveDataList);
  };

  const onFilteredData = () => {
    var betResponseTemp: any[] = [];
    tPnlTotal = 0;
    tRefundTotal = 0;
    tInvestedTotal = 0;
    for (const data of filteredData) {
      if (
        selectedProvince.length !== 0 &&
        !selectedProvince.includes(data.province)
      ) {
        continue;
      }
      if (selectedPool.length !== 0 && !selectedPool.includes(data.poolType)) {
        continue;
      }
      if (
        selectedEvent.length !== 0 &&
        !selectedEvent.includes(data.eventType)
      ) {
        continue;
      }
      totalCalculation(data);
      betResponseTemp.push(data);
    }
    setTotals({
      pnlTotal: tPnlTotal,
      refundTotal: tRefundTotal,
      investedTotal: tInvestedTotal,
    });
    if (betResponseTemp.length === 0) {
      setisDataNotAvailable(true);
    } else {
      setisDataNotAvailable(false);
    }
    setBetHistoryData(betResponseTemp);
  };

  let footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Totals:"
          colSpan={7}
          footerStyle={{ textAlign: "right" }}
        />
        <Column footer={numberFormate(totals.refundTotal)} />
        <Column footer={numberFormate(totals.pnlTotal)} />
        <Column />
        <Column footer={numberFormate(totals.investedTotal)} />
      </Row>
    </ColumnGroup>
  );
  const getColumnsData = (rowData: any, field: string) => {
    if (field === "isResulted") {
      if (
        rowData.betStatus === "RESULTED" &&
        Object.keys(rowData["poolPayout"]).length !== 0
      ) {
        return (
          <span
            onClick={() => onPayOut(rowData)}
            className="sele-text text-capitalize"
          >
            payout
          </span>
        );
      }
    }
    if (field === "successBetAmt") {
      return (
        <span onClick={() => showInvestedData(rowData)} className="sele-text">
          {numberFormate(rowData.successBetAmt)}
        </span>
      );
    }
    if (field === "pnl" || field === "totalWinnings") {
      if (rowData[field] !== null) {
        return numberFormate(rowData[field]);
      }
    }
    return <span>{rowData[field]}</span>;
  };
  const showInvestedData = (rowData: any) => {
    let selkeys: any = [];
    if (rowData.selectionsInvestment != null) {
      selkeys = Object.keys(rowData.selectionsInvestment);
      let selectionMap = rowData.selectionsInvestment;
      let combinationsTicketMap = rowData.correctCombinations;
      let tempList: investMentData[] = [];
      for (const key of selkeys) {
        let tempObj: any = {};
        tempObj.selection = key;
        tempObj.amount = selectionMap[key];
        tempObj.ticket = combinationsTicketMap[key];
        tempList.push(tempObj);
      }
      setInvData(tempList);
      setSuccessSelection(Object.keys(selectionMap).length);
      setDisplayInvest(true);
    }
  };

  const onPayOut = (rowData: any) => {
    let poolPayOutKeys = Object.keys(rowData.poolPayout);
    let poolpayOutMap = rowData.poolPayout;
    let tempPayOutList: payOutData[] = [];
    for (const key of poolPayOutKeys) {
      let tempPayOutObj: any = {};
      tempPayOutObj.selection = key;
      tempPayOutObj.odds = poolpayOutMap[key];
      tempPayOutList.push(tempPayOutObj);
    }
    setPayOutData(tempPayOutList);
    setDisplayPayout(true);
  };

  const invFooterBtn = (
    <div className="btn-container">
      <button
        className="pz-btn primary footer_btn"
        onClick={() => setDisplayInvest(false)}
      >
        Close
      </button>
    </div>
  );
  const payOutFooterBtn = (
    <div className="btn-container">
      <button
        className="pz-btn primary footer_btn"
        onClick={() => setDisplayPayout(false)}
      >
        Close
      </button>
    </div>
  );

  const onCsvLink = () => {
    if (exportCSV_Data.length === 0) {
      return false;
    }
  };

  const onExport = () => {
    let values: any = [...betHistoryData];

    let wagersExportDataList: any = [];
    for (const val of values) {
      let wagersObj: any = {};
      wagersObj["timestamp"] = val.timestamp;
      wagersObj["clientId"] = val.clientId;
      wagersObj["transactionId"] = val.transactionId;
      wagersObj["raceKey"] = val.raceKey;
      wagersObj["poolType"] = val.poolType;
      wagersObj["eventType"] = val.eventType;
      wagersObj["provider"] = val.provider;
      wagersObj["betStatus"] = val.betStatus;
      wagersObj["totalRefunds"] = val.totalRefunds;
      wagersObj["pnl"] = val.pnl;
      wagersObj["totalInvested"] = val.totalInvested;
      for (const t in val["poolPayout"]) {
        wagersObj["selection"] = t;
        wagersObj["selectionsOdds"] = val["selectionsOdds"][t];
        wagersObj["payoutSelection"] = val["poolPayout"][t] ? t : null;
        wagersObj["payoutOdds"] = val["poolPayout"][t]
          ? val["poolPayout"][t]
          : null;
        wagersObj["isResulted"] = val["poolPayout"][t] ? "payout" : null;
      }
      wagersExportDataList.push(wagersObj);
    }
    setExportCSV_Data(wagersExportDataList);
  };

  return (
    <div>
      <LargeLoader isLoading={loading} />
      <Header pageTitle="Reports" />
      <div className="content heightFull cont">
        <div className="content-left-panel sidebar">
          <InsideTransactionHeader selectionType="wagersData" />
        </div>
        <div className="content-middle-panel w-100">
          <div className="content-panel-header head multiple">Wagers Data</div>
          <div className="inpage-nav m-3" style={{ borderRadius: "0.5rem" }}>
            <div className="subheader ml-3">
              <div className="input_block">
                <PzDropDown
                  value={poolType}
                  options={options}
                  onChange={(e: any) => setPoolType(e.value)}
                  placeHolder="Select Bet Options"
                  className="bet_options"
                  classNameWrp="betOptionWrp"
                />
              </div>
              <div className="input_block">
                <Calendar
                  id="range"
                  selectionMode="range"
                  onChange={onChangeDate}
                  value={dateRange}
                  monthNavigator
                  yearNavigator
                  yearRange="2020:3000"
                  placeholder="Select date for bet history"
                  className="calendercss calender"
                ></Calendar>
              </div>
              <div className="btn-container " style={{ padding: "5px" }}>
                <button
                  className={`pz-btn primary rounded subBtn`}
                  onClick={onSubmit}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </div>

          <div className="pz-card">
            <div>
              {isMainData && (
                <div>
                  <div className="pz-card multiselscroll">
                    <div className="filter_row" style={{ padding: "10px" }}>
                      <div className="d-flex align-items-center">
                        <div className="multi_select_bx">
                          <MultiSelect
                            value={selectedProvince}
                            options={provinceList}
                            onChange={(e) => setSelectedProvince(e.value)}
                            optionLabel="label"
                            placeholder="Select a Province"
                          />
                        </div>
                        <div className="multi_select_bx">
                          <MultiSelect
                            value={selectedPool}
                            options={poolList}
                            onChange={(e) => setSelectedPool(e.value)}
                            optionLabel="label"
                            placeholder="Select a Pool"
                          />
                        </div>
                        <div className="multi_select_bx">
                          <MultiSelect
                            value={selectedEvent}
                            options={eventTypeList}
                            onChange={(e) => setSelectedEvent(e.value)}
                            optionLabel="label"
                            placeholder="Select a event"
                          />
                        </div>
                        <div className="btn-container m-2 p-2">
                          <button
                            className={`pz-btn primary rounded fitBtn`}
                            onClick={onFilteredData}
                          >
                            FILTER
                          </button>
                        </div>
                      </div>
                      <CSVLink
                        data={exportCSV_Data}
                        filename={
                          format(dateRange[0], "yyyyMMdd") +
                          "_" +
                          format(dateRange[1], "yyyyMMdd") +
                          "_wagersHistory.csv"
                        }
                        className="pz-btn primary rounded tclass"
                        target="_blank"
                        headers={wagersCSV_Headers}
                        onClick={onCsvLink}
                      >
                        Export CSV
                      </CSVLink>
                    </div>
                  </div>
                  {!isDataNotAvailable && (
                    <div className="pz-card">
                      <div className="table-scroll">
                        <DataTable
                          ref={dt}
                          value={betHistoryData}
                          paginator
                          className="table-class"
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                          rows={100}
                          rowsPerPageOptions={[100, 150, 200, 500]}
                          footerColumnGroup={footerGroup}
                        >
                          {betHistorycolumns.map((col, i) => {
                            const { field, header, width } = col;
                            return (
                              <Column
                                key={field}
                                field={field}
                                header={header}
                                style={{ width: width }}
                                sortable
                                body={(rowData) =>
                                  getColumnsData(rowData, field)
                                }
                              />
                            );
                          })}
                        </DataTable>
                      </div>
                    </div>
                  )}
                  <div>
                    <Dialog
                      header={`Success Selections :  ${successSelection}`}
                      footer={invFooterBtn}
                      visible={displayInvest}
                      className="dialog-class dark pool_payout_dailog"
                      onHide={() => setDisplayInvest(false)}
                      draggable={false}
                    >
                      <DataTable value={invData} className="table-class ">
                        {investmentDataColumns}
                      </DataTable>
                    </Dialog>
                  </div>
                  <div>
                    <Dialog
                      header="Pools Payout"
                      footer={payOutFooterBtn}
                      visible={displayPayout}
                      className="dialog-class dark pool_payout_dailog"
                      onHide={() => setDisplayPayout(false)}
                      draggable={false}
                    >
                      <DataTable value={payOutData} className="table-class">
                        {payOutColumns}
                      </DataTable>
                    </Dialog>
                  </div>
                </div>
              )}
            </div>
            {isResponseDataNotAvailable && !isDataNotAvailable && (
              <div className="no_data">
                <span>Data Not Available!</span>
              </div>
            )}
            {betHistoryData.length === 0 &&
              !isMainData &&
              !isResponseDataNotAvailable && (
                <div className="no_data">
                  <span>Select Date For Wagers Data</span>
                </div>
              )}
            {isDataNotAvailable && (
              <div className="no_data">
                <span>Data Not Available!</span>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

import React, { ReactNode } from "react";
import "./backDrop.scss";

export interface props {
  open: boolean;
  noBackDrop?: boolean;
  noDismissableMask?: boolean;
  className?: string;
  setOpen: (value: boolean) => void;
  children: ReactNode;
}

export const BackDrop = (props: props) => {
  const outsideClick = () => {
    props.setOpen(false);
  };

  return (
    <>
      {props.open && (
        <>
          <div
            className={`${props.noBackDrop ? "noBackDrop" : "backDrop"} ${
              props.className
            }`}
            onClick={props.noDismissableMask ? () => {} : outsideClick}
          ></div>
          <div>{props.children}</div>
        </>
      )}
    </>
  );
};

export default BackDrop;

import { TableColumnsDTO } from "../../Helpers/columns";
import "./pzTable.scss";

export interface IPzTableProps {
  data: any[];
  columns: TableColumnsDTO[];
  isEditAction?: boolean;
  onEditClick?: any;
  emptyMessage?: string;
  onRowDoubleClick?: any;
}

export default function PzTable(props: IPzTableProps) {
  if (props.data && props.data.length)
    return (
      <table className="pz-table">
        <thead>
          <tr>
            {props.isEditAction && <th style={{ width: "1%" }}>Actions</th>}
            {props.columns.map((col) => {
              return (
                <th key={col.field} style={{ width: col.width }}>
                  {col.header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {props.data.map((rowData, index) => {
            return (
              <tr key={index}>
                {props.isEditAction && (
                  <td>
                    <button
                      onClick={() => props.onEditClick(rowData)}
                      className="action-btn"
                    >
                      <i className="fa fa-pencil"></i>
                    </button>
                  </td>
                )}
                {props.columns.map((col: any) => {
                  if (col.field === "scratched")
                    return (
                      <td key={col.field}>
                        {rowData[col.field] ? (
                          <span className="badge badge-danger text-dark">
                            SCR
                          </span>
                        ) : (
                          "-"
                        )}
                      </td>
                    );
                  if (col.field === "coupled")
                    return (
                      <td key={col.field}>
                        {rowData[col.field] ? (
                          <span className="badge badge-warning text-dark">
                            CPL
                          </span>
                        ) : (
                          "-"
                        )}
                      </td>
                    );
                  if (rowData[col.field] === true)
                    return (
                      <td key={col.field}>
                        <i className="fa fa-check text-warning"></i>
                      </td>
                    );
                  if (rowData[col.field] === false) return <td> - </td>;
                  return <td key={col.field}>{rowData[col.field]}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  return <div className="text-muted h5 m-auto p-3">{props.emptyMessage}</div>;
}

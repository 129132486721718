import { useState } from "react";
import { CustomerRule } from "../../../../Models/customerRulesDto";
import AddCustomerRules from "./Screens/AddCutomerRules/addCustomerRules";
import ViewCustomerRules from "./Screens/ViewCustomerRules/viewCustomerRules";

export enum ScreenType {
  ADD,
  VIEW,
}
export default function CustomerRules() {

  const [selectedScreen, setSelectedScreen] = useState(ScreenType.VIEW);
  const [onRuleIdAdded, setOnRuleIdAdded] = useState<string>('');
  const [isRuleIdAdded, setIsRuleIdAdded] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(
    {} as CustomerRule
  );
  const changeScreen = (screen: number) => {
    if (screen === 1) {
      setSelectedScreen(ScreenType.VIEW);
    } else {
      setSelectedScreen(ScreenType.ADD);
    }
  };

  const ScreenTemplate = () => {
    switch (selectedScreen) {
      case ScreenType.VIEW:
        return (
          <ViewCustomerRules
            changeScreen={changeScreen}
            setIsUpdated={setIsUpdated}
            setSelectedRowData={setSelectedRowData}
            setOnRuleIdAdded={setOnRuleIdAdded}
            setIsRuleIdAdded={setIsRuleIdAdded}
          />
        );
      case ScreenType.ADD:
        return (
          <AddCustomerRules
            changeScreen={changeScreen}
            isUpdated={isUpdated}
            selectedRowData={selectedRowData}
            onRuleIdAdded={onRuleIdAdded}
            isRuleIdAdded={isRuleIdAdded}
          />
        );
      default:
        break;
    }
  };

  return <div>{ScreenTemplate()}</div>;
}

import { useState } from "react";
import { getFloatButton } from "../../../../Helpers/ui-helper";
import "./scratch-profile.scss";
import AddScrProfile from "./Screens/addScratchProfile";
import ViewProfiles from "./Screens/viewProfile";

export interface IScratchProfileProps {}

export enum ScreenType {
  ADD,
  VIEW,
}

export default function ScratchProfile(props: IScratchProfileProps) {
  //   const rlContext = useContext(RulesContext);

  const [selectedScreen, setSelectedScreen] = useState(ScreenType.VIEW);

  const ScreenTemplate = () => {
    switch (selectedScreen) {
      case ScreenType.VIEW:
        return <ViewProfiles />;
      case ScreenType.ADD:
        return <AddScrProfile changeScreen={changeScreen} />;
      default:
        break;
    }
  };

  const changeScreen = (screen: number) => {
    if (screen === 1) {
      setSelectedScreen(ScreenType.VIEW);
    } else {
      setSelectedScreen(ScreenType.ADD);
    }
  };

  return (
    <>
      {ScreenTemplate()}
      {ScreenType.VIEW === selectedScreen &&
        getFloatButton({ onClick: () => setSelectedScreen(ScreenType.ADD) })}
    </>
  );
}

import { Dialog } from "primereact/dialog";
import { useContext, useEffect, useState } from "react";
import "./providerDialog.scss";
import { ScheduleApiService } from "../../../services/scheduleApiService";
import {
  CLOG,
  showAPIToast,
  showToast,
  TOAST,
} from "../../../Helpers/ui-helper";
import { TableColumnsDTO } from "../../../Helpers/columns";
import { FixedOddsContext } from "../../../Context/fixedOddsContext";
import LargeLoader from "../../../UI-Components/Loader/loaders";
export interface IProviderOddsDialogProps {
  dialogueShow: boolean;
  setDialogShow: (state: boolean) => void;
}
export default function ProviderOddsDialog(props: IProviderOddsDialogProps) {
  const {
    selectedPool,
    setProvidersOddsList,
    setProvidersOddsCols,
    providersOddsCols,
    providersOddsList,
  } = useContext(FixedOddsContext);

  useEffect(() => {
    if (selectedPool && props.dialogueShow) {
      showProviderWiseOdds();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPool, props.dialogueShow]);
  const [isLoading, setIsLoading] = useState(false);

  const showProviderWiseOdds = () => {
    let poolKeyStr = selectedPool?.poolDetails?.poolKeyString;
    if (!poolKeyStr) return;
    setIsLoading(true);
    ScheduleApiService.getAllProviderOdds(poolKeyStr)
      .then((res) => {
        if (res.data) {
          CLOG("=>___APO___");
          let providersObj = res.data;
          let columns: TableColumnsDTO[] = [
            { field: "selId", header: "Sel No." },
          ];
          let tempObj: any = {};
          for (let provider in providersObj) {
            columns.push({ field: provider, header: provider });
            for (let sel in providersObj[provider]) {
              if (tempObj[sel]) {
                let obj = { ...tempObj[sel] };
                obj[provider] = providersObj[provider][sel];
                obj["selId"] = sel;
                tempObj[sel] = obj;
              } else {
                let obj = {} as any;
                obj[provider] = providersObj[provider][sel];
                obj["selId"] = sel;
                tempObj[sel] = obj;
              }
            }
          }
          setProvidersOddsList(Object.values(tempObj));
          setProvidersOddsCols([...columns]);
          // props.setDialogShow(true);
        } else {
          setProvidersOddsList(null);
          showToast("Provider odds Not available!", TOAST.ERROR);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        showAPIToast(err, "while fetching provider odds", "p_odds");
        setIsLoading(false);
      });
  };

  const footer = (
    <div>
      <button
        className="pz-btn rounded-pill shadow btn-primary"
        onClick={() => props.setDialogShow(false)}
      >
        {" "}
        OK
      </button>
    </div>
  );

  return (
    <>
      <LargeLoader isLoading={isLoading} />
      <Dialog
        header="Providers Odds"
        footer={footer}
        className="pz-dialog"
        visible={props.dialogueShow}
        dismissableMask
        style={{ minWidth: "20vw" }}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        modal
        onHide={() => props.setDialogShow(false)}
      >
        <div className="dialog-body">
          <div>
            {providersOddsList?.length === 0 && (
              <h3 className="text-muted p-4">No Provider Odds Available</h3>
            )}
            {providersOddsList?.length > 0 && (
              <div className="po-table">
                <table>
                  <thead>
                    <tr>
                      {providersOddsCols?.map((col) => {
                        return <th>{col.header}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {providersOddsList?.map((po: any, index: any) => {
                      return (
                        <tr key={index}>
                          {providersOddsCols?.map((col) => {
                            return (
                              <td>
                                {col.field === "selId"
                                  ? po[col.field]
                                  : po[col.field]
                                  ? po[col.field].toFixed(2)
                                  : po[col.field]}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
}
